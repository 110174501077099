// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignupForm_container___38D2zwg3p3 {\n  margin-top: 5em;\n}\n\n  .SignupForm_container___38D2zwg3p3 form {\n    display: flex;\n  }\n\n  .SignupForm_container___38D2zwg3p3 form input {\n      flex-grow: 1;\n      margin-right: 0.5em;\n    }\n\n  .SignupForm_container___38D2zwg3p3 form button {\n      align-self: flex-start;\n    }\n\n@media (max-width: 992px) {\n  .SignupForm_container___38D2zwg3p3 {\n    margin-top: initial;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "SignupForm_container___38D2zwg3p3"
};
module.exports = exports;
