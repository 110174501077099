// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FeaturingSection_featuring-section___2FV7RSxKhr {\n  padding: 4em;\n}\n\n  .FeaturingSection_featuring-section___2FV7RSxKhr p {\n    font-weight: 700;\n    margin: 0;\n    font-size: 28px;\n    text-align: center;\n  }\n\n  .FeaturingSection_featuring-section___2FV7RSxKhr .FeaturingSection_logosContainer___2Id8Lq5-1- {\n    max-width: 750px;\n  }\n\n@media (max-width: 576px) {\n  .FeaturingSection_featuring-section___2FV7RSxKhr {\n    padding: 4em 2em;\n  }\n\n    .FeaturingSection_featuring-section___2FV7RSxKhr p {\n      font-size: 24px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"featuring-section": "FeaturingSection_featuring-section___2FV7RSxKhr",
	"featuringSection": "FeaturingSection_featuring-section___2FV7RSxKhr",
	"logosContainer": "FeaturingSection_logosContainer___2Id8Lq5-1-"
};
module.exports = exports;
