import React from 'react';

import cx from 'classnames';
import { InputProps } from './types';
import styles from './Input.css';
import { MailIcon, RightArrowIcon } from '../icon/Icon';
import { IconButton } from '../button/Button';

const Input = React.forwardRef(
  (
    {
      placeholder,
      type,
      onChange,
      invalid,
      readOnly,
      canSubmit,
      hasInlineSubmit,
      isDisabled,
      value,
    }: InputProps,
    ref: any,
  ) => {
    const inputStyles = cx(styles.inputContainer, {
      [styles.invalid]: value && invalid,
      [styles.success]: value && !invalid,
    });

    return (
      <div className={inputStyles}>
        <MailIcon />
        <input
          ref={ref}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          type={type}
          readOnly={readOnly}
          disabled={isDisabled}
          value={value}
        />
        {hasInlineSubmit && <IconButton icon={<RightArrowIcon />} isDisabled={!canSubmit} />}
      </div>
    );
  },
);

export default Input;
