export enum InputTypes {
  TEXT = 'text',
  EMAIL = 'email',
}

export type InputProps = {
  readonly type: InputTypes;
  readonly placeholder: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly invalid?: boolean;
  readonly readOnly?: boolean;
  readonly canSubmit?: boolean;
  readonly hasInlineSubmit?: boolean;
  readonly isDisabled?: boolean;
};
