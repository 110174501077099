// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Unity_unity-container___2WyBJxg0_N {\n  display: none\n}\n.Unity_unity-container___2WyBJxg0_N.Unity_unity-container--open___12BctVAWo5 {\n    display: initial;\n    position: fixed;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 14000;\n  }\n", ""]);
// Exports
exports.locals = {
	"unity-container": "Unity_unity-container___2WyBJxg0_N",
	"unityContainer": "Unity_unity-container___2WyBJxg0_N",
	"unity-container--open": "Unity_unity-container--open___12BctVAWo5",
	"unityContainerOpen": "Unity_unity-container--open___12BctVAWo5"
};
module.exports = exports;
