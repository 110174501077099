// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".static-pages_privacyPolicy___5Z2VF43idb main, .static-pages_termsOfUse___2IMdtO1rlK main {\n    color: #5a3e75;\n    max-width: 800px;\n    padding: 1em;\n    margin: auto;\n    font-weight: 400;\n  }\n  .static-pages_privacyPolicy___5Z2VF43idb header, .static-pages_termsOfUse___2IMdtO1rlK header {\n    text-align: center;\n    border-bottom: 1px solid #f9d196;\n  }\n  .static-pages_privacyPolicy___5Z2VF43idb h1, .static-pages_termsOfUse___2IMdtO1rlK h1 {\n    font-size: 44px;\n  }\n  .static-pages_privacyPolicy___5Z2VF43idb h2, .static-pages_termsOfUse___2IMdtO1rlK h2 {\n    font-size: 36px;\n    margin-top: 1.5em;\n    margin-bottom: 0;\n  }\n  .static-pages_privacyPolicy___5Z2VF43idb p,\n  .static-pages_privacyPolicy___5Z2VF43idb li,\n  .static-pages_privacyPolicy___5Z2VF43idb strong,\n  .static-pages_termsOfUse___2IMdtO1rlK p,\n  .static-pages_termsOfUse___2IMdtO1rlK li,\n  .static-pages_termsOfUse___2IMdtO1rlK strong {\n    font-size: 20px;\n    line-height: 30px;\n  }\n  .static-pages_privacyPolicy___5Z2VF43idb li, .static-pages_termsOfUse___2IMdtO1rlK li {\n    margin-bottom: 16px;\n  }\n", ""]);
// Exports
exports.locals = {
	"privacyPolicy": "static-pages_privacyPolicy___5Z2VF43idb",
	"termsOfUse": "static-pages_termsOfUse___2IMdtO1rlK"
};
module.exports = exports;
