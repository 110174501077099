import React from 'react';
import StaticNavBar from '../components/navbar/StaticNavBar';

import styles from '../../css/static-pages';

const PrivacyPolicyPage = () => (
  <div className={styles.privacyPolicy}>
    <StaticNavBar />
    <main>
      <header>
        <h1>Our Privacy Policy</h1>
        <p>Last Updated: April 12, 2020</p>
      </header>
      <section>
        <h2>About Us</h2>
        <p>
          Thank you for using Hellosaurus, shows you can play! Hellosaurus is owned and operated by
          All Together Labs, Inc. (referred to in this policy as All Together Labs, ATL,
          Hellosaurus, we, us, or our). This privacy policy applies to your use of the website
          located at{' '}
          <a href="https://hellosaurus.com" target="_blank noopener noreferrer">
            hellosaurus.com
          </a>{' '}
          (Hellosaurus Website), the website located at{' '}
          <a href="https://thebirthday.show" target="_blank noopener noreferrer">
            thebirthday.show
          </a>{' '}
          (The Birthday Show Website), and the Hellosaurus mobile application (our “App”) (which we
          will refer to collectively as the Services) and any other information you may provide us
          in association with your use of the Services.
        </p>
        <p>
          Our Privacy Policy describes what information we gather from our users and how we use that
          information. Younger visitors should always check with their parents or guardians before
          entering information on any website or mobile application, and we encourage families to
          discuss their household guidelines regarding the online sharing of personal information.
        </p>
        <p>
          We may update this Privacy Policy from time to time at our sole discretion. Any variations
          become effective on posting the updated Privacy Policy and we shall have no obligation to
          provide you with individual notice of such changes. Your continued use of the Services
          following the publication of any amended Privacy Policy shall signify your acceptance of
          that amended Privacy Policy, except where we are otherwise required by law to seek your
          direct consent.
        </p>
        <p>
          <strong>
            BY INSTALLING, USING, REGISTERING TO OR OTHERWISE ACCESSING THE SERVICES, YOU AGREE TO
            THIS PRIVACY POLICY AND GIVE AN EXPLICIT AND INFORMED CONSENT TO THE PROCESSING, USE AND
            DISCLOSURE OF YOUR PERSONAL DATA IN ACCORDANCE WITH THIS PRIVACY POLICY. IF YOU DO NOT
            AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT INSTALL, USE, REGISTER TO OR OTHERWISE
            ACCESS THE SERVICES.
          </strong>
        </p>
      </section>
      <section>
        <h2>What Information Do We Collect?</h2>
        <p>
          We collect personal information when a Parent (“parent” or “you”) or child accesses and
          uses our Services, including:
        </p>
        <ul>
          <li>
            <strong>Information you or your child actively provide, such as the following:</strong>{' '}
            identifiers such as name, username, phone number, email address, mailing address; child
            profile information such as date of birth, gender, photo, avatar, interests; parent or
            guardian contact information, payment information such as a credit card number; location
            data such as city, town, state; If you choose to post content or communicate with us or
            others on a public platform we host or through user support, we will collect the
            information you provide in those postings but we do not allow children to communicate
            with others on public platforms; if you choose to “Request a Shoutout” for your child in
            any show or “Share A Present” from your child in any show, in addition to the above, we
            collect the image and/or video of your child that you choose to provide as well as the
            names of the individuals featured in said image and/or video submissions.
          </li>
          <li>
            <strong>Information collected automatically, such as the following:</strong> Device and
            network identifiers such as IP address, mobile advertising ID, media access control
            (MAC) address, and identifiers associated with browser cookies, web beacons and similar
            technologies we deploy on the Services; Internet or other electronic network activity
            information, such as your Internet service provider (ISP), browser type and operating
            system version, language settings, access times, pages viewed, the routes by which you
            access the Services, and your use of any hyperlinks available within the Services.
          </li>
          <li>
            <strong>Information from Third Parties.</strong> Where we collect information from
            adults or from portions of the Services that are not directed to children, we may
            collect personal information from third parties to supplement the information we collect
            from you directly. We do this so that we can show offers that are more relevant to your
            interests and to better understand our users. We may derive information or draw
            inferences about you or your child based on the information we, or our partners,
            collect. For example, we may make inferences about your location (e.g., country, state,
            and city) based on your IP address.
          </li>
          <li>
            <strong>Cookies and similar technologies.</strong> We and any of our service providers
            use cookies, which are small text files that help store user preferences and activity,
            similar technologies such as web beacons, pixels, ad tags, and SDKs, which are blocks of
            code provided by our partners that may be installed in our mobile and online
            applications, to recognize you when you visit our Services, and to collect information
            such as the number of visits, which features, pages, or content are popular, and to
            measure your browsing activities. These technologies allow us to optimize the operation
            of our Services, including by allowing us to help ensure our Services are functioning
            properly, and to improve the Services. On any portion of the Services that is directed
            to children, we do not use, or allow our partners to use, automatically collected
            information, such as IP addresses, cookie identifiers, and other device identifiers,
            other than for purposes of supporting our internal operations, such as to provide
            children with access to features and activities on the Services, to customize content
            and improve our Services. We do not allow online behavioral advertising, sometimes
            called personalized advertising or tailored advertising to children or teens under 16.
            For the portions of our Services that are directed to audiences older than 16, we may
            share identifiers such as cookies and the advertising identifier associated with your
            mobile device with our advertising partners, and those partners may use and share those
            identifiers to show ads that are targeted to your interests.
          </li>
          <li>
            <strong>Websites and data collection of Third Parties.</strong> The Services may include
            links to other websites or services, such as to purchase online subscription or purchase
            of a consumer or virtual good or service. Personal information collected on other sites
            and services is subject to the privacy policy and practices of the third party providing
            such website or service and not this Privacy Policy. Similarly, on the Services or
            portions of the Services that are not directed to children, we may give you the option
            to share with others on social media platforms, such as Instagram, Facebook or Twitter.
            When you share in this manner, the privacy practices of the social media platform
            control who has access to your information and how it is treated by the social media
            platform. Please consult the privacy policies of any social media services or other
            third parties with which you share information in this manner to learn about the social
            media platform's data practices.
          </li>
          <li>
            Please note that all the information we collect may be combined and used for the
            purposes described in the Privacy Policy.
          </li>
        </ul>
      </section>
      <section>
        <h2>How We Use The Information We Collect</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li> Operate, maintain, enhance, and provide all of the features of our Services.</li>
          <li>
            Personalize your experience while using our Services, including on various devices you
            use to access the Services. For instance, we may remember your recent activity so we can
            recommend the most content for you on your next visit.
          </li>
          <li>
            Communicate with you about your account and account activity and to respond to
            inquiries. From time to time, we may also use your information to send you emails about
            Hellosaurus and our other websites and services that may be of interest to you.
          </li>
          <li>
            Understand and analyze the usage trends, behaviors, and preferences of our users, to
            improve the way the Services work and look, and to develop new features and
            functionality.
          </li>
          <li>Enable you or your child to participate in interactive features of the Services.</li>
          <li>
            Enhance our shows and features on our Services with content (e.g. images and videos) you
            actively submit.
          </li>
          <li>Conduct internal research and development.</li>
          <li>Process or fulfill orders and transactions.</li>
          <li>Maintain, develop, support, and improve our Services.</li>
        </ul>
      </section>
      <section>
        <h2>How We Share or Transfer Data To Others</h2>
        <p>
          We care deeply about the privacy of our users. We do not sell personal information
          collected from children we know to be under 13. We will not share the personal information
          we collect with any unrelated third parties other in the following circumstances:
        </p>
        <ul>
          <li>
            <strong>Vendors and Service Providers.</strong> We may share information with our
            trusted vendors, third party service providers, and individuals to provide services or
            products for us or on our behalf, which may include hosting, email delivery, and
            business administration or professional services (e.g., legal advisors, accountants).
            When we share personal information with third party service providers or vendors, these
            third parties are contractually obligated to maintain the security and confidentiality
            of that personal information.
          </li>
          <li>
            <strong>In Connection With A Corporate Transaction.</strong> If we become involved in a
            merger, acquisition, bankruptcy, change of control, or any form of sale of some or all
            of our assets, your personal information may be transferred or disclosed in connection
            with the business transaction.
          </li>
          <li>
            <strong>Aggregate or De-identified Information.</strong> We may share information in
            aggregated and/or anonymous form that cannot reasonably be used to identify any
            individual.
          </li>
          <li>
            <strong>With Your Consent or At Your Direction.</strong> We share personal information
            with third parties when you ask us to do so.
          </li>
        </ul>
      </section>
      <section>
        <h2>How We Store Data</h2>
        <p>
          We store the personal information we collect for as long as is necessary for the
          purpose(s) for which we originally collected it. We may also retain personal information
          as required by law.
        </p>
      </section>
      <section>
        <h2>Marketing, Advertising and Email Communications</h2>
        <ul>
          <li>
            <strong>Email or SMS communications.</strong> We may, from time to time, send you email
            or SMS regarding our products and services, or products and services we think you may
            enjoy. Only we (or our vendors or service providers operating on our behalf) will send
            you these emails. You can choose not to receive these emails by clicking the unsubscribe
            link in any email or respond appropriately to any SMS. Please note that you are not
            permitted to unsubscribe or opt-out of non-promotional messages regarding your account,
            such as account verification, orders from us, changes or updates to features of the
            Services, or technical or security notices.
          </li>
          <li>
            <strong>Interest based advertising.</strong> We do not display any targeted advertising
            in the portions of the Services that are directed to children. We do not permit third
            parties to collect information about your use of the Hellosaurus App for the purpose of
            sending you targeted advertisements on other websites or applications. However, outside
            of the Hellosaurus App, we may engage in interest-based advertising to deliver online
            advertisements about our Services, and may use third party advertising companies to
            serve targeted advertisements on other websites, apps or services, including on Facebook
            and other social networks. These advertisements are not based on the personal
            information we collect from you or on your activities on our Services, though you may
            see one of these advertisements based on your activities on other websites or services
            (for instance, if you type “interactive kids mobile app” into an online search engine,
            you may see an advertisement for Hellosaurus).
          </li>
        </ul>
      </section>
      <section>
        <h2>How To Access, Update Or Delete Your Personal Information</h2>
        <p>
          Please contact us at <a href="mailto:privacy@hellosaurus.com">privacy@hellosaurus.com</a>{' '}
          to update or delete your personal information.
        </p>
        <p>
          We may not be able to delete information in all instances, such as information retained in
          technical support logs and other business records. We may retain information which has
          been de-identified or disassociated with personal identifiers such that it can no longer
          be used to reasonably identify a particular individual.
        </p>
        <p>
          Unless we receive a deletion request, we will retain your information for as long as your
          account is active or as is reasonably useful for operational purposes. For example, we may
          retain certain data as necessary to prevent fraud or future abuse, for recordkeeping or
          other legitimate business purposes, or if required by law. We may also retain information
          which has been de-identified or aggregated such that it can no longer reasonably identify
          a particular individual. All retained personal information will remain subject to the
          terms of this Privacy Policy.
        </p>
      </section>
      <section>
        <h2>International Visitors</h2>
        <p>
          Our App is operated and managed on servers located within the United States. If you choose
          to use our App from the European Union or other regions of the world with laws governing
          data collection and use that differ from United States law, then you acknowledge that we
          will transfer your personal information to the United States for the purpose of performing
          the App according to our contract and for any other purpose for which you provide
          explicit, informed consent.
        </p>
      </section>
      <section>
        <h2>California Privacy Notice</h2>
        <p>
          If you are a California resident, please review these additional privacy disclosures,
          which provide a summary of how we collect, use, and disclose personal information from you
          or your child, and explain your rights under California law.
        </p>
        <ul>
          <li>
            <strong>
              Categories of Information We Collect, Use, and Disclose for Business Purposes:
            </strong>
            We collect the categories of personal information described in the What Information Do
            We Collect? section. We collect this information from you, your child, third parties, or
            as otherwise described in this policy. We use these categories as described in the How
            We Use The Information We Collect section above, and we disclose these categories of
            personal information for business purposes as described above and in the How We Share or
            Transfer Data To Others section.
          </li>
          <li>
            <strong>Your Rights:</strong> Under the California Consumer Privacy Protection Act
            (CCPA), California residents have the right to request access to or deletion of their
            personal information, to request additional details about our information practices, and
            to not be discriminated against for exercising their rights. You may exercise your right
            to access, edit or delete information we have collected about you or your child by
            contacting us at <a href="mailto:privacy@hellosaurus.com">privacy@hellosaurus.com</a>,
            and we will consider your request in accordance with applicable laws. Note that even if
            we honor your request to delete your information, we may retain certain information as
            required or permitted by law. We do not sell personal information collected by or from
            any Service or portion of a Service that is directed to children or where we know that
            the user is a child or a teen under 16. California residents who are under 18 years of
            age may request and obtain removal of any postings on the Services that they themselves
            post by emailing us at{' '}
            <a href="mailto:privacy@hellosaurus.com">privacy@hellosaurus.com</a>. Please also note
            that any requests for removal do not ensure complete or comprehensive removal of the
            content or information from the Services. For example, content that you or your child
            have posted may be republished or reposted by another user or third party.
          </li>
        </ul>
      </section>
      <section>
        <h2>Links To Other Sites</h2>
        <p>
          Our Services may link to and may be linked by websites operated by other entities or
          individuals. Some of these websites may be co-branded with our name or logo. This Privacy
          Policy does not apply to, and we cannot always control the activities of, such other
          third-party websites. You should consult the respective privacy policies of those
          third-party websites.
        </p>
      </section>
      <section>
        <h2>Contacting All Together Labs</h2>
        <p>Please contact All Together Labs, Inc. with any questions or comments.</p>
        <p>
          By email: <a href="mailto:privacy@hellosaurus.com">privacy@hellosaurus.com</a>
        </p>
        <p>By mail: All Together Labs, Inc., 600 Madison Avenue, New York, NY 10022</p>
      </section>
    </main>
  </div>
);

export default PrivacyPolicyPage;
