export enum SectionColors {
  CREAM = 'cream',
  BLUE = 'blue',
  BLUE_LIGHT = 'blue-light',
  CREAM_DARK = 'cream-dark',
  CREAM_DARKER = 'cream-darker',
}

export const WAVING_DINO_POSITIONS = [
  'top-right' /* , 'top-right', 'bottom-left', 'bottom-right' */,
];

export const DINO_SIDE_WAVING_DURATION_MS = 4500;
