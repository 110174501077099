import React from 'react';

import { CreamSection } from '../components/section/Section';
import HeaderWithSubheader from '../components/header_with_subheader/HeaderWithSubheader';
import SignupForm from '../components/signup_form/SignupForm';
import TabletAndPhone from '../components/tablet_and_phone/TabletAndPhone';

import styles from './IntroSection.css';
import commonStyles from '../../css/index.css';

const IntroSection = ({ onSignUp }: { onSignUp: (submittedEmail: string) => void }) => (
  <CreamSection className={styles.introSection}>
    <div className={styles.introSectionContent}>
      <div>
        <HeaderWithSubheader
          header={
            <>
              The Most Advanced
              <br />
              Interactive Learning
              <br />
              Platform For Kids.
            </>
          }
          subheader="Immersive video classes from award-winning kids media creators and YouTube stars."
        />
        <SignupForm className={commonStyles.lgUp} onSignUp={onSignUp} />
      </div>
      <TabletAndPhone />
      <SignupForm className={commonStyles.lgDown} onSignUp={onSignUp} />
    </div>
  </CreamSection>
);

export default IntroSection;
