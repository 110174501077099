// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UnityMobileNotSupportedModal_unity-mobile-not-supported-modal___3CwXP1zAJA p {\n    margin-bottom: 0;\n  }\n\n.UnityMobileNotSupportedModal_dinosaur-error___3Cbi1PkVvU {\n  height: 14em;\n  filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.2));\n  margin-bottom: 1em;\n}\n", ""]);
// Exports
exports.locals = {
	"unity-mobile-not-supported-modal": "UnityMobileNotSupportedModal_unity-mobile-not-supported-modal___3CwXP1zAJA",
	"unityMobileNotSupportedModal": "UnityMobileNotSupportedModal_unity-mobile-not-supported-modal___3CwXP1zAJA",
	"dinosaur-error": "UnityMobileNotSupportedModal_dinosaur-error___3Cbi1PkVvU",
	"dinosaurError": "UnityMobileNotSupportedModal_dinosaur-error___3Cbi1PkVvU"
};
module.exports = exports;
