import React from 'react';

import { CreamDarkerSection } from '../components/section/Section';
import Image from '../components/image/Image';

import styles from './PressSection.css';

import axiosStandard from '../../images/logos/axios.png';
import axiosRetina from '../../images/logos/axios@2x.png';
import businessInsiderStandard from '../../images/logos/business-insider.png';
import businessInsiderRetina from '../../images/logos/business-insider@2x.png';
import techcrunchStandard from '../../images/logos/techcrunch.png';
import techcrunchRetina from '../../images/logos/techcrunch@2x.png';
import theInformationStandard from '../../images/logos/the-information.png';
import theInformationRetina from '../../images/logos/the-information@2x.png';

const press = [
  {
    image: axiosStandard,
    imageRetina: axiosRetina,
    alt: 'Axios',
    link:
      'https://www.axios.com/pandemic-reshapes-a-cadre-of-y-combinator-startups-4e4fe2d9-ac16-43d5-9e42-6031dedaeb5f.html',
  },
  {
    image: businessInsiderStandard,
    imageRetina: businessInsiderRetina,
    alt: 'Business Insider',
    link:
      'https://www.businessinsider.com/one-makers-hq-trivia-is-back-with-netflix-for-kids-2020-9',
  },
  {
    image: techcrunchStandard,
    imageRetina: techcrunchRetina,
    alt: 'TechCrunch',
    link:
      'https://techcrunch.com/2020/08/24/our-11-favorite-companies-from-y-combinators-s20-demo-day-part-i/',
  },
  {
    image: theInformationStandard,
    imageRetina: theInformationRetina,
    alt: 'The Information',
    link:
      'https://www.theinformation.com/articles/y-combinator-ceo-faces-the-crisis-and-his-critics',
  },
];

const PressSection = () => (
  <CreamDarkerSection className={styles.pressSection}>
    <h2>Featured On</h2>
    <div className={styles.pressBoxContainer}>
      {press.map((attributes) => (
        <PressBox {...attributes} />
      ))}
    </div>
  </CreamDarkerSection>
);

const PressBox = ({ image, imageRetina, link, alt }) => (
  <a href={link} target="_blank" className={styles.pressBox}>
    <Image standard={image} retina={imageRetina} alt={alt} />
  </a>
);

export default PressSection;
