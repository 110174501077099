import * as React from 'react';

import cx from 'classnames';
import { ButtonProps, IconButtonProps, LabelButtonProps, ShareButtonProps } from './types';
import styles from './Button.css';
import { FacebookIcon, TwitterIcon, InstagramIcon } from '../icon/Icon';

const URL_ENCODED = encodeURI('https://hellosaurus.com');

function Button({
  label,
  onClick,
  isDisabled,
  icon,
  className,
  id,
  href,
}: ButtonProps): JSX.Element {
  const buttonStyles = cx(styles.button, className);
  const handleClick = () => {
    if (href) {
      window.open(href);
    }

    onClick?.();
  };

  return (
    <button className={buttonStyles} onClick={handleClick} disabled={isDisabled} id={id}>
      {label}
      {icon}
    </button>
  );
}

export const ButtonPrimary = (props: LabelButtonProps): JSX.Element => <Button {...props} />;
export const IconButton = (props: IconButtonProps): JSX.Element => (
  <Button {...props} className={cx(props.className, styles.buttonIcon)} />
);

export const FacebookShareButton = (props: ButtonProps) => (
  <Button
    {...props}
    href={`https://facebook.com/sharer/sharer.php?u=${URL_ENCODED}`}
    icon={<FacebookIcon />}
    label="Share on Facebook"
    className={styles.buttonFacebook}
  />
);

// Opens new tweet with some share text
export const TwitterShareButton = (props: ShareButtonProps) => {
  const textEncoded = encodeURI(props.shareText);

  return (
    <Button
      {...props}
      href={`https://twitter.com/intent/tweet/?text=${textEncoded}&amp;url=${URL_ENCODED}`}
      icon={<TwitterIcon />}
      label="Share on Twitter"
      className={styles.buttonTwitter}
    />
  );
};

// Goes to twitter.com/hellosaurus.com
export const TwitterFollowButton = (props: ButtonProps) => (
  <Button
    {...props}
    href={'https://twitter.com/playhellosaurus'}
    icon={<TwitterIcon />}
    label="Follow on Twitter"
    className={styles.buttonTwitter}
  />
);

export const InstagramFollowButton = (props: ButtonProps) => (
  <Button
    {...props}
    href={'https://instagram.com/playhellosaurus'}
    icon={<InstagramIcon />}
    label="Follow on Instagram"
    className={styles.buttonInstagram}
  />
);
