export function getScrollPercentDecimal(): number {
  const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
  const bodyScrollTop = document.body.scrollTop;

  return (scrollTop + bodyScrollTop) / (scrollHeight - clientHeight);
}

function easeInOutQuadAnimationCurve(t: number): number {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

export function getProgressFromTo(
  from: number,
  to: number,
  animationDecimal: number,
  animationCurveFn = easeInOutQuadAnimationCurve,
): number {
  return from + (to - from) * animationCurveFn(animationDecimal);
}

function getCssNumberAndUnit(styleValue: string): [number, string] {
  const match = styleValue.match(/^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/);

  if (!match) {
    return [0, 'px'];
  }

  const [_, numberStr, unit] = match;
  const number = Number(numberStr);

  return [number, unit];
}

// Given a percentage of the animation, calculates the style's value in the animation.
// e.g. From 2px, To 4px, animationDecimal 0.5 would return 3px
export function getStyleProgressFromTo(
  fromStyleValue: string,
  toStyleValue: string,
  animationDecimal: number,
  animationCurveFn = easeInOutQuadAnimationCurve,
): string {
  const [fromStyleNumber, fromStyleUnit] = getCssNumberAndUnit(fromStyleValue);
  const [toStyleNumber] = getCssNumberAndUnit(toStyleValue);
  const currentStyleNumber = getProgressFromTo(
    fromStyleNumber,
    toStyleNumber,
    animationDecimal,
    animationCurveFn,
  );
  const currentStyleValue = `${currentStyleNumber}${fromStyleUnit}`;

  return currentStyleValue;
}

// For navbar/modals https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open (See also: index.tsx)
export function preventBodyScrolling(): void {
  const { documentElement } = document;
  const scrollY = documentElement.style.getPropertyValue('--scroll-y');
  const { body } = document;

  body.style.position = 'fixed';
  body.style.top = `-${scrollY}`;
}

export function restoreBodyScrolling(): void {
  const { body } = document;
  const scrollY = body.style.top;

  body.style.position = '';
  body.style.top = '';

  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
}

export function validateEmail(email: string): boolean {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

const WAITLIST_SIGNUP_LOCALSTORAGE_KEY = 'hellosaurus:waitlist:registered' as const;

export function getUserWaitListEmail(): string {
  return localStorage.getItem(WAITLIST_SIGNUP_LOCALSTORAGE_KEY) || '';
}

export async function addUserToWaitList(email: string): Promise<Response> {
  const anonymousId = window.analytics.user().anonymousId();

  const body = JSON.stringify({
    anonymousId,
    email,
  });

  localStorage.setItem(WAITLIST_SIGNUP_LOCALSTORAGE_KEY, email);

  return window.fetch('https://api.hellosaurus.com/analytics/subscribe', {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
}

// Note: Returns true for touch screen desktops, but good enough for what we need
export function isDesktop(): boolean {
  return !('ontouchstart' in document.documentElement);
}

export function isSmallScreen(): boolean {
  const BOOTSTRAP_SM_PX = 576;
  return window.innerWidth <= BOOTSTRAP_SM_PX;
}

export function getRandomElementInArray<T extends unknown>(arr: T[], excludedElement: T): T {
  const arrWithoutExclusion = arr.filter((el) => el !== excludedElement);
  return arrWithoutExclusion[Math.floor(Math.random() * arrWithoutExclusion.length)];
}

export const isWebGLSupported = () => !!document.createElement('canvas').getContext('webgl2');
