// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DemoSection_demo-section___1mtGAhmJ5R {\n  align-items: initial;\n  padding-bottom: 4em;\n  flex-direction: row;\n}\n\n  .DemoSection_demo-section___1mtGAhmJ5R .DemoSection_demo-section__info___pLk1icpVij {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n\n  .DemoSection_demo-section___1mtGAhmJ5R .DemoSection_demo-section__info___pLk1icpVij img {\n      height: auto;\n      width: 30em;\n      margin-top: 2em;\n    }\n\n@media (max-width: 992px) {\n  .DemoSection_demo-section___1mtGAhmJ5R {\n    padding-top: 4em;\n    flex-direction: column;\n  }\n\n    .DemoSection_demo-section___1mtGAhmJ5R > div {\n      margin: 0 !important;\n    }\n      .DemoSection_demo-section___1mtGAhmJ5R .DemoSection_demo-section__info___pLk1icpVij img {\n        width: initial;\n        align-self: flex-start;\n        margin-bottom: 1em;\n      }\n}\n", ""]);
// Exports
exports.locals = {
	"demo-section": "DemoSection_demo-section___1mtGAhmJ5R",
	"demoSection": "DemoSection_demo-section___1mtGAhmJ5R",
	"demo-section__info": "DemoSection_demo-section__info___pLk1icpVij",
	"demoSectionInfo": "DemoSection_demo-section__info___pLk1icpVij"
};
module.exports = exports;
