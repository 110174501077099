/// <reference types="@types/segment-analytics" />

import '../css/index.css';

import * as React from 'react';
import { render } from 'react-dom';
import Routes from './routes';

declare global {
  interface Window {
    EPISODE_CONFIG_FOR_UNITY: Record<string, any>;
    UnityLoader: Record<string, any>;
    unityInstance: Record<string, any>;
    updateActiveDoit: () => void;
    updateVideoTime: (videoTime: number) => void;
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

render(<Routes />, document.getElementById('root'));

// For navbar/modals https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open (See also: utils.ts)
window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
});
