// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TabletAndPhone_tablet-and-phone___lsaRGmrjoJ {\n  width: 50vw;\n  height: 670px;\n}\n\n  .TabletAndPhone_tablet-and-phone___lsaRGmrjoJ img {\n    position: absolute;\n    right: -17em;\n    min-height: 350px;\n    top: 1em;\n    max-width: 80%;\n    height: auto;\n  }\n\n@media (max-width: 1200px) {\n  .TabletAndPhone_tablet-and-phone___lsaRGmrjoJ {\n    height: 550px;\n  }\n}\n\n@media (max-width: 992px) {\n  .TabletAndPhone_tablet-and-phone___lsaRGmrjoJ {\n    width: 100%;\n    height: 50vw;\n    margin-left: -2em;\n    margin-right: -2em;\n  }\n\n    .TabletAndPhone_tablet-and-phone___lsaRGmrjoJ img {\n      position: initial;\n      width: initial;\n      height: initial;\n      min-height: initial;\n    }\n}\n\n@media (max-width: 576px) {\n  .TabletAndPhone_tablet-and-phone___lsaRGmrjoJ {\n    width: initial;\n    height: auto;\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    margin: 0;\n  }\n\n    .TabletAndPhone_tablet-and-phone___lsaRGmrjoJ img {\n      max-width: initial;\n      align-self: center;\n      width: 115%;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"tablet-and-phone": "TabletAndPhone_tablet-and-phone___lsaRGmrjoJ",
	"tabletAndPhone": "TabletAndPhone_tablet-and-phone___lsaRGmrjoJ"
};
module.exports = exports;
