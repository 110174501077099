import React from 'react';

import styles from './TabletAndPhone.css';

import Image from '../image/Image';

import tabletAndPhoneStandard from '../../../images/tablet-and-phone.png';
import tabletAndPhoneRetina from '../../../images/tablet-and-phone@2x.png';

function TabletAndPhone(): JSX.Element {
  return (
    <div className={styles.tabletAndPhone}>
      <Image
        standard={tabletAndPhoneStandard}
        retina={tabletAndPhoneRetina}
        alt="Hellosaurus app on tablet and mobile"
        startStyles={{ top: '0em', right: '-19em' }}
        endStyles={{ top: '15em', right: '-5em' }}
      />
    </div>
  );
}

export default TabletAndPhone;
