import React from 'react';
import cx from 'classnames';

import Input from '../input/Input';
import { InputTypes } from '../input/types';
import { SignupFormState, SignupFormProps } from './types';
import styles from './SignupForm.css';
import * as utils from '../../utils';

class SignupForm extends React.PureComponent<SignupFormProps, SignupFormState> {
  private emailInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props: SignupFormProps) {
    super(props);

    const registeredEmail = utils.getUserWaitListEmail();

    this.state = {
      email: registeredEmail,
      emailValid: registeredEmail ? true : undefined,
      success: Boolean(registeredEmail),
      isLoading: true,
    };

    analytics.ready(() => {
      this.setState({ isLoading: false });

      if (utils.isDesktop() && !this.state.isLoading) {
        this.emailInputRef.current?.focus();
      }
    });
  }

  async handleSubmitForm(e: React.FormEvent<EventTarget>): Promise<void> {
    e.preventDefault();

    const { onSignUp } = this.props;
    const { email } = this.state;

    this.setState({ isLoading: true });

    await utils.addUserToWaitList(email);
    onSignUp(email);
  }

  handleEmailChange(value: string) {
    this.setState({ email: value, emailValid: utils.validateEmail(value) });
  }

  render(): JSX.Element | null {
    const { className } = this.props;
    const { email, emailValid, success, isLoading } = this.state;
    const signupFormStyles = cx(styles.container, className);

    if (success) {
      return null;
    }

    return (
      <div className={signupFormStyles}>
        <form onSubmit={(e) => this.handleSubmitForm(e)}>
          <Input
            placeholder="Get early access"
            onChange={(value: string) => this.handleEmailChange(value)}
            invalid={typeof emailValid !== 'undefined' && !emailValid}
            value={email}
            type={InputTypes.EMAIL}
            canSubmit={emailValid && !success}
            hasInlineSubmit
            ref={this.emailInputRef}
            isDisabled={isLoading}
          />
        </form>
      </div>
    );
  }
}

export default SignupForm;
