import React from 'react';
import cx from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import styles from './NavBar.css';
import commonStyles from '../../../css/index.css';

import { ButtonPrimary, TwitterFollowButton } from '../button/Button';
import Image from '../image/Image';
import EarlyAccessModal from '../modal/modals/EarlyAccessModal';
import WaitListModal from '../modal/modals/WaitListModal';

import hellosaurusStandard from '../../../images/logos/hellosaurus.png';
import hellosaurusRetina from '../../../images/logos/hellosaurus@2x.png';
import hellosaurusSmallStandard from '../../../images/logos/hellosaurus-sm.png';
import hellosaurusSmallRetina from '../../../images/logos/hellosaurus-sm@2x.png';
import hellosaurusTextStandard from '../../../images/logos/hellosaurus-text.png';
import hellosaurusTextRetina from '../../../images/logos/hellosaurus-text@2x.png';

import { NavBarProps, NavBarState } from './types';
import * as utils from '../../utils';

class NavBar extends React.PureComponent<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);

    this.state = {
      isEarlyAccessModalOpen: false,
      isScrolledDown: false,
      hasScrolled: false,
      waitListEmail: utils.getUserWaitListEmail(),
      isWaitListModalOpen: false,
      isLoading: true,
    };

    analytics.ready(() => {
      this.setState({ isLoading: false });
    });
  }

  static APPROX_NAV_HEIGHT = 165;

  handleVisibility(isVisible: boolean) {
    this.setState(({ hasScrolled }) => ({
      isScrolledDown: !isVisible,
      hasScrolled: hasScrolled || !isVisible,
    }));
  }

  handleEarlyAccessModalToggle(submittedEmail?: string) {
    this.setState(({ isEarlyAccessModalOpen }) => ({
      isEarlyAccessModalOpen: !isEarlyAccessModalOpen && !submittedEmail,
      waitListEmail: submittedEmail ?? '',
      isWaitListModalOpen: Boolean(submittedEmail),
    }));
  }

  handleWaitListModalToggle() {
    this.setState(({ isWaitListModalOpen }) => ({ isWaitListModalOpen: !isWaitListModalOpen }));
  }

  handleLogoClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const { isFixed } = this.props;
    const {
      isEarlyAccessModalOpen,
      isScrolledDown,
      hasScrolled,
      isWaitListModalOpen,
      waitListEmail,
      isLoading,
    } = this.state;
    const navbarStyles = cx(styles.navbar, {
      [styles.navbarFixed]: isFixed,
    });
    const navbarSlimStyles = cx(styles.navbar, styles.navbarSlim, {
      [styles.navbarShowing]: isScrolledDown,
      [styles.navbarHiding]: hasScrolled && !isScrolledDown,
    });
    const navButtonElement = waitListEmail ? (
      <TwitterFollowButton />
    ) : (
      <ButtonPrimary
        isDisabled={isLoading}
        label="Get Early Access"
        onClick={() => this.handleEarlyAccessModalToggle()}
      />
    );

    return (
      <>
        <VisibilitySensor
          onChange={(isVisible) => !isFixed && this.handleVisibility(isVisible)}
          partialVisibility={true}
          offset={{ bottom: NavBar.APPROX_NAV_HEIGHT }}
        >
          <div className={navbarStyles}>
            <div className={styles.navbarLogo} onClick={this.handleLogoClick}>
              <Image
                className={commonStyles.lgUp}
                standard={hellosaurusStandard}
                retina={hellosaurusRetina}
                alt="Hellosaurus Logo"
              />
              <Image
                className={commonStyles.lgDown}
                standard={hellosaurusSmallStandard}
                retina={hellosaurusSmallRetina}
                alt="Hellosaurus Small Logo"
              />
            </div>
            {!isFixed && <div className={styles.navbarContents}>{navButtonElement}</div>}
          </div>
        </VisibilitySensor>
        <div className={navbarSlimStyles}>
          <Image
            className={commonStyles.smUp}
            standard={hellosaurusTextStandard}
            retina={hellosaurusTextRetina}
            alt="Hellosaurus Text Logo"
            onClick={this.handleLogoClick}
          />
          <Image
            className={commonStyles.smDown}
            standard={hellosaurusSmallStandard}
            retina={hellosaurusSmallRetina}
            alt="Hellosaurus Small Logo"
            onClick={this.handleLogoClick}
          />
          <div className={styles.navbarContents}>{navButtonElement}</div>
        </div>
        <EarlyAccessModal
          isOpen={isEarlyAccessModalOpen}
          onClose={(submittedEmail?: string) => this.handleEarlyAccessModalToggle(submittedEmail)}
        />
        <WaitListModal
          email={waitListEmail}
          isOpen={isWaitListModalOpen}
          onClose={() => this.handleWaitListModalToggle()}
        />
      </>
    );
  }
}

export default NavBar;
