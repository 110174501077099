import React from 'react';
import NavBar from '../components/navbar/NavBar';
import IntroSection from '../sections/IntroSection';
import DemoSection from '../sections/DemoSection';
import FeaturingSection from '../sections/FeaturingSection';
import TestimonialSection from '../sections/TestimonialSection';
import PressSection from '../sections/PressSection';
import FooterSection from '../sections/FooterSection';

class HomePage extends React.PureComponent {
  private navbarRef: React.RefObject<NavBar> = React.createRef();

  handleSignUp(submittedEmail: string) {
    this.navbarRef.current?.handleEarlyAccessModalToggle(submittedEmail);
  }

  render(): JSX.Element {
    return (
      <>
        <NavBar ref={this.navbarRef} />
        <IntroSection onSignUp={(submittedEmail) => this.handleSignUp(submittedEmail)} />
        <DemoSection />
        <FeaturingSection />
        <TestimonialSection />
        <PressSection />
        <FooterSection />
      </>
    );
  }
}

export default HomePage;
