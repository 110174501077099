// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HeaderWithSubheader_header-with-subheader___1vBitd6hjR {\n  max-width: 37em;\n}\n\n  .HeaderWithSubheader_header-with-subheader___1vBitd6hjR h1 {\n    margin: 0;\n  }\n\n@media (max-width: 992px) {\n  .HeaderWithSubheader_header-with-subheader___1vBitd6hjR {\n    max-width: 30em;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"header-with-subheader": "HeaderWithSubheader_header-with-subheader___1vBitd6hjR",
	"headerWithSubheader": "HeaderWithSubheader_header-with-subheader___1vBitd6hjR"
};
module.exports = exports;
