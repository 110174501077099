// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TestimonialSection_testimonial-section___3BL_dVrKXY {\n  padding: 6em;\n}\n\n  .TestimonialSection_testimonial-section___3BL_dVrKXY h2 {\n    text-align: center;\n    margin-bottom: 1em;\n    margin-top: 0;\n  }\n\n@media (max-width: 576px) {\n  .TestimonialSection_testimonial-section___3BL_dVrKXY {\n    padding: 4em 2em;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"testimonial-section": "TestimonialSection_testimonial-section___3BL_dVrKXY",
	"testimonialSection": "TestimonialSection_testimonial-section___3BL_dVrKXY"
};
module.exports = exports;
