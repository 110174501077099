import React from 'react';
import cx from 'classnames';
import { BlueSection } from '../components/section/Section';

import styles from './DemoSection.css';
import commonStyles from '../../css/index.css';

import HeaderWithSubheader from '../components/header_with_subheader/HeaderWithSubheader';
import PlayerPreview from '../components/player_preview/PlayerPreview';
import Image from '../components/image/Image';

import tryItYourselfRightStandard from '../../images/player/try-it-yourself-right.png';
import tryItYourselfRightRetina from '../../images/player/try-it-yourself-right@2x.png';
import tryItYourselfDownStandard from '../../images/player/try-it-yourself-down.png';
import tryItYourselfDownRetina from '../../images/player/try-it-yourself-down@2x.png';

const DemoSection = () => {
  const sectionStyles = cx(styles.demoSection, commonStyles.splitSection);

  return (
    <BlueSection className={sectionStyles} hasWavingDino>
      <div className={styles.demoSectionInfo}>
        <HeaderWithSubheader
          header="Classes kids can play, not just watch."
          subheader="Transform screen time into an active and fun experience for everyone!"
        />
        <Image
          className={commonStyles.lgUp}
          standard={tryItYourselfRightStandard}
          retina={tryItYourselfRightRetina}
          alt="Try It Yourself!"
        />
        <Image
          className={commonStyles.lgDown}
          standard={tryItYourselfDownStandard}
          retina={tryItYourselfDownRetina}
          alt="Try It Yourself!"
        />
      </div>
      <div>
        <PlayerPreview />
      </div>
    </BlueSection>
  );
};

export default DemoSection;
