import React from 'react';

import Image from '../image/Image';

import styles from './NavBar.css';

import hellosaurusStandard from '../../../images/logos/hellosaurus.png';
import hellosaurusRetina from '../../../images/logos/hellosaurus@2x.png';

const StaticNavBar = () => (
  <div style={{ textAlign: 'center', margin: 24 }}>
    <a className={styles.navbarLogo} href="/">
      <Image standard={hellosaurusStandard} retina={hellosaurusRetina} alt="Hellosaurus Logo" />
    </a>
  </div>
);

export default StaticNavBar;
