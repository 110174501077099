import * as React from 'react';

import { HeaderWithSubheaderProps } from './types';
import styles from './HeaderWithSubheader.css';

function HeaderWithSubheader({ header, subheader }: HeaderWithSubheaderProps): JSX.Element {
  return (
    <div className={styles.headerWithSubheader}>
      <h1>{header}</h1>
      <p>{subheader}</p>
    </div>
  );
}

export default HeaderWithSubheader;
