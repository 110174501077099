// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PaginationDots_pagination-dots___TM09oMFV-4 {\n  display: flex;\n  justify-content: center;\n  margin-top: 2em;\n}\n\n.PaginationDots_pagination-dot___3NQ7GbCYqI {\n  opacity: 0.5;\n  background-color: #5a3e75;\n  margin-left: 0.25em;\n  margin-right: 0.25em;\n  cursor: pointer;\n  border-radius: 50%;\n  width: 16px;\n  height: 16px\n}\n\n.PaginationDots_pagination-dot___3NQ7GbCYqI.PaginationDots_pagination-dot--active___1WaADTnO6B {\n    opacity: 1;\n    cursor: initial;\n  }\n", ""]);
// Exports
exports.locals = {
	"pagination-dots": "PaginationDots_pagination-dots___TM09oMFV-4",
	"paginationDots": "PaginationDots_pagination-dots___TM09oMFV-4",
	"pagination-dot": "PaginationDots_pagination-dot___3NQ7GbCYqI",
	"paginationDot": "PaginationDots_pagination-dot___3NQ7GbCYqI",
	"pagination-dot--active": "PaginationDots_pagination-dot--active___1WaADTnO6B",
	"paginationDotActive": "PaginationDots_pagination-dot--active___1WaADTnO6B"
};
module.exports = exports;
