// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PressSection_press-section___2BUT2JPuoX {\n  padding: 6em;\n}\n\n  .PressSection_press-section___2BUT2JPuoX h2 {\n    text-align: center;\n    margin-bottom: 1em;\n    margin-top: 0;\n  }\n\n@media (max-width: 576px) {\n  .PressSection_press-section___2BUT2JPuoX {\n    padding: 4em 2em;\n  }\n}\n\n.PressSection_press-box-container___3scYCdMUQ8 {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.PressSection_press-box___3vjiA_EvTl {\n  background: #fbf2cc;\n  box-shadow: 0px 5px 29px rgba(88, 53, 115, 0.1);\n  border-radius: 30px;\n  padding: 38px 0;\n  margin: 12px;\n  width: 300px;\n  height: 38px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media (max-width: 768px) {\n  .PressSection_press-box___3vjiA_EvTl {\n    margin: 8px 8px;\n    width: 156px;\n    padding: 28px 15px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"press-section": "PressSection_press-section___2BUT2JPuoX",
	"pressSection": "PressSection_press-section___2BUT2JPuoX",
	"press-box-container": "PressSection_press-box-container___3scYCdMUQ8",
	"pressBoxContainer": "PressSection_press-box-container___3scYCdMUQ8",
	"press-box": "PressSection_press-box___3vjiA_EvTl",
	"pressBox": "PressSection_press-box___3vjiA_EvTl"
};
module.exports = exports;
