// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WaitListModal_wait-list-modal___2Acx7QNp8r h4 {\n    margin-bottom: 0;\n  }\n  .WaitListModal_wait-list-modal___2Acx7QNp8r .WaitListModal_wait-list-modal__share-buttons___1etTfIRn0a {\n    display: flex;\n    flex-direction: column;\n  }\n  .WaitListModal_wait-list-modal___2Acx7QNp8r .WaitListModal_wait-list-modal__share-buttons___1etTfIRn0a button {\n      margin-top: 1em !important\n    }\n  .WaitListModal_wait-list-modal___2Acx7QNp8r .WaitListModal_wait-list-modal__share-buttons___1etTfIRn0a button:first-child {\n        margin-top: 0 !important;\n      }\n", ""]);
// Exports
exports.locals = {
	"wait-list-modal": "WaitListModal_wait-list-modal___2Acx7QNp8r",
	"waitListModal": "WaitListModal_wait-list-modal___2Acx7QNp8r",
	"wait-list-modal__share-buttons": "WaitListModal_wait-list-modal__share-buttons___1etTfIRn0a",
	"waitListModalShareButtons": "WaitListModal_wait-list-modal__share-buttons___1etTfIRn0a"
};
module.exports = exports;
