import React from 'react';

import { CreamSection } from '../components/section/Section';
import styles from './FooterSection.css';
import Image from '../components/image/Image';
import AnimatedImage from '../components/image/AnimatedImage';

import dinoWaveRetina from '../../images/dino-wave.gif';
import dinoWaveStaticRetina from '../../images/dino-wave-static.png';
import yCombinatorStandard from '../../images/investors/y-combinator.png';
import yCombinatorRetina from '../../images/investors/y-combinator@2x.png';
import csvVenturesStandard from '../../images/investors/csv-ventures.png';
import csvVenturesRetina from '../../images/investors/csv-ventures@2x.png';
import shrugCapitalStandard from '../../images/investors/shrug-capital.png';
import shrugCapitalRetina from '../../images/investors/shrug-capital@2x.png';

const DINO_WAVE_GIF_DURATION_MS = 2840;

class FooterSection extends React.PureComponent {
  render() {
    return (
      <CreamSection className={styles.footerSection}>
        <div className={styles.footerSectionLeft}>
          <AnimatedImage
            staticImage={dinoWaveStaticRetina}
            animatedImage={dinoWaveRetina}
            durationMs={DINO_WAVE_GIF_DURATION_MS}
            isClickable
          />
          <div className={styles.footerInfo}>
            <strong>Contact</strong>
            <p>
              Want to help build the future of kids media with us? Visit{' '}
              <a href="https://jobs.hellosaurus.com">jobs.hellosaurus.com</a>
            </p>
            <p>
              Creator interested in working with us? Email{' '}
              <a href="mailto:creators@hellosaurus.com">creators@hellosaurus.com</a>
            </p>
            <p>
              For all other questions, email{' '}
              <a href="mailto:hello@hellosaurus.com">hello@hellosaurus.com</a>
            </p>
          </div>
        </div>
        <div>
          <div className={styles.footerInfo}>
            <strong>Backed By</strong>
            <div className={styles.footerInfoImages}>
              <Image
                standard={yCombinatorStandard}
                retina={yCombinatorRetina}
                alt="Y Combinator Logo"
              />
              <Image
                standard={csvVenturesStandard}
                retina={csvVenturesRetina}
                alt="CSV Ventures Logo"
              />
              <Image
                standard={shrugCapitalStandard}
                retina={shrugCapitalRetina}
                alt="Shrug Capital Logo"
              />
            </div>
          </div>
        </div>
      </CreamSection>
    );
  }
}

export default FooterSection;
