import React from 'react';

import { CreamDarkSection } from '../components/section/Section';
import styles from './TestimonialSection.css';
import Testimonials from '../components/testimonials/Testimonials';
import { TestimonialProps } from '../components/testimonials/types';

class TestimonialSection extends React.PureComponent {
  static TESTIMONIALS: TestimonialProps[] = [
    {
      quote:
        'I loved every second of it. Fantastic vision, and extremely well executed. Love the satisfying interactive elements, the pacing, and the humor.',
      fromName: 'Chris Rettstatt',
      fromTitle: "Award-winning kids' digital media strategist",
    },
    {
      quote: (
        <>
          It was fun!
          <br />
          Fun, fun, fun, fun, fun, fun, fun, fun, fun, fun, fun!
        </>
      ),
      fromName: 'Nadine',
      fromTitle: '5 years old',
    },
    {
      quote:
        'This is the new wave of television, allowing children to co-create and feel connected, something we need now more than ever.',
      fromName: 'Liz Casey Searl',
      fromTitle: 'Early childhood educator',
    },
  ];

  render() {
    return (
      <CreamDarkSection className={styles.testimonialSection}>
        <h2>Don&apos;t Just Take Our Word For It!</h2>
        <Testimonials testimonials={TestimonialSection.TESTIMONIALS} />
      </CreamDarkSection>
    );
  }
}

export default TestimonialSection;
