import React from 'react';
import cx from 'classnames';

import { TestimonialProps } from './types';

import styles from './Testimonial.css';

class Testimonial extends React.PureComponent<TestimonialProps> {
  render() {
    const { quote, fromName, fromTitle, isActive, testimonialRef } = this.props;
    const testimonialStyles = cx(styles.testimonial, isActive && styles.testimonialActive);

    return (
      <div ref={testimonialRef} className={testimonialStyles}>
        <div className={styles.testimonialQuote}>
          <h3>“{quote}”</h3>
        </div>
        <div className={styles.testimonialFrom}>
          <h4>{fromName}</h4>
          <p>{fromTitle}</p>
        </div>
      </div>
    );
  }
}

export default Testimonial;
