import React from 'react';

import styles from './FeaturingSection.css';
import { BlueLightSection } from '../components/section/Section';
import Image from '../components/image/Image';
import Carousell from '../components/carousell/Carousell';

import motherGooseStandard from '../../images/logos/mother-goose-club.png';
import motherGooseRetina from '../../images/logos/mother-goose-club@2x.png';
import cosmicKidsStandard from '../../images/logos/cosmic-kids.png';
import cosmicKidsRetina from '../../images/logos/cosmic-kids@2x.png';
import thePopUpsStandard from '../../images/logos/the-pop-ups.png';
import thePopUpsRetina from '../../images/logos/the-pop-ups@2x.png';
// import superSimpleStandard from '../../images/logos/super-simple.png';
// import superSimpleRetina from '../../images/logos/super-simple@2x.png';
import bouncePatrolStandard from '../../images/logos/bounce-patrol.png';
import bouncePatrolRetina from '../../images/logos/bounce-patrol@2x.png';
import highlightsRetina from '../../images/logos/highlights@2x.png';
import worldByCharlieRetina from '../../images/logos/world-by-charlie@2x.png';
import berknerStandard from '../../images/logos/berkner.png';
import kidzbopStandard from '../../images/logos/kidzbop.png';

const creator = (name, link, standard, retina) => {
  return {
    name,
    link,
    standard,
    retina,
  };
};

const creators = [
  creator('Mother Goose', 'https://mothergooseclub.com', motherGooseStandard, motherGooseRetina),
  creator('Laurie Bernker Band', 'https://laurieberkner.com', berknerStandard, berknerStandard),
  creator('Cosmic Kids', 'https://www.cosmickids.com', cosmicKidsStandard, cosmicKidsRetina),
  creator('The Pop Ups', 'https://thepopups.com', thePopUpsStandard, thePopUpsRetina),
  creator(
    'Bounce Patrol',
    'https://www.bouncepatrol.com',
    bouncePatrolStandard,
    bouncePatrolRetina,
  ),
  creator(
    'World By Charlie',
    'https://www.worldbycharlie.com',
    worldByCharlieRetina,
    worldByCharlieRetina,
  ),
  creator('Kidz Bop', 'https://kidzbop.com', kidzbopStandard, kidzbopStandard),
  creator('Highlights', 'https://www.highlightskids.com', highlightsRetina, highlightsRetina),
];

class FeaturingSection extends React.PureComponent {
  render() {
    return (
      <BlueLightSection className={styles.featuringSection}>
        <p>Featuring Content By</p>
        <div className={styles.logosContainer}>
          <Carousell>
            {creators.map(({ name, link, standard, retina }) => (
              <a href={link} target="_blank">
                <Image standard={standard} retina={retina} alt={`${name} Logo`} />
              </a>
            ))}
          </Carousell>
        </div>
        <p>…and many more!</p>
      </BlueLightSection>
    );
  }
}

export default FeaturingSection;
