import * as React from 'react';
import cx from 'classnames';

import { SectionProps, BaseSectionProps } from './types';
import { SectionColors, WAVING_DINO_POSITIONS, DINO_SIDE_WAVING_DURATION_MS } from './constants';
import styles from './Section.css';

import AnimatedImage from '../image/AnimatedImage';
import dinoSideWaveRetina from '../../../images/dino-side-wave.gif';
import dinoSideWaveStaticRetina from '../../../images/dino-side-wave-static.png';

function BaseSection({ children, className, color, hasWavingDino }: BaseSectionProps): JSX.Element {
  const sectionStyles = cx(className, styles.section, (styles as any)[`section--${color}`]);

  if (!hasWavingDino) {
    return <section className={sectionStyles}>{children}</section>;
  }

  const wavingDinoDirections =
    WAVING_DINO_POSITIONS[Math.floor(Math.random() * WAVING_DINO_POSITIONS.length)];
  const wavingDinoStyles = cx(
    styles.dinoSideWaving,
    (styles as any)[`dino-side-waving--${wavingDinoDirections}`],
  );

  return (
    <section className={sectionStyles}>
      {children}
      <AnimatedImage
        className={wavingDinoStyles}
        staticImage={dinoSideWaveStaticRetina}
        animatedImage={dinoSideWaveRetina}
        durationMs={DINO_SIDE_WAVING_DURATION_MS}
      />
    </section>
  );
}

export const CreamSection = (props: SectionProps): JSX.Element => (
  <BaseSection color={SectionColors.CREAM} {...props} />
);

export const BlueSection = (props: SectionProps): JSX.Element => (
  <BaseSection color={SectionColors.BLUE} {...props} />
);

export const CreamDarkSection = (props: SectionProps): JSX.Element => (
  <BaseSection color={SectionColors.CREAM_DARK} {...props} />
);

export const CreamDarkerSection = (props: SectionProps): JSX.Element => (
  <BaseSection color={SectionColors.CREAM_DARKER} {...props} />
);

export const BlueLightSection = (props: SectionProps): JSX.Element => (
  <BaseSection color={SectionColors.BLUE_LIGHT} {...props} />
);
