// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Testimonial_testimonial___3l6D2HfrD1 {\n  background: #fbf2cc;\n  box-shadow: 0px 5px 29px rgba(88, 53, 115, 0.1);\n  border-radius: 30px;\n  text-align: center;\n  padding: 3em 6em;\n  opacity: 0.6;\n  margin-left: 1em;\n  margin-right: 1em;\n  transform: opacity 0.35s ease;\n  flex-shrink: 0;\n  width: 40vw;\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n}\n\n  .Testimonial_testimonial___3l6D2HfrD1 h3,\n  .Testimonial_testimonial___3l6D2HfrD1 h4,\n  .Testimonial_testimonial___3l6D2HfrD1 p {\n    margin: 0;\n  }\n\n  .Testimonial_testimonial___3l6D2HfrD1 .Testimonial_testimonial_quote___3nbFzMWSbl {\n    margin-bottom: 2em;\n  }\n\n  .Testimonial_testimonial___3l6D2HfrD1 .Testimonial_testimonial__from___1uMK4omaw3 p {\n      color: #9a8698;\n      font-size: 21px;\n      letter-spacing: initial;\n    }\n\n.Testimonial_testimonial___3l6D2HfrD1.Testimonial_testimonial--active___2g7kcUolF5 {\n    opacity: 1;\n  }\n\n@media (max-width: 1200px) {\n  .Testimonial_testimonial___3l6D2HfrD1 {\n    padding: 2em 4em;\n  }\n\n    .Testimonial_testimonial___3l6D2HfrD1 h3 {\n      font-size: 30px;\n    }\n\n    .Testimonial_testimonial___3l6D2HfrD1 h4 {\n      font-size: 28px;\n    }\n}\n\n@media (max-width: 576px) {\n  .Testimonial_testimonial___3l6D2HfrD1 {\n    width: 60vw;\n    margin-right: 0.5em;\n    margin-left: 0.5em;\n  }\n\n    .Testimonial_testimonial___3l6D2HfrD1 h3 {\n      font-size: 26px;\n    }\n\n    .Testimonial_testimonial___3l6D2HfrD1 h4 {\n      font-size: 22px;\n    }\n\n    .Testimonial_testimonial___3l6D2HfrD1 p {\n      font-size: 20px;\n      line-height: 23px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"testimonial": "Testimonial_testimonial___3l6D2HfrD1",
	"testimonial_quote": "Testimonial_testimonial_quote___3nbFzMWSbl",
	"testimonialQuote": "Testimonial_testimonial_quote___3nbFzMWSbl",
	"testimonial__from": "Testimonial_testimonial__from___1uMK4omaw3",
	"testimonialFrom": "Testimonial_testimonial__from___1uMK4omaw3",
	"testimonial--active": "Testimonial_testimonial--active___2g7kcUolF5",
	"testimonialActive": "Testimonial_testimonial--active___2g7kcUolF5"
};
module.exports = exports;
