import React from 'react';
import cx from 'classnames';

import unityUtils from './utils';
import { UnityProps } from './types';
import styles from './Unity.css';

class Unity extends React.Component<UnityProps> {
  componentDidMount() {
    unityUtils.onUnityItemIdChange(() => {});
    unityUtils.onUnityVideoTimeChange((videoTime) => {
      if (videoTime === 0) {
        this.props.onClose();
      }
    });
  }

  shouldComponentUpdate(nextProps: UnityProps, _nextState: {}) {
    return JSON.stringify(this.props) !== JSON.stringify(nextProps);
  }

  componentDidUpdate(prevProps: UnityProps) {
    const { episodePayload, isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      unityUtils.updateUnityConfig(episodePayload);
      unityUtils.updateDoits();
      unityUtils.loadAndInstantiateUnity();
    } else if (prevProps.isOpen && !isOpen) {
      unityUtils.destroyUnity();
    }
  }

  componentWillUnmount() {
    unityUtils.destroyUnity();
  }

  render() {
    const { isOpen } = this.props;
    const unityContainerStyles = cx(styles.unityContainer, {
      [styles.unityContainerOpen]: isOpen,
    });

    return <div id="unityContainer" className={unityContainerStyles} />;
  }
}

export default Unity;
