import React from 'react';
import cx from 'classnames';

import styles from './Modal.css';
import { CrossIcon } from '../icon/Icon';
import * as utils from '../../utils';
import { ModalProps, ModalState } from './types';

import dinoSideWaveRetina from '../../../images/dino-side-wave.gif';
import dinoSideWaveStaticRetina from '../../../images/dino-side-wave-static.png';
import AnimatedImage from '../image/AnimatedImage';
import { DINO_SIDE_WAVING_DURATION_MS } from '../section/constants';

class Modal extends React.PureComponent<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      isOpen: false,
      closingTimer: null,
      dinoDelayMs: 0,
      dinoClassName: '',
    };

    this.handleWindowKeydown = this.handleWindowKeydown.bind(this);
  }

  static DINO_DIRECTION_CLASS_NAMES = [
    styles.wavingDinoLeft,
    styles.wavingDinoTopRight,
    styles.wavingDinoTopLeft,
    styles.wavingDinoRight,
    styles.wavingDinoBottom,
  ];

  static DINO_DIRECTION_MOBILE_CLASS_NAMES = [
    styles.wavingDinoTopRight,
    styles.wavingDinoTopLeft,
    styles.wavingDinoBottom,
  ];

  static DINO_DELAYS_MS = [2500, 500, 5000];

  static getDerivedStateFromProps(nextProps: ModalProps, prevState: ModalState) {
    if (!nextProps.isOpen || prevState.isOpen || prevState.closingTimer) {
      return {
        isOpen: nextProps.isOpen,
      };
    }

    utils.preventBodyScrolling();

    return {
      isOpen: nextProps.isOpen,
      dinoClassName: utils.getRandomElementInArray(
        utils.isSmallScreen()
          ? Modal.DINO_DIRECTION_MOBILE_CLASS_NAMES
          : Modal.DINO_DIRECTION_CLASS_NAMES,
        prevState.dinoClassName,
      ),
      dinoDelayMs: utils.getRandomElementInArray(Modal.DINO_DELAYS_MS, prevState.dinoDelayMs),
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeydown);
  }

  componentWillUnmount() {
    utils.restoreBodyScrolling();
    window.removeEventListener('keydown', this.handleWindowKeydown);
  }

  handleWindowKeydown({ key, keyCode, target }: KeyboardEvent) {
    const isEscapeKey = key === 'Escape' || key === 'Esc' || keyCode === 27;
    const isTargetBody = (target as any).nodeName === 'BODY';

    if (!isEscapeKey || !isTargetBody) {
      return;
    }

    if (!this.state.isOpen) {
      return;
    }

    this.handleClose();
  }

  handleClose() {
    this.props.onClose();
    utils.restoreBodyScrolling();

    if (this.state.closingTimer) {
      clearInterval(this.state.closingTimer);
    }

    const MODAL_CLOSE_DURATION_MS = 1000;
    const timer = setTimeout(() => {
      this.setState({ closingTimer: null });
    }, MODAL_CLOSE_DURATION_MS);

    this.setState({ closingTimer: timer });
  }

  render() {
    const { children, isOpen, className, hasWavingDino } = this.props;
    const { closingTimer, dinoDelayMs, dinoClassName } = this.state;
    const isClosing = Boolean(closingTimer);
    const modalBackdropStyles = cx(styles.modalBackdrop, {
      [styles.modalBackdropOpen]: isOpen || isClosing,
      [styles.modalBackdropClosing]: isClosing,
    });
    const modalStyles = cx(styles.modal, className);
    const wavingDinoStyles = cx(styles.wavingDino, dinoClassName);

    return (
      <div className={modalBackdropStyles}>
        <div className={modalStyles}>
          <span className={styles.modalClose} onClick={this.handleClose.bind(this)}>
            <CrossIcon />
          </span>
          <div className={styles.modalContents}>{children}</div>
          {hasWavingDino && (
            <AnimatedImage
              key={dinoClassName}
              className={wavingDinoStyles}
              staticImage={dinoSideWaveStaticRetina}
              animatedImage={dinoSideWaveRetina}
              durationMs={DINO_SIDE_WAVING_DURATION_MS}
              delayMs={dinoDelayMs}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Modal;
