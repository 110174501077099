// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/backgrounds/scribbles-colors@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".IntroSection_intro-section___3i95-wFn6W {\n  padding: 0 6em 14em 6em;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: repeat-x;\n  background-position-y: 96%;\n  background-size: contain;\n}\n\n  .IntroSection_intro-section___3i95-wFn6W .IntroSection_intro-section__content___2VQvORh67X {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n    align-items: center;\n  }\n\n@media (max-width: 992px) {\n  .IntroSection_intro-section___3i95-wFn6W {\n    padding: 0 6em 8em 6em;\n  }\n\n    .IntroSection_intro-section___3i95-wFn6W .IntroSection_intro-section__content___2VQvORh67X {\n      flex-direction: column;\n      align-items: initial;\n    }\n}\n\n@media (max-width: 768px) {\n  .IntroSection_intro-section___3i95-wFn6W {\n    background-size: 200%;\n  }\n}\n\n@media (max-width: 576px) {\n  .IntroSection_intro-section___3i95-wFn6W {\n    padding: 0 2em 6em 2em;\n    background-size: 300%;\n    background-position-y: 99%;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"intro-section": "IntroSection_intro-section___3i95-wFn6W",
	"introSection": "IntroSection_intro-section___3i95-wFn6W",
	"intro-section__content": "IntroSection_intro-section__content___2VQvORh67X",
	"introSectionContent": "IntroSection_intro-section__content___2VQvORh67X"
};
module.exports = exports;
