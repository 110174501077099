import React from 'react';
import cx from 'classnames';

import styles from './PaginationDots.css';

type PaginationDotsProps = {
  count: number;
  activeIndex: number;
  onClick: (activeIndex: number) => void;
};

class PaginationDots extends React.PureComponent<PaginationDotsProps> {
  render(): JSX.Element {
    const { count, activeIndex, onClick } = this.props;
    const paginationDots = new Array(count).fill(1).map((_, i) => {
      const isActive = i === activeIndex;
      const paginationDotStyles = cx(styles.paginationDot, {
        [styles.paginationDotActive]: isActive,
      });
      return (
        <span
          key={i}
          className={paginationDotStyles}
          onClick={() => !isActive && onClick(i)}
        ></span>
      );
    });

    return <div className={styles.paginationDots}>{paginationDots}</div>;
  }
}

export default PaginationDots;
