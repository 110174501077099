import React from 'react';

import { CarousellProps } from './types';
import styles from './Carousell.css';

class Carousell extends React.PureComponent<CarousellProps> {
  render() {
    const { children } = this.props;

    return <div className={styles.carousell}>{children}</div>;
  }
}

export default Carousell;
