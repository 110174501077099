import React from 'react';
import cx from 'classnames';

import styles from './PlayerPreview.css';
import Image from '../image/Image';
import Unity from '../unity/Unity';
import UnityMobileNotSupportedModal from '../modal/modals/UnityMobileNotSupportedModal';
import * as utils from '../../utils';

import playerDemoRetina from '../../../images/player/player-demo-content.jpg';
import playStandard from '../../../images/player/play.png';
import playRetina from '../../../images/player/play@2x.png';
import { Episode } from '../unity/types';

import { PlayerPreviewState } from './types';

class PlayerPreview extends React.PureComponent<{}, PlayerPreviewState> {
  static DEMO_EPISODE: Episode = {
    episodeId: 'ckd4pvxhj002i1ipg02ie3f5y',
    title: 'Robot Party With Devon Quinn',
    doits: [
      {
        itemId: 'ckd4pvxi1002k1ipgeoct8oqq',
        name: 'Intro',
        baseTemplateId: 'soundboard-it-template',
        dynamicTemplateId: 'soundboard-it-template-4',
        startSecs: 40,
        finishSecs: 70,
        params: {
          panels: [
            {
              background: {
                color: '#df4e4e',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacxz004u01pg767uaktt',
                  awsInternalPath: 'doits/ckcxwacxz004u01pg767uaktt.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxz004u01pg767uaktt.png',
                  editedName: 'tambourine_up.png',
                  formattedSize: '31.96 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacxy004t01pgcqrx17ga',
                  awsInternalPath: 'doits/ckcxwacxy004t01pgcqrx17ga.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxy004t01pgcqrx17ga.png',
                  editedName: 'tambourine_down.png',
                  formattedSize: '29.57 kB',
                  formattedCreated: '4 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwb9jq004v01pgfdq57aj9',
                  awsInternalPath: 'doits/ckcxwb9jq004v01pgfdq57aj9.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwb9jq004v01pgfdq57aj9.mp3',
                  editedName: 'tambourine.mp3',
                  formattedSize: '17.94 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacxz004u01pg767uaktt.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacxy004t01pgcqrx17ga.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwb9jq004v01pgfdq57aj9.mp3',
                spriteLocalUri: 'ckcxwacxz004u01pg767uaktt.png',
                pressedSpriteLocalUri: 'ckcxwacxy004t01pgcqrx17ga.png',
                audioLocalUri: 'ckcxwb9jq004v01pgfdq57aj9.mp3',
              },
            },
            {
              background: {
                color: '#9114ef',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacx4004o01pg21ki02q8',
                  awsInternalPath: 'doits/ckcxwacx4004o01pg21ki02q8.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacx4004o01pg21ki02q8.png',
                  editedName: 'conga_up.png',
                  formattedSize: '27.16 kB',
                  formattedCreated: 'a minute ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacwy004n01pg3pxaavel',
                  awsInternalPath: 'doits/ckcxwacwy004n01pg3pxaavel.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacwy004n01pg3pxaavel.png',
                  editedName: 'conga_down.png',
                  formattedSize: '30.16 kB',
                  formattedCreated: 'a minute ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwccad004w01pgdy8hcrpj',
                  awsInternalPath: 'doits/ckcxwccad004w01pgdy8hcrpj.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwccad004w01pgdy8hcrpj.mp3',
                  editedName: 'conga.mp3',
                  formattedSize: '7.46 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacx4004o01pg21ki02q8.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacwy004n01pg3pxaavel.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwccad004w01pgdy8hcrpj.mp3',
                spriteLocalUri: 'ckcxwacx4004o01pg21ki02q8.png',
                pressedSpriteLocalUri: 'ckcxwacwy004n01pg3pxaavel.png',
                audioLocalUri: 'ckcxwccad004w01pgdy8hcrpj.mp3',
              },
            },
            {
              background: {
                color: '#48ba8f',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacxr004q01pgg3ok5mxr',
                  awsInternalPath: 'doits/ckcxwacxr004q01pgg3ok5mxr.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxr004q01pgg3ok5mxr.png',
                  editedName: 'guiro_up.png',
                  formattedSize: '39.55 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacx4004p01pg6ptj635r',
                  awsInternalPath: 'doits/ckcxwacx4004p01pg6ptj635r.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacx4004p01pg6ptj635r.png',
                  editedName: 'guiro_down.png',
                  formattedSize: '28.16 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwccad004x01pg67ijg90k',
                  awsInternalPath: 'doits/ckcxwccad004x01pg67ijg90k.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwccad004x01pg67ijg90k.mp3',
                  editedName: 'guiro.mp3',
                  formattedSize: '12.32 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacxr004q01pgg3ok5mxr.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacx4004p01pg6ptj635r.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwccad004x01pg67ijg90k.mp3',
                spriteLocalUri: 'ckcxwacxr004q01pgg3ok5mxr.png',
                pressedSpriteLocalUri: 'ckcxwacx4004p01pg6ptj635r.png',
                audioLocalUri: 'ckcxwccad004x01pg67ijg90k.mp3',
              },
            },
            {
              background: {
                color: '#43c2cc',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacxy004s01pg4wlxd6z0',
                  awsInternalPath: 'doits/ckcxwacxy004s01pg4wlxd6z0.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxy004s01pg4wlxd6z0.png',
                  editedName: 'maracas_up.png',
                  formattedSize: '30.46 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacxr004r01pg2nq87ath',
                  awsInternalPath: 'doits/ckcxwacxr004r01pg2nq87ath.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxr004r01pg2nq87ath.png',
                  editedName: 'maracas_down.png',
                  formattedSize: '16.07 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwccaj004y01pge6bsafx3',
                  awsInternalPath: 'doits/ckcxwccaj004y01pge6bsafx3.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwccaj004y01pge6bsafx3.mp3',
                  editedName: 'maracas.mp3',
                  formattedSize: '3.44 kB',
                  formattedCreated: 'a minute ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 340,
                  y: 340,
                },
                position: {
                  x: -10,
                  y: -40,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacxy004s01pg4wlxd6z0.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacxr004r01pg2nq87ath.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwccaj004y01pge6bsafx3.mp3',
                spriteLocalUri: 'ckcxwacxy004s01pg4wlxd6z0.png',
                pressedSpriteLocalUri: 'ckcxwacxr004r01pg2nq87ath.png',
                audioLocalUri: 'ckcxwccaj004y01pge6bsafx3.mp3',
              },
            },
          ],
          dynamicTemplateId: 'soundboard-it-template-4',
          itemId: 'ckd4pvxi1002k1ipgeoct8oqq',
        },
      },
      {
        itemId: 'ckd4pvxi1002n1ipga1upet5j',
        name: 'Outro',
        baseTemplateId: 'soundboard-it-template',
        dynamicTemplateId: 'soundboard-it-template-4',
        startSecs: 912,
        finishSecs: 948,
        params: {
          panels: [
            {
              background: {
                color: '#df4e4e',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacxz004u01pg767uaktt',
                  awsInternalPath: 'doits/ckcxwacxz004u01pg767uaktt.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxz004u01pg767uaktt.png',
                  editedName: 'tambourine_up.png',
                  formattedSize: '31.96 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacxy004t01pgcqrx17ga',
                  awsInternalPath: 'doits/ckcxwacxy004t01pgcqrx17ga.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxy004t01pgcqrx17ga.png',
                  editedName: 'tambourine_down.png',
                  formattedSize: '29.57 kB',
                  formattedCreated: '4 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwb9jq004v01pgfdq57aj9',
                  awsInternalPath: 'doits/ckcxwb9jq004v01pgfdq57aj9.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwb9jq004v01pgfdq57aj9.mp3',
                  editedName: 'tambourine.mp3',
                  formattedSize: '17.94 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacxz004u01pg767uaktt.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacxy004t01pgcqrx17ga.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwb9jq004v01pgfdq57aj9.mp3',
                spriteLocalUri: 'ckcxwacxz004u01pg767uaktt.png',
                pressedSpriteLocalUri: 'ckcxwacxy004t01pgcqrx17ga.png',
                audioLocalUri: 'ckcxwb9jq004v01pgfdq57aj9.mp3',
              },
            },
            {
              background: {
                color: '#9114ef',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacx4004o01pg21ki02q8',
                  awsInternalPath: 'doits/ckcxwacx4004o01pg21ki02q8.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacx4004o01pg21ki02q8.png',
                  editedName: 'conga_up.png',
                  formattedSize: '27.16 kB',
                  formattedCreated: 'a minute ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacwy004n01pg3pxaavel',
                  awsInternalPath: 'doits/ckcxwacwy004n01pg3pxaavel.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacwy004n01pg3pxaavel.png',
                  editedName: 'conga_down.png',
                  formattedSize: '30.16 kB',
                  formattedCreated: 'a minute ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwccad004w01pgdy8hcrpj',
                  awsInternalPath: 'doits/ckcxwccad004w01pgdy8hcrpj.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwccad004w01pgdy8hcrpj.mp3',
                  editedName: 'conga.mp3',
                  formattedSize: '7.46 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacx4004o01pg21ki02q8.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacwy004n01pg3pxaavel.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwccad004w01pgdy8hcrpj.mp3',
                spriteLocalUri: 'ckcxwacx4004o01pg21ki02q8.png',
                pressedSpriteLocalUri: 'ckcxwacwy004n01pg3pxaavel.png',
                audioLocalUri: 'ckcxwccad004w01pgdy8hcrpj.mp3',
              },
            },
            {
              background: {
                color: '#48ba8f',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacxr004q01pgg3ok5mxr',
                  awsInternalPath: 'doits/ckcxwacxr004q01pgg3ok5mxr.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxr004q01pgg3ok5mxr.png',
                  editedName: 'guiro_up.png',
                  formattedSize: '39.55 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacx4004p01pg6ptj635r',
                  awsInternalPath: 'doits/ckcxwacx4004p01pg6ptj635r.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacx4004p01pg6ptj635r.png',
                  editedName: 'guiro_down.png',
                  formattedSize: '28.16 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwccad004x01pg67ijg90k',
                  awsInternalPath: 'doits/ckcxwccad004x01pg67ijg90k.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwccad004x01pg67ijg90k.mp3',
                  editedName: 'guiro.mp3',
                  formattedSize: '12.32 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacxr004q01pgg3ok5mxr.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacx4004p01pg6ptj635r.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwccad004x01pg67ijg90k.mp3',
                spriteLocalUri: 'ckcxwacxr004q01pgg3ok5mxr.png',
                pressedSpriteLocalUri: 'ckcxwacx4004p01pg6ptj635r.png',
                audioLocalUri: 'ckcxwccad004x01pg67ijg90k.mp3',
              },
            },
            {
              background: {
                color: '#43c2cc',
              },
              button: {
                spriteFile: {
                  fileId: 'ckcxwacxy004s01pg4wlxd6z0',
                  awsInternalPath: 'doits/ckcxwacxy004s01pg4wlxd6z0.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxy004s01pg4wlxd6z0.png',
                  editedName: 'maracas_up.png',
                  formattedSize: '30.46 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckcxwacxr004r01pg2nq87ath',
                  awsInternalPath: 'doits/ckcxwacxr004r01pg2nq87ath.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwacxr004r01pg2nq87ath.png',
                  editedName: 'maracas_down.png',
                  formattedSize: '16.07 kB',
                  formattedCreated: '2 minutes ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckcxwccaj004y01pge6bsafx3',
                  awsInternalPath: 'doits/ckcxwccaj004y01pge6bsafx3.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckcxwccaj004y01pge6bsafx3.mp3',
                  editedName: 'maracas.mp3',
                  formattedSize: '3.44 kB',
                  formattedCreated: 'a minute ago',
                  uploaderUserId: 'cka2r4mp400001lrud7bs61sh',
                  uploaderName: 'Toby Mellor',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 340,
                  y: 340,
                },
                position: {
                  x: -10,
                  y: -40,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckcxwacxy004s01pg4wlxd6z0.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckcxwacxr004r01pg2nq87ath.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckcxwccaj004y01pge6bsafx3.mp3',
                spriteLocalUri: 'ckcxwacxy004s01pg4wlxd6z0.png',
                pressedSpriteLocalUri: 'ckcxwacxr004r01pg2nq87ath.png',
                audioLocalUri: 'ckcxwccaj004y01pge6bsafx3.mp3',
              },
            },
          ],
          dynamicTemplateId: 'soundboard-it-template-4',
          itemId: 'ckd4pvxi1002n1ipga1upet5j',
        },
      },
      {
        itemId: 'ckd4q0b6t000d2c6ehbof90di',
        name: 'Robot Balloon New',
        baseTemplateId: 'sequence-it-template',
        dynamicTemplateId: 'sequence-it-template',
        startSecs: 126,
        finishSecs: 158,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4ooavf00201ipgd5my80wx',
              awsInternalPath: 'doits/ckd4ooavf00201ipgd5my80wx.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ooavf00201ipgd5my80wx.png',
              editedName: 'RobotBackground.png',
              formattedSize: '742.02 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4ooavf00201ipgd5my80wx.png',
            spriteLocalUri: 'ckd4ooavf00201ipgd5my80wx.png',
          },
          sequence: [
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4nwnla001h1ipg3dkn7mmb',
                awsInternalPath: 'doits/ckd4nwnla001h1ipg3dkn7mmb.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4nwnla001h1ipg3dkn7mmb.png',
                editedName: 'SequenceIt_BalloonPop_1.png',
                formattedSize: '20.00 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4o3erc001t1ipg3chda0t5',
                awsInternalPath: 'doits/ckd4o3erc001t1ipg3chda0t5.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o3erc001t1ipg3chda0t5.mp3',
                editedName: 'balloon1.mp3',
                formattedSize: '6.36 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4nwnla001h1ipg3dkn7mmb.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4o3erc001t1ipg3chda0t5.mp3',
              spriteLocalUri: 'ckd4nwnla001h1ipg3dkn7mmb.png',
              audioLocalUri: 'ckd4o3erc001t1ipg3chda0t5.mp3',
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4nx3lv001i1ipg26w16irz',
                awsInternalPath: 'doits/ckd4nx3lv001i1ipg26w16irz.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4nx3lv001i1ipg26w16irz.png',
                editedName: 'SequenceIt_BalloonPop_2.png',
                formattedSize: '15.34 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4o3lhe001u1ipg0e9xey5h',
                awsInternalPath: 'doits/ckd4o3lhe001u1ipg0e9xey5h.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o3lhe001u1ipg0e9xey5h.mp3',
                editedName: 'balloon2.mp3',
                formattedSize: '5.21 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4nx3lv001i1ipg26w16irz.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4o3lhe001u1ipg0e9xey5h.mp3',
              spriteLocalUri: 'ckd4nx3lv001i1ipg26w16irz.png',
              audioLocalUri: 'ckd4o3lhe001u1ipg0e9xey5h.mp3',
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4nxd35001j1ipg2903c22p',
                awsInternalPath: 'doits/ckd4nxd35001j1ipg2903c22p.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4nxd35001j1ipg2903c22p.png',
                editedName: 'SequenceIt_BalloonPop_3.png',
                formattedSize: '14.33 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4o3q4j001v1ipgb1pk8l7r',
                awsInternalPath: 'doits/ckd4o3q4j001v1ipgb1pk8l7r.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o3q4j001v1ipgb1pk8l7r.mp3',
                editedName: 'balloon3.mp3',
                formattedSize: '5.06 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4nxd35001j1ipg2903c22p.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4o3q4j001v1ipgb1pk8l7r.mp3',
              spriteLocalUri: 'ckd4nxd35001j1ipg2903c22p.png',
              audioLocalUri: 'ckd4o3q4j001v1ipgb1pk8l7r.mp3',
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4nxk4m001k1ipg9lpkbw3x',
                awsInternalPath: 'doits/ckd4nxk4m001k1ipg9lpkbw3x.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4nxk4m001k1ipg9lpkbw3x.png',
                editedName: 'SequenceIt_BalloonPop_4.png',
                formattedSize: '12.08 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4o3vbd001w1ipg49qc57ih',
                awsInternalPath: 'doits/ckd4o3vbd001w1ipg49qc57ih.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o3vbd001w1ipg49qc57ih.mp3',
                editedName: 'balloon4.mp3',
                formattedSize: '5.53 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4nxk4m001k1ipg9lpkbw3x.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4o3vbd001w1ipg49qc57ih.mp3',
              spriteLocalUri: 'ckd4nxk4m001k1ipg9lpkbw3x.png',
              audioLocalUri: 'ckd4o3vbd001w1ipg49qc57ih.mp3',
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: -50,
              },
              spriteFile: {
                fileId: 'ckd4o01gj001q1ipg4p6n7fff',
                awsInternalPath: 'doits/ckd4o01gj001q1ipg4p6n7fff.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o01gj001q1ipg4p6n7fff.png',
                editedName: 'SequenceIt_BalloonPop_5.png',
                formattedSize: '20.82 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4o3zlv001x1ipg3pbgatj8',
                awsInternalPath: 'doits/ckd4o3zlv001x1ipg3pbgatj8.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o3zlv001x1ipg3pbgatj8.mp3',
                editedName: 'balloon5.mp3',
                formattedSize: '5.84 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4o01gj001q1ipg4p6n7fff.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4o3zlv001x1ipg3pbgatj8.mp3',
              spriteLocalUri: 'ckd4o01gj001q1ipg4p6n7fff.png',
              audioLocalUri: 'ckd4o3zlv001x1ipg3pbgatj8.mp3',
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: -100,
              },
              spriteFile: {
                fileId: 'ckd4o05wr001r1ipg6q4b3xsf',
                awsInternalPath: 'doits/ckd4o05wr001r1ipg6q4b3xsf.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o05wr001r1ipg6q4b3xsf.png',
                editedName: 'SequenceIt_BalloonPop_6.png',
                formattedSize: '17.90 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4o4y2s001y1ipg40sz942h',
                awsInternalPath: 'doits/ckd4o4y2s001y1ipg40sz942h.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o4y2s001y1ipg40sz942h.mp3',
                editedName: 'balloon6.mp3',
                formattedSize: '6.57 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4o05wr001r1ipg6q4b3xsf.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4o4y2s001y1ipg40sz942h.mp3',
              spriteLocalUri: 'ckd4o05wr001r1ipg6q4b3xsf.png',
              audioLocalUri: 'ckd4o4y2s001y1ipg40sz942h.mp3',
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: -150,
              },
              spriteFile: {
                fileId: 'ckd4o0b19001s1ipg95ur547w',
                awsInternalPath: 'doits/ckd4o0b19001s1ipg95ur547w.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o0b19001s1ipg95ur547w.png',
                editedName: 'SequenceIt_BalloonPop_7.png',
                formattedSize: '16.91 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4o54op001z1ipg7kgofg7m',
                awsInternalPath: 'doits/ckd4o54op001z1ipg7kgofg7m.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4o54op001z1ipg7kgofg7m.mp3',
                editedName: 'balloon7.mp3',
                formattedSize: '16.32 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4o0b19001s1ipg95ur547w.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4o54op001z1ipg7kgofg7m.mp3',
              spriteLocalUri: 'ckd4o0b19001s1ipg95ur547w.png',
              audioLocalUri: 'ckd4o54op001z1ipg7kgofg7m.mp3',
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: -200,
              },
              spriteFile: {
                fileId: 'ckd4nyvyb001p1ipggwxe6cbo',
                awsInternalPath: 'doits/ckd4nyvyb001p1ipggwxe6cbo.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4nyvyb001p1ipggwxe6cbo.png',
                editedName: 'SequenceIt_BalloonPop_8.png',
                formattedSize: '5.38 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: null,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4nyvyb001p1ipggwxe6cbo.png',
              audioUri: '',
              spriteLocalUri: 'ckd4nyvyb001p1ipggwxe6cbo.png',
              audioLocalUri: null,
            },
            {
              size: {
                x: 700,
                y: 700,
              },
              position: {
                x: 0,
                y: -200,
              },
              spriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '19 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: null,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              audioUri: '',
              spriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
              audioLocalUri: null,
            },
          ],
          itemId: 'ckd4q0b6t000d2c6ehbof90di',
        },
      },
      {
        itemId: 'ckd4q4yp100022c6a9g59bhiz',
        name: 'Robot Sound Board New',
        baseTemplateId: 'soundboard-it-template',
        dynamicTemplateId: 'soundboard-it-template-4',
        startSecs: 215,
        finishSecs: 263,
        params: {
          panels: [
            {
              background: {
                color: '#9013fe',
              },
              button: {
                spriteFile: {
                  fileId: 'ckd4os8l200211ipg0sho8fok',
                  awsInternalPath: 'doits/ckd4os8l200211ipg0sho8fok.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4os8l200211ipg0sho8fok.png',
                  editedName: 'robot-up.png',
                  formattedSize: '20.46 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckd4ose1d00221ipg9tvddxny',
                  awsInternalPath: 'doits/ckd4ose1d00221ipg9tvddxny.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4ose1d00221ipg9tvddxny.png',
                  editedName: 'robot-dn.png',
                  formattedSize: '18.68 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckd4ozjun00291ipgah5rgstb',
                  awsInternalPath: 'doits/ckd4ozjun00291ipgah5rgstb.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4ozjun00291ipgah5rgstb.mp3',
                  editedName: 'happy-bday-robot.mp3',
                  formattedSize: '37.11 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckd4os8l200211ipg0sho8fok.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckd4ose1d00221ipg9tvddxny.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckd4ozjun00291ipgah5rgstb.mp3',
                spriteLocalUri: 'ckd4os8l200211ipg0sho8fok.png',
                pressedSpriteLocalUri: 'ckd4ose1d00221ipg9tvddxny.png',
                audioLocalUri: 'ckd4ozjun00291ipgah5rgstb.mp3',
              },
            },
            {
              background: {
                color: '#d0021b',
                spriteFile: {
                  fileId: 'ckd4pja0l002h1ipg8fk2glo8',
                  awsInternalPath: 'doits/ckd4pja0l002h1ipg8fk2glo8.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4pja0l002h1ipg8fk2glo8.png',
                  editedName: 'Screen Shot 2020-07-27 at 12.09.51 PM.png',
                  formattedSize: '4.97 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'image/png',
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckd4pja0l002h1ipg8fk2glo8.png',
                spriteLocalUri: 'ckd4pja0l002h1ipg8fk2glo8.png',
              },
              button: {
                spriteFile: {
                  fileId: 'ckdk7xsep002l1tnu8boh9cns',
                  awsInternalPath: 'doits/ckdk7xsep002l1tnu8boh9cns.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckdk7xsep002l1tnu8boh9cns.png',
                  editedName: 'popper.png',
                  formattedSize: '45.00 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckcc89a59002f01o08s8x9sbz',
                  uploaderName: 'Jared Silver',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckdk7xvpq002m1tnuhnmraaym',
                  awsInternalPath: 'doits/ckdk7xvpq002m1tnuhnmraaym.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckdk7xvpq002m1tnuhnmraaym.png',
                  editedName: 'popper popped.png',
                  formattedSize: '28.85 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckcc89a59002f01o08s8x9sbz',
                  uploaderName: 'Jared Silver',
                  mimeType: 'image/png',
                },
                audioFile: null,
                numMaxPlays: 0,
                size: {
                  x: 250,
                  y: 250,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckdk7xsep002l1tnu8boh9cns.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckdk7xvpq002m1tnuhnmraaym.png',
                audioUri: '',
                spriteLocalUri: null,
                pressedSpriteLocalUri: null,
                audioLocalUri: null,
              },
            },
            {
              background: {
                color: '#770f71',
              },
              button: {
                spriteFile: {
                  fileId: 'ckd4owh6900231ipg3xfo74xx',
                  awsInternalPath: 'doits/ckd4owh6900231ipg3xfo74xx.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4owh6900231ipg3xfo74xx.png',
                  editedName: 'cheer-up.png',
                  formattedSize: '51.30 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckd4ownzs00241ipg249m47f2',
                  awsInternalPath: 'doits/ckd4ownzs00241ipg249m47f2.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4ownzs00241ipg249m47f2.png',
                  editedName: 'cheer-down.png',
                  formattedSize: '38.10 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckd4oyz9c00281ipg3u0e2xs8',
                  awsInternalPath: 'doits/ckd4oyz9c00281ipg3u0e2xs8.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4oyz9c00281ipg3u0e2xs8.mp3',
                  editedName: 'cheers.mp3',
                  formattedSize: '50.54 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 250,
                  y: 250,
                },
                position: {
                  x: 0,
                  y: 40,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckd4owh6900231ipg3xfo74xx.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckd4ownzs00241ipg249m47f2.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckd4oyz9c00281ipg3u0e2xs8.mp3',
                spriteLocalUri: 'ckd4owh6900231ipg3xfo74xx.png',
                pressedSpriteLocalUri: 'ckd4ownzs00241ipg249m47f2.png',
                audioLocalUri: 'ckd4oyz9c00281ipg3u0e2xs8.mp3',
              },
            },
            {
              background: {
                color: '#50e3c2',
              },
              button: {
                spriteFile: {
                  fileId: 'ckd4ox7zh00251ipg5gpi3t1z',
                  awsInternalPath: 'doits/ckd4ox7zh00251ipg5gpi3t1z.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4ox7zh00251ipg5gpi3t1z.png',
                  editedName: 'drum-up.png',
                  formattedSize: '33.23 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'image/png',
                },
                pressedSpriteFile: {
                  fileId: 'ckd4oxfck00261ipg44s8ect5',
                  awsInternalPath: 'doits/ckd4oxfck00261ipg44s8ect5.png',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4oxfck00261ipg44s8ect5.png',
                  editedName: 'drum-down.png',
                  formattedSize: '27.76 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'image/png',
                },
                audioFile: {
                  fileId: 'ckd4oykj400271ipggc8u81mn',
                  awsInternalPath: 'doits/ckd4oykj400271ipggc8u81mn.mp3',
                  awsPublicUrl:
                    'https://assets.hellosaurus.com/doits/ckd4oykj400271ipggc8u81mn.mp3',
                  editedName: 'drums-roll.mp3',
                  formattedSize: '59.96 kB',
                  formattedCreated: 'a few seconds ago',
                  uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                  uploaderName: 'Jonathan Shulman',
                  mimeType: 'audio/mpeg',
                },
                numMaxPlays: 0,
                size: {
                  x: 300,
                  y: 300,
                },
                position: {
                  x: 0,
                  y: 20,
                },
                spriteUri: 'https://assets.hellosaurus.com/doits/ckd4ox7zh00251ipg5gpi3t1z.png',
                pressedSpriteUri:
                  'https://assets.hellosaurus.com/doits/ckd4oxfck00261ipg44s8ect5.png',
                audioUri: 'https://assets.hellosaurus.com/doits/ckd4oykj400271ipggc8u81mn.mp3',
                spriteLocalUri: 'ckd4ox7zh00251ipg5gpi3t1z.png',
                pressedSpriteLocalUri: 'ckd4oxfck00261ipg44s8ect5.png',
                audioLocalUri: 'ckd4oykj400271ipggc8u81mn.mp3',
              },
            },
          ],
          dynamicTemplateId: 'soundboard-it-template-4',
          itemId: 'ckd4q4yp100022c6a9g59bhiz',
        },
      },
      {
        itemId: 'ckd4qhmex00072c6htynbf76g',
        name: 'Invisibilifier',
        baseTemplateId: 'find-it-template',
        dynamicTemplateId: 'find-it-template',
        startSecs: 330,
        finishSecs: 369,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4qiasf00311ipgfb7o3iuy',
              awsInternalPath: 'doits/ckd4qiasf00311ipgfb7o3iuy.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qiasf00311ipgfb7o3iuy.png',
              editedName: 'visibilifeir-new-bkg.png',
              formattedSize: '209.43 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4qiasf00311ipgfb7o3iuy.png',
            spriteLocalUri: 'ckd4qiasf00311ipgfb7o3iuy.png',
          },
          completeSound: {
            file: {
              fileId: 'ckculkp18001o01oye8062f29',
              awsInternalPath: 'doits/ckculkp18001o01oye8062f29.mp3',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckculkp18001o01oye8062f29.mp3',
              editedName: 'success_big_generic.mp3',
              formattedSize: '66.33 kB',
              formattedCreated: '7 days ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'audio/mpeg',
            },
            uri: 'https://assets.hellosaurus.com/doits/ckculkp18001o01oye8062f29.mp3',
            localUri: 'ckculkp18001o01oye8062f29.mp3',
          },
          findables: [
            {
              position: {
                x: -179,
                y: -285,
              },
              size: {
                x: 85,
                y: 120,
              },
              tweenTo: {
                x: -250,
                y: 350,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd4qj2br00321ipgg5bc83xt',
                awsInternalPath: 'doits/ckd4qj2br00321ipgg5bc83xt.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qj2br00321ipgg5bc83xt.png',
                editedName: 'arm-corner.png',
                formattedSize: '7.72 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4qslk600371ipg5ef2311k',
                awsInternalPath: 'doits/ckd4qslk600371ipg5ef2311k.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qslk600371ipg5ef2311k.mp3',
                editedName: 'right-arm.mp3',
                formattedSize: '11.16 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4qj2br00321ipgg5bc83xt.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4qslk600371ipg5ef2311k.mp3',
              swapToSpriteFile: {
                fileId: 'ckcxoeqit002r01pg62ryb8un',
                awsInternalPath: 'doits/ckcxoeqit002r01pg62ryb8un.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
                editedName: 'IMG_2783.PNG',
                formattedSize: '2.18 kB',
                formattedCreated: '5 days ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
              spriteLocalUri: 'ckd4qj2br00321ipgg5bc83xt.png',
              audioLocalUri: 'ckd4qslk600371ipg5ef2311k.mp3',
              swapToSpriteLocalUri: 'ckcxoeqit002r01pg62ryb8un.png',
            },
            {
              position: {
                x: 26,
                y: -168,
              },
              size: {
                x: 55,
                y: 140,
              },
              tweenTo: {
                x: -250,
                y: 350,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd4qlocm00331ipgazh7cc4j',
                awsInternalPath: 'doits/ckd4qlocm00331ipgazh7cc4j.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qlocm00331ipgazh7cc4j.png',
                editedName: 'arm-hang.png',
                formattedSize: '8.92 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4qsru000381ipg9skg6e3p',
                awsInternalPath: 'doits/ckd4qsru000381ipg9skg6e3p.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qsru000381ipg9skg6e3p.mp3',
                editedName: 'left-arm.mp3',
                formattedSize: '14.79 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4qlocm00331ipgazh7cc4j.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4qsru000381ipg9skg6e3p.mp3',
              swapToSpriteFile: {
                fileId: 'ckcxoeqit002r01pg62ryb8un',
                awsInternalPath: 'doits/ckcxoeqit002r01pg62ryb8un.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
                editedName: 'IMG_2783.PNG',
                formattedSize: '2.18 kB',
                formattedCreated: '5 days ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
              spriteLocalUri: 'ckd4qlocm00331ipgazh7cc4j.png',
              audioLocalUri: 'ckd4qsru000381ipg9skg6e3p.mp3',
              swapToSpriteLocalUri: 'ckcxoeqit002r01pg62ryb8un.png',
            },
            {
              position: {
                x: -70,
                y: 200,
              },
              size: {
                x: 170,
                y: 170,
              },
              tweenTo: {
                x: -250,
                y: 350,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd4qoge400341ipga95qc6ti',
                awsInternalPath: 'doits/ckd4qoge400341ipga95qc6ti.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qoge400341ipga95qc6ti.png',
                editedName: 'body.png',
                formattedSize: '36.29 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4qsydw00391ipgdbhqe5ms',
                awsInternalPath: 'doits/ckd4qsydw00391ipgdbhqe5ms.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qsydw00391ipgdbhqe5ms.mp3',
                editedName: 'robot-body.mp3',
                formattedSize: '23.93 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4qoge400341ipga95qc6ti.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4qsydw00391ipgdbhqe5ms.mp3',
              swapToSpriteFile: {
                fileId: 'ckcxoeqit002r01pg62ryb8un',
                awsInternalPath: 'doits/ckcxoeqit002r01pg62ryb8un.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
                editedName: 'IMG_2783.PNG',
                formattedSize: '2.18 kB',
                formattedCreated: '5 days ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
              spriteLocalUri: 'ckd4qoge400341ipga95qc6ti.png',
              audioLocalUri: 'ckd4qsydw00391ipgdbhqe5ms.mp3',
              swapToSpriteLocalUri: 'ckcxoeqit002r01pg62ryb8un.png',
            },
            {
              position: {
                x: 80,
                y: 115,
              },
              size: {
                x: 130,
                y: 130,
              },
              tweenTo: {
                x: -250,
                y: 350,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd4qqi2100351ipg436gdugv',
                awsInternalPath: 'doits/ckd4qqi2100351ipg436gdugv.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qqi2100351ipg436gdugv.png',
                editedName: 'head.png',
                formattedSize: '16.24 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4qt3q5003a1ipg7gug16jp',
                awsInternalPath: 'doits/ckd4qt3q5003a1ipg7gug16jp.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4qt3q5003a1ipg7gug16jp.mp3',
                editedName: 'robot-head.mp3',
                formattedSize: '15.81 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4qqi2100351ipg436gdugv.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4qt3q5003a1ipg7gug16jp.mp3',
              swapToSpriteFile: {
                fileId: 'ckcxoeqit002r01pg62ryb8un',
                awsInternalPath: 'doits/ckcxoeqit002r01pg62ryb8un.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
                editedName: 'IMG_2783.PNG',
                formattedSize: '2.18 kB',
                formattedCreated: '5 days ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckcxoeqit002r01pg62ryb8un.png',
              spriteLocalUri: 'ckd4qqi2100351ipg436gdugv.png',
              audioLocalUri: 'ckd4qt3q5003a1ipg7gug16jp.mp3',
              swapToSpriteLocalUri: 'ckcxoeqit002r01pg62ryb8un.png',
            },
          ],
          itemId: 'ckd4qhmex00072c6htynbf76g',
        },
      },
      {
        itemId: 'ckd4r701w003v2c6hpcb6uxe3',
        name: 'Robot activate',
        baseTemplateId: 'sequence-it-template',
        dynamicTemplateId: 'sequence-it-template',
        startSecs: 379,
        finishSecs: 432,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4r7jed003b1ipg86x4brn7',
              awsInternalPath: 'doits/ckd4r7jed003b1ipg86x4brn7.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4r7jed003b1ipg86x4brn7.png',
              editedName: 'visi-blue-bkg.png',
              formattedSize: '5.32 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4r7jed003b1ipg86x4brn7.png',
            spriteLocalUri: 'ckd4r7jed003b1ipg86x4brn7.png',
          },
          sequence: [
            {
              size: {
                x: 600,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4r7oat003c1ipgg3hr5qg2',
                awsInternalPath: 'doits/ckd4r7oat003c1ipgg3hr5qg2.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4r7oat003c1ipgg3hr5qg2.png',
                editedName: 'yellow-robot1.png',
                formattedSize: '36.45 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4rbib4003h1ipg5kb8h3zc',
                awsInternalPath: 'doits/ckd4rbib4003h1ipg5kb8h3zc.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rbib4003h1ipg5kb8h3zc.mp3',
                editedName: 'actuator.mp3',
                formattedSize: '14.66 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4r7oat003c1ipgg3hr5qg2.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4rbib4003h1ipg5kb8h3zc.mp3',
              spriteLocalUri: 'ckd4r7oat003c1ipgg3hr5qg2.png',
              audioLocalUri: 'ckd4rbib4003h1ipg5kb8h3zc.mp3',
            },
            {
              size: {
                x: 600,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4r8wf5003d1ipg25w56id0',
                awsInternalPath: 'doits/ckd4r8wf5003d1ipg25w56id0.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4r8wf5003d1ipg25w56id0.png',
                editedName: 'yellow-robot2.png',
                formattedSize: '65.05 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4rbib4003h1ipg5kb8h3zc',
                awsInternalPath: 'doits/ckd4rbib4003h1ipg5kb8h3zc.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rbib4003h1ipg5kb8h3zc.mp3',
                editedName: 'actuator.mp3',
                formattedSize: '14.66 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4r8wf5003d1ipg25w56id0.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4rbib4003h1ipg5kb8h3zc.mp3',
              spriteLocalUri: 'ckd4r8wf5003d1ipg25w56id0.png',
              audioLocalUri: 'ckd4rbib4003h1ipg5kb8h3zc.mp3',
            },
            {
              size: {
                x: 600,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4r91ue003e1ipg4zdvalcu',
                awsInternalPath: 'doits/ckd4r91ue003e1ipg4zdvalcu.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4r91ue003e1ipg4zdvalcu.png',
                editedName: 'yellow-robot3.png',
                formattedSize: '65.09 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4rbnx3003i1ipgc5klc8lo',
                awsInternalPath: 'doits/ckd4rbnx3003i1ipgc5klc8lo.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rbnx3003i1ipgc5klc8lo.mp3',
                editedName: 'gas-leak.mp3',
                formattedSize: '20.41 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4r91ue003e1ipg4zdvalcu.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4rbnx3003i1ipgc5klc8lo.mp3',
              spriteLocalUri: 'ckd4r91ue003e1ipg4zdvalcu.png',
              audioLocalUri: 'ckd4rbnx3003i1ipgc5klc8lo.mp3',
            },
            {
              size: {
                x: 600,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4r9hgl003f1ipg7dj9f2np',
                awsInternalPath: 'doits/ckd4r9hgl003f1ipg7dj9f2np.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4r9hgl003f1ipg7dj9f2np.png',
                editedName: 'yellow-robot4.png',
                formattedSize: '65.13 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4rbwpy003k1ipg4huse6cf',
                awsInternalPath: 'doits/ckd4rbwpy003k1ipg4huse6cf.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rbwpy003k1ipg4huse6cf.mp3',
                editedName: 'robot-ooops.mp3',
                formattedSize: '38.66 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4r9hgl003f1ipg7dj9f2np.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4rbwpy003k1ipg4huse6cf.mp3',
              spriteLocalUri: 'ckd4r9hgl003f1ipg7dj9f2np.png',
              audioLocalUri: 'ckd4rbwpy003k1ipg4huse6cf.mp3',
            },
            {
              size: {
                x: 600,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4r9tc3003g1ipg5phxdawf',
                awsInternalPath: 'doits/ckd4r9tc3003g1ipg5phxdawf.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4r9tc3003g1ipg5phxdawf.png',
                editedName: 'yellow-robot4b.png',
                formattedSize: '79.72 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4rc0sr003l1ipgfgp235of',
                awsInternalPath: 'doits/ckd4rc0sr003l1ipgfgp235of.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rc0sr003l1ipgfgp235of.mp3',
                editedName: 'robot-pip-pip.mp3',
                formattedSize: '64.78 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4r9tc3003g1ipg5phxdawf.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4rc0sr003l1ipgfgp235of.mp3',
              spriteLocalUri: 'ckd4r9tc3003g1ipg5phxdawf.png',
              audioLocalUri: 'ckd4rc0sr003l1ipgfgp235of.mp3',
            },
          ],
          itemId: 'ckd4r701w003v2c6hpcb6uxe3',
        },
      },
      {
        itemId: 'ckd4rku2i00022c6esi4hule2',
        name: 'Stamp the robots',
        baseTemplateId: 'stamp-it-template',
        dynamicTemplateId: 'stamp-it-template',
        startSecs: 446,
        finishSecs: 476,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4rgw17003m1ipgetth9vv3',
              awsInternalPath: 'doits/ckd4rgw17003m1ipgetth9vv3.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rgw17003m1ipgetth9vv3.png',
              editedName: 'robot-town.png',
              formattedSize: '113.55 kB',
              formattedCreated: '3 minutes ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rgw17003m1ipgetth9vv3.png',
            spriteLocalUri: 'ckd4rgw17003m1ipgetth9vv3.png',
          },
          completeSound: {
            file: null,
            uri: '',
            localUri: null,
          },
          maxNumStamps: 35,
          stamps: [
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rk1zl003q1ipg8r54go0b',
                awsInternalPath: 'doits/ckd4rk1zl003q1ipg8r54go0b.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rk1zl003q1ipg8r54go0b.png',
                editedName: 'robot-town_0002_Layer-3.png',
                formattedSize: '6.28 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rk1zl003q1ipg8r54go0b.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rk1zl003q1ipg8r54go0b.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rimfd003p1ipgh6uyeyu0',
                awsInternalPath: 'doits/ckd4rimfd003p1ipgh6uyeyu0.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rimfd003p1ipgh6uyeyu0.png',
                editedName: 'robot-town_0001_Layer-2.png',
                formattedSize: '6.32 kB',
                formattedCreated: '3 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rimfd003p1ipgh6uyeyu0.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rimfd003p1ipgh6uyeyu0.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rhus4003n1ipg432i7y8k',
                awsInternalPath: 'doits/ckd4rhus4003n1ipg432i7y8k.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rhus4003n1ipg432i7y8k.png',
                editedName: 'robot-town_0000_Layer-1.png',
                formattedSize: '6.35 kB',
                formattedCreated: '4 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rhus4003n1ipg432i7y8k.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rhus4003n1ipg432i7y8k.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rnc4m003r1ipgbju68g6g',
                awsInternalPath: 'doits/ckd4rnc4m003r1ipgbju68g6g.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rnc4m003r1ipgbju68g6g.png',
                editedName: 'robot-town_0003_Layer-4.png',
                formattedSize: '6.68 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rnc4m003r1ipgbju68g6g.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rnc4m003r1ipgbju68g6g.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rniid003s1ipg21t4djgl',
                awsInternalPath: 'doits/ckd4rniid003s1ipg21t4djgl.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rniid003s1ipg21t4djgl.png',
                editedName: 'robot-town_0004_Layer-5.png',
                formattedSize: '5.84 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rniid003s1ipg21t4djgl.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rniid003s1ipg21t4djgl.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rnpkz003t1ipge3xubali',
                awsInternalPath: 'doits/ckd4rnpkz003t1ipge3xubali.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rnpkz003t1ipge3xubali.png',
                editedName: 'robot-town_0005_Layer-6.png',
                formattedSize: '6.30 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rnpkz003t1ipge3xubali.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rnpkz003t1ipge3xubali.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rp2uu003u1ipg1xex7yzm',
                awsInternalPath: 'doits/ckd4rp2uu003u1ipg1xex7yzm.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rp2uu003u1ipg1xex7yzm.png',
                editedName: 'robot-town_0006_Layer-7.png',
                formattedSize: '6.96 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rp2uu003u1ipg1xex7yzm.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rp2uu003u1ipg1xex7yzm.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rpc5g003v1ipgcq5oaefm',
                awsInternalPath: 'doits/ckd4rpc5g003v1ipgcq5oaefm.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rpc5g003v1ipgcq5oaefm.png',
                editedName: 'robot-town_0007_Layer-8.png',
                formattedSize: '5.66 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rpc5g003v1ipgcq5oaefm.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rpc5g003v1ipgcq5oaefm.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rpkec003w1ipg4iad2m31',
                awsInternalPath: 'doits/ckd4rpkec003w1ipg4iad2m31.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rpkec003w1ipg4iad2m31.png',
                editedName: 'robot-town_0008_Layer-9.png',
                formattedSize: '6.34 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rpkec003w1ipg4iad2m31.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rpkec003w1ipg4iad2m31.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rpqzc003x1ipgfvr05z1t',
                awsInternalPath: 'doits/ckd4rpqzc003x1ipgfvr05z1t.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rpqzc003x1ipgfvr05z1t.png',
                editedName: 'robot-town_0009_Layer-10.png',
                formattedSize: '6.21 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rpqzc003x1ipgfvr05z1t.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rpqzc003x1ipgfvr05z1t.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rpyet003y1ipg9ny13lwq',
                awsInternalPath: 'doits/ckd4rpyet003y1ipg9ny13lwq.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rpyet003y1ipg9ny13lwq.png',
                editedName: 'robot-town_0010_Layer-11.png',
                formattedSize: '7.23 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rpyet003y1ipg9ny13lwq.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rpyet003y1ipg9ny13lwq.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rq52i003z1ipgdgyw9uuf',
                awsInternalPath: 'doits/ckd4rq52i003z1ipgdgyw9uuf.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rq52i003z1ipgdgyw9uuf.png',
                editedName: 'robot-town_0011_Layer-12.png',
                formattedSize: '6.28 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rq52i003z1ipgdgyw9uuf.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rq52i003z1ipgdgyw9uuf.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rqbop00401ipgapl33hkr',
                awsInternalPath: 'doits/ckd4rqbop00401ipgapl33hkr.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rqbop00401ipgapl33hkr.png',
                editedName: 'robot-town_0012_Layer-13.png',
                formattedSize: '6.34 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rqbop00401ipgapl33hkr.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rqbop00401ipgapl33hkr.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rqk8000411ipg56fm9tl9',
                awsInternalPath: 'doits/ckd4rqk8000411ipg56fm9tl9.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rqk8000411ipg56fm9tl9.png',
                editedName: 'robot-town_0013_Layer-14.png',
                formattedSize: '6.37 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rqk8000411ipg56fm9tl9.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rqk8000411ipg56fm9tl9.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rqrp500421ipgboi6bri9',
                awsInternalPath: 'doits/ckd4rqrp500421ipgboi6bri9.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rqrp500421ipgboi6bri9.png',
                editedName: 'robot-town_0014_Layer-15.png',
                formattedSize: '6.55 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rqrp500421ipgboi6bri9.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rqrp500421ipgboi6bri9.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rqz1c00431ipg04be69v1',
                awsInternalPath: 'doits/ckd4rqz1c00431ipg04be69v1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rqz1c00431ipg04be69v1.png',
                editedName: 'robot-town_0015_Layer-16.png',
                formattedSize: '6.83 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rqz1c00431ipg04be69v1.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rqz1c00431ipg04be69v1.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rrbl900441ipg7txrc0ac',
                awsInternalPath: 'doits/ckd4rrbl900441ipg7txrc0ac.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rrbl900441ipg7txrc0ac.png',
                editedName: 'robot-town_0017_Layer-18.png',
                formattedSize: '5.83 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rrbl900441ipg7txrc0ac.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rrbl900441ipg7txrc0ac.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rrjkl00451ipg3vc3abpx',
                awsInternalPath: 'doits/ckd4rrjkl00451ipg3vc3abpx.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rrjkl00451ipg3vc3abpx.png',
                editedName: 'robot-town_0018_Layer-19.png',
                formattedSize: '5.40 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rrjkl00451ipg3vc3abpx.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rrjkl00451ipg3vc3abpx.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rrs0i00461ipgeebuhcon',
                awsInternalPath: 'doits/ckd4rrs0i00461ipgeebuhcon.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rrs0i00461ipgeebuhcon.png',
                editedName: 'robot-town_0019_Layer-20.png',
                formattedSize: '5.45 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rrs0i00461ipgeebuhcon.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rrs0i00461ipgeebuhcon.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rs4zb00471ipgg6it08k0',
                awsInternalPath: 'doits/ckd4rs4zb00471ipgg6it08k0.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rs4zb00471ipgg6it08k0.png',
                editedName: 'robot-town_0020_Layer-21.png',
                formattedSize: '5.47 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rs4zb00471ipgg6it08k0.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rs4zb00471ipgg6it08k0.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rsd8000481ipgd64gfgng',
                awsInternalPath: 'doits/ckd4rsd8000481ipgd64gfgng.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rsd8000481ipgd64gfgng.png',
                editedName: 'robot-town_0021_Layer-22.png',
                formattedSize: '5.42 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rsd8000481ipgd64gfgng.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rsd8000481ipgd64gfgng.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rsn0600491ipg6563gi38',
                awsInternalPath: 'doits/ckd4rsn0600491ipg6563gi38.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rsn0600491ipg6563gi38.png',
                editedName: 'robot-town_0022_Layer-23.png',
                formattedSize: '5.90 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rsn0600491ipg6563gi38.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rsn0600491ipg6563gi38.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rsutl004a1ipghdb0h985',
                awsInternalPath: 'doits/ckd4rsutl004a1ipghdb0h985.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rsutl004a1ipghdb0h985.png',
                editedName: 'robot-town_0023_Layer-24.png',
                formattedSize: '5.85 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rsutl004a1ipghdb0h985.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rsutl004a1ipghdb0h985.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rt7pp004b1ipg4wqocfys',
                awsInternalPath: 'doits/ckd4rt7pp004b1ipg4wqocfys.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rt7pp004b1ipg4wqocfys.png',
                editedName: 'robot-town_0023_Layer-24.png',
                formattedSize: '5.85 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rt7pp004b1ipg4wqocfys.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rt7pp004b1ipg4wqocfys.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rtf4t004c1ipg9zbg6ff4',
                awsInternalPath: 'doits/ckd4rtf4t004c1ipg9zbg6ff4.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rtf4t004c1ipg9zbg6ff4.png',
                editedName: 'robot-town_0024_Layer-25.png',
                formattedSize: '5.86 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rtf4t004c1ipg9zbg6ff4.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rtf4t004c1ipg9zbg6ff4.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rtojr004d1ipg2m063hgj',
                awsInternalPath: 'doits/ckd4rtojr004d1ipg2m063hgj.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rtojr004d1ipg2m063hgj.png',
                editedName: 'robot-town_0025_Layer-26.png',
                formattedSize: '5.98 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rtojr004d1ipg2m063hgj.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rtojr004d1ipg2m063hgj.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rtxgy004e1ipg1jdsetzv',
                awsInternalPath: 'doits/ckd4rtxgy004e1ipg1jdsetzv.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rtxgy004e1ipg1jdsetzv.png',
                editedName: 'robot-town_0026_Layer-27.png',
                formattedSize: '5.86 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rtxgy004e1ipg1jdsetzv.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rtxgy004e1ipg1jdsetzv.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4ruq7e004g1ipgfh489sxi',
                awsInternalPath: 'doits/ckd4ruq7e004g1ipgfh489sxi.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ruq7e004g1ipgfh489sxi.png',
                editedName: 'robot-town_0028_Layer-29.png',
                formattedSize: '6.30 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4ruq7e004g1ipgfh489sxi.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4ruq7e004g1ipgfh489sxi.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4ruwcy004h1ipghj1q2p7v',
                awsInternalPath: 'doits/ckd4ruwcy004h1ipghj1q2p7v.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ruwcy004h1ipghj1q2p7v.png',
                editedName: 'robot-town_0029_Layer-30.png',
                formattedSize: '5.53 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4ruwcy004h1ipghj1q2p7v.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4ruwcy004h1ipghj1q2p7v.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rv4wx004i1ipgg0k24yfj',
                awsInternalPath: 'doits/ckd4rv4wx004i1ipgg0k24yfj.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rv4wx004i1ipgg0k24yfj.png',
                editedName: 'robot-town_0030_Layer-31.png',
                formattedSize: '5.84 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rv4wx004i1ipgg0k24yfj.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rv4wx004i1ipgg0k24yfj.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rvg4j004j1ipg7d9r6mwg',
                awsInternalPath: 'doits/ckd4rvg4j004j1ipg7d9r6mwg.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rvg4j004j1ipg7d9r6mwg.png',
                editedName: 'robot-town_0031_Layer-32.png',
                formattedSize: '5.49 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rvg4j004j1ipg7d9r6mwg.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rvg4j004j1ipg7d9r6mwg.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rvpz8004k1ipgbvgq7me3',
                awsInternalPath: 'doits/ckd4rvpz8004k1ipgbvgq7me3.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rvpz8004k1ipgbvgq7me3.png',
                editedName: 'robot-town_0032_Layer-33.png',
                formattedSize: '5.50 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rvpz8004k1ipgbvgq7me3.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rvpz8004k1ipgbvgq7me3.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rwqm3004l1ipga47ie2fe',
                awsInternalPath: 'doits/ckd4rwqm3004l1ipga47ie2fe.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rwqm3004l1ipga47ie2fe.png',
                editedName: 'robot-town_0034_Layer-35.png',
                formattedSize: '5.72 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rwqm3004l1ipga47ie2fe.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rwqm3004l1ipga47ie2fe.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rwzwb004m1ipg150i0kg9',
                awsInternalPath: 'doits/ckd4rwzwb004m1ipg150i0kg9.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rwzwb004m1ipg150i0kg9.png',
                editedName: 'robot-town_0035_Layer-36.png',
                formattedSize: '4.89 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rwzwb004m1ipg150i0kg9.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rwzwb004m1ipg150i0kg9.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4rxefa004n1ipg4fjj1ywc',
                awsInternalPath: 'doits/ckd4rxefa004n1ipg4fjj1ywc.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4rxefa004n1ipg4fjj1ywc.png',
                editedName: 'robot-town_0036_Layer-37.png',
                formattedSize: '5.58 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4rxefa004n1ipg4fjj1ywc.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4rxefa004n1ipg4fjj1ywc.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
            {
              size: {
                x: 40,
                y: 50,
              },
              spriteFile: {
                fileId: 'ckd4s3bqv004o1ipg27ci9efe',
                awsInternalPath: 'doits/ckd4s3bqv004o1ipg27ci9efe.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4s3bqv004o1ipg27ci9efe.png',
                editedName: 'robot-town_0036_Layer-37.png',
                formattedSize: '5.58 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4ri7wc003o1ipgfqt45iuk',
                awsInternalPath: 'doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
                editedName: 'pop1.mp3',
                formattedSize: '2.71 kB',
                formattedCreated: '2 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4s3bqv004o1ipg27ci9efe.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4ri7wc003o1ipgfqt45iuk.mp3',
              spriteLocalUri: 'ckd4s3bqv004o1ipg27ci9efe.png',
              audioLocalUri: 'ckd4ri7wc003o1ipgfqt45iuk.mp3',
            },
          ],
          itemId: 'ckd4rku2i00022c6esi4hule2',
        },
      },
      {
        itemId: 'ckd4sjyz1004i2c6eo6rorvbz',
        name: 'Unwrap the present',
        baseTemplateId: 'sequence-it-template',
        dynamicTemplateId: 'sequence-it-template',
        startSecs: 494,
        finishSecs: 514,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4smnn6004q1ipgbnq83f7m',
              awsInternalPath: 'doits/ckd4smnn6004q1ipgbnq83f7m.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4smnn6004q1ipgbnq83f7m.png',
              editedName: 'Screen Shot 2020-07-27 at 1.36.32 PM.png',
              formattedSize: '12.57 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4smnn6004q1ipgbnq83f7m.png',
            spriteLocalUri: 'ckd4smnn6004q1ipgbnq83f7m.png',
          },
          sequence: [
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4smv2w004r1ipgau5b2xod',
                awsInternalPath: 'doits/ckd4smv2w004r1ipgau5b2xod.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4smv2w004r1ipgau5b2xod.png',
                editedName: 'purple1.png',
                formattedSize: '146.42 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sq35z004z1ipg4flsfxmj',
                awsInternalPath: 'doits/ckd4sq35z004z1ipg4flsfxmj.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sq35z004z1ipg4flsfxmj.mp3',
                editedName: 'unwrap1.mp3',
                formattedSize: '76.77 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4smv2w004r1ipgau5b2xod.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sq35z004z1ipg4flsfxmj.mp3',
              spriteLocalUri: 'ckd4smv2w004r1ipgau5b2xod.png',
              audioLocalUri: 'ckd4sq35z004z1ipg4flsfxmj.mp3',
            },
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4snzqf004s1ipg1tzk7uu8',
                awsInternalPath: 'doits/ckd4snzqf004s1ipg1tzk7uu8.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4snzqf004s1ipg1tzk7uu8.png',
                editedName: 'purple2.png',
                formattedSize: '125.93 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sq8ar00501ipgffojfqxy',
                awsInternalPath: 'doits/ckd4sq8ar00501ipgffojfqxy.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sq8ar00501ipgffojfqxy.mp3',
                editedName: 'unwrap2.mp3',
                formattedSize: '17.95 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4snzqf004s1ipg1tzk7uu8.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sq8ar00501ipgffojfqxy.mp3',
              spriteLocalUri: 'ckd4snzqf004s1ipg1tzk7uu8.png',
              audioLocalUri: 'ckd4sq8ar00501ipgffojfqxy.mp3',
            },
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4so6ch004t1ipg3xlncpv9',
                awsInternalPath: 'doits/ckd4so6ch004t1ipg3xlncpv9.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4so6ch004t1ipg3xlncpv9.png',
                editedName: 'purple3.png',
                formattedSize: '112.28 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sqdiy00511ipgd1s57sp4',
                awsInternalPath: 'doits/ckd4sqdiy00511ipgd1s57sp4.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sqdiy00511ipgd1s57sp4.mp3',
                editedName: 'unwrap3.mp3',
                formattedSize: '29.64 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4so6ch004t1ipg3xlncpv9.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sqdiy00511ipgd1s57sp4.mp3',
              spriteLocalUri: 'ckd4so6ch004t1ipg3xlncpv9.png',
              audioLocalUri: 'ckd4sqdiy00511ipgd1s57sp4.mp3',
            },
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4soe0v004u1ipgfdrjfyb6',
                awsInternalPath: 'doits/ckd4soe0v004u1ipgfdrjfyb6.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4soe0v004u1ipgfdrjfyb6.png',
                editedName: 'purple4.png',
                formattedSize: '119.95 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sqkd500521ipg1w03dalv',
                awsInternalPath: 'doits/ckd4sqkd500521ipg1w03dalv.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sqkd500521ipg1w03dalv.mp3',
                editedName: 'unwrap4.mp3',
                formattedSize: '25.68 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4soe0v004u1ipgfdrjfyb6.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sqkd500521ipg1w03dalv.mp3',
              spriteLocalUri: 'ckd4soe0v004u1ipgfdrjfyb6.png',
              audioLocalUri: 'ckd4sqkd500521ipg1w03dalv.mp3',
            },
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4sojmx004v1ipgavqqh9l1',
                awsInternalPath: 'doits/ckd4sojmx004v1ipgavqqh9l1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sojmx004v1ipgavqqh9l1.png',
                editedName: 'purple5.png',
                formattedSize: '91.05 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sqov400531ipgg5py1cnb',
                awsInternalPath: 'doits/ckd4sqov400531ipgg5py1cnb.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sqov400531ipgg5py1cnb.mp3',
                editedName: 'unwrap5.mp3',
                formattedSize: '44.25 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4sojmx004v1ipgavqqh9l1.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sqov400531ipgg5py1cnb.mp3',
              spriteLocalUri: 'ckd4sojmx004v1ipgavqqh9l1.png',
              audioLocalUri: 'ckd4sqov400531ipgg5py1cnb.mp3',
            },
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4sopd0004w1ipg7l442tgx',
                awsInternalPath: 'doits/ckd4sopd0004w1ipg7l442tgx.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sopd0004w1ipg7l442tgx.png',
                editedName: 'purple6.png',
                formattedSize: '94.33 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sqtv200541ipg32plfclp',
                awsInternalPath: 'doits/ckd4sqtv200541ipg32plfclp.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sqtv200541ipg32plfclp.mp3',
                editedName: 'unwrap6.mp3',
                formattedSize: '27.45 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4sopd0004w1ipg7l442tgx.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sqtv200541ipg32plfclp.mp3',
              spriteLocalUri: 'ckd4sopd0004w1ipg7l442tgx.png',
              audioLocalUri: 'ckd4sqtv200541ipg32plfclp.mp3',
            },
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4sowdq004x1ipg41nvan6t',
                awsInternalPath: 'doits/ckd4sowdq004x1ipg41nvan6t.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sowdq004x1ipg41nvan6t.png',
                editedName: 'purple7.png',
                formattedSize: '69.67 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sqzl700551ipgfretgz0b',
                awsInternalPath: 'doits/ckd4sqzl700551ipgfretgz0b.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sqzl700551ipgfretgz0b.mp3',
                editedName: 'unwrap7.mp3',
                formattedSize: '25.15 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4sowdq004x1ipg41nvan6t.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sqzl700551ipgfretgz0b.mp3',
              spriteLocalUri: 'ckd4sowdq004x1ipg41nvan6t.png',
              audioLocalUri: 'ckd4sqzl700551ipgfretgz0b.mp3',
            },
            {
              size: {
                x: 500,
                y: 500,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4sp2x1004y1ipg0xma8jbt',
                awsInternalPath: 'doits/ckd4sp2x1004y1ipg0xma8jbt.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sp2x1004y1ipg0xma8jbt.png',
                editedName: 'purple8.png',
                formattedSize: '62.33 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4srbkq00561ipg3x2ggqb6',
                awsInternalPath: 'doits/ckd4srbkq00561ipg3x2ggqb6.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4srbkq00561ipg3x2ggqb6.mp3',
                editedName: 'unwrap5.mp3',
                formattedSize: '44.25 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4sp2x1004y1ipg0xma8jbt.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4srbkq00561ipg3x2ggqb6.mp3',
              spriteLocalUri: 'ckd4sp2x1004y1ipg0xma8jbt.png',
              audioLocalUri: 'ckd4srbkq00561ipg3x2ggqb6.mp3',
            },
          ],
          itemId: 'ckd4sjyz1004i2c6eo6rorvbz',
        },
      },
      {
        itemId: 'ckd4sztpn005n2c6ejhd05loz',
        name: 'New Spinner',
        baseTemplateId: 'sequence-it-template',
        dynamicTemplateId: 'sequence-it-template',
        startSecs: 593,
        finishSecs: 615,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4naw2x00151ipgbvvt5qrs',
              awsInternalPath: 'doits/ckd4naw2x00151ipgbvvt5qrs.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4naw2x00151ipgbvvt5qrs.png',
              editedName: 'Background.png',
              formattedSize: '392.58 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4naw2x00151ipgbvvt5qrs.png',
            spriteLocalUri: 'ckd4naw2x00151ipgbvvt5qrs.png',
          },
          sequence: [
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lv12t000z1ipg9gqvepxu',
                awsInternalPath: 'doits/ckd4lv12t000z1ipg9gqvepxu.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
                editedName: 'green.png',
                formattedSize: '47.35 kB',
                formattedCreated: '30 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lv12t000z1ipg9gqvepxu.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lxe1p00101ipghnmd0vg0',
                awsInternalPath: 'doits/ckd4lxe1p00101ipghnmd0vg0.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lxe1p00101ipghnmd0vg0.png',
                editedName: 'blue.png',
                formattedSize: '47.50 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lxe1p00101ipghnmd0vg0.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lxe1p00101ipghnmd0vg0.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lzkxl00111ipg9tph7fq4',
                awsInternalPath: 'doits/ckd4lzkxl00111ipg9tph7fq4.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
                editedName: 'red.png',
                formattedSize: '47.78 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lzkxl00111ipg9tph7fq4.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4m10n900121ipg96h77w5i',
                awsInternalPath: 'doits/ckd4m10n900121ipg96h77w5i.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4m10n900121ipg96h77w5i.png',
                editedName: 'yellow.png',
                formattedSize: '45.39 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4m10n900121ipg96h77w5i.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4m10n900121ipg96h77w5i.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lv12t000z1ipg9gqvepxu',
                awsInternalPath: 'doits/ckd4lv12t000z1ipg9gqvepxu.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
                editedName: 'green.png',
                formattedSize: '47.35 kB',
                formattedCreated: '9 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lv12t000z1ipg9gqvepxu.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lzkxl00111ipg9tph7fq4',
                awsInternalPath: 'doits/ckd4lzkxl00111ipg9tph7fq4.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
                editedName: 'red.png',
                formattedSize: '47.78 kB',
                formattedCreated: '5 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lzkxl00111ipg9tph7fq4.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lxe1p00101ipghnmd0vg0',
                awsInternalPath: 'doits/ckd4lxe1p00101ipghnmd0vg0.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lxe1p00101ipghnmd0vg0.png',
                editedName: 'blue.png',
                formattedSize: '47.50 kB',
                formattedCreated: '7 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lxe1p00101ipghnmd0vg0.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lxe1p00101ipghnmd0vg0.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lv12t000z1ipg9gqvepxu',
                awsInternalPath: 'doits/ckd4lv12t000z1ipg9gqvepxu.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
                editedName: 'green.png',
                formattedSize: '47.35 kB',
                formattedCreated: '9 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lv12t000z1ipg9gqvepxu.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lzkxl00111ipg9tph7fq4',
                awsInternalPath: 'doits/ckd4lzkxl00111ipg9tph7fq4.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
                editedName: 'red.png',
                formattedSize: '47.78 kB',
                formattedCreated: '6 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lzkxl00111ipg9tph7fq4.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lv12t000z1ipg9gqvepxu',
                awsInternalPath: 'doits/ckd4lv12t000z1ipg9gqvepxu.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
                editedName: 'green.png',
                formattedSize: '47.35 kB',
                formattedCreated: '10 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lv12t000z1ipg9gqvepxu.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4m10n900121ipg96h77w5i',
                awsInternalPath: 'doits/ckd4m10n900121ipg96h77w5i.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4m10n900121ipg96h77w5i.png',
                editedName: 'yellow.png',
                formattedSize: '45.39 kB',
                formattedCreated: '7 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4m10n900121ipg96h77w5i.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4m10n900121ipg96h77w5i.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lxe1p00101ipghnmd0vg0',
                awsInternalPath: 'doits/ckd4lxe1p00101ipghnmd0vg0.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lxe1p00101ipghnmd0vg0.png',
                editedName: 'blue.png',
                formattedSize: '47.50 kB',
                formattedCreated: '10 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lxe1p00101ipghnmd0vg0.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lxe1p00101ipghnmd0vg0.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lv12t000z1ipg9gqvepxu',
                awsInternalPath: 'doits/ckd4lv12t000z1ipg9gqvepxu.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
                editedName: 'green.png',
                formattedSize: '47.35 kB',
                formattedCreated: '12 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lv12t000z1ipg9gqvepxu.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lv12t000z1ipg9gqvepxu.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 630,
                y: 700,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4lzkxl00111ipg9tph7fq4',
                awsInternalPath: 'doits/ckd4lzkxl00111ipg9tph7fq4.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
                editedName: 'red.png',
                formattedSize: '47.78 kB',
                formattedCreated: '8 minutes ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4sy3ts00571ipgc8egd574',
                awsInternalPath: 'doits/ckd4sy3ts00571ipgc8egd574.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
                editedName: 'tap_generic.mp3',
                formattedSize: '3.59 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4lzkxl00111ipg9tph7fq4.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4sy3ts00571ipgc8egd574.mp3',
              spriteLocalUri: 'ckd4lzkxl00111ipg9tph7fq4.png',
              audioLocalUri: 'ckd4sy3ts00571ipgc8egd574.mp3',
            },
            {
              size: {
                x: 635,
                y: 715,
              },
              position: {
                x: 0,
                y: 0,
              },
              spriteFile: {
                fileId: 'ckd4tcjym00581ipg9t03b644',
                awsInternalPath: 'doits/ckd4tcjym00581ipg9t03b644.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tcjym00581ipg9t03b644.png',
                editedName: 'YellowFinal.png',
                formattedSize: '39.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckcxfereu000101pghfzq8svf',
                awsInternalPath: 'doits/ckcxfereu000101pghfzq8svf.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckcxfereu000101pghfzq8svf.mp3',
                editedName: 'ding.mp3',
                formattedSize: '31.49 kB',
                formattedCreated: '5 days ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tcjym00581ipg9t03b644.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckcxfereu000101pghfzq8svf.mp3',
              spriteLocalUri: 'ckd4tcjym00581ipg9t03b644.png',
              audioLocalUri: 'ckcxfereu000101pghfzq8svf.mp3',
            },
          ],
          itemId: 'ckd4sztpn005n2c6ejhd05loz',
        },
      },
      {
        itemId: 'ckd4tgilm005x2c6e6lf3k4sl',
        name: 'Knock over the cans',
        baseTemplateId: 'sequence-it-template',
        dynamicTemplateId: 'sequence-it-template',
        startSecs: 616,
        finishSecs: 652,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4u09ln005p1ipg2art4bdm',
              awsInternalPath: 'doits/ckd4u09ln005p1ipg2art4bdm.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4u09ln005p1ipg2art4bdm.png',
              editedName: 'RobotCloth.png',
              formattedSize: '1.33 MB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4u09ln005p1ipg2art4bdm.png',
            spriteLocalUri: 'ckd4u09ln005p1ipg2art4bdm.png',
          },
          sequence: [
            {
              size: {
                x: 1500,
                y: 700,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4tj2ll005b1ipged4t25kc',
                awsInternalPath: 'doits/ckd4tj2ll005b1ipged4t25kc.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tj2ll005b1ipged4t25kc.png',
                editedName: 'cans1.png',
                formattedSize: '284.20 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4twvhm005i1ipgbuqz22t8',
                awsInternalPath: 'doits/ckd4twvhm005i1ipgbuqz22t8.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4twvhm005i1ipgbuqz22t8.mp3',
                editedName: 'can1.mp3',
                formattedSize: '49.06 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tj2ll005b1ipged4t25kc.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4twvhm005i1ipgbuqz22t8.mp3',
              spriteLocalUri: 'ckd4tj2ll005b1ipged4t25kc.png',
              audioLocalUri: 'ckd4twvhm005i1ipgbuqz22t8.mp3',
            },
            {
              size: {
                x: 1200,
                y: 600,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4tl791005c1ipg0c2chgte',
                awsInternalPath: 'doits/ckd4tl791005c1ipg0c2chgte.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tl791005c1ipg0c2chgte.png',
                editedName: 'cans2.png',
                formattedSize: '263.94 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4tx18m005j1ipgcpfj3r80',
                awsInternalPath: 'doits/ckd4tx18m005j1ipgcpfj3r80.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tx18m005j1ipgcpfj3r80.mp3',
                editedName: 'can2.mp3',
                formattedSize: '39.14 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tl791005c1ipg0c2chgte.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4tx18m005j1ipgcpfj3r80.mp3',
              spriteLocalUri: 'ckd4tl791005c1ipg0c2chgte.png',
              audioLocalUri: 'ckd4tx18m005j1ipgcpfj3r80.mp3',
            },
            {
              size: {
                x: 1000,
                y: 550,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4tnc1s005d1ipgbt6l5p7e',
                awsInternalPath: 'doits/ckd4tnc1s005d1ipgbt6l5p7e.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tnc1s005d1ipgbt6l5p7e.png',
                editedName: 'cans3.png',
                formattedSize: '260.49 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4tx63g005k1ipg8flp0nzm',
                awsInternalPath: 'doits/ckd4tx63g005k1ipg8flp0nzm.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tx63g005k1ipg8flp0nzm.mp3',
                editedName: 'can3.mp3',
                formattedSize: '44.67 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tnc1s005d1ipgbt6l5p7e.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4tx63g005k1ipg8flp0nzm.mp3',
              spriteLocalUri: 'ckd4tnc1s005d1ipgbt6l5p7e.png',
              audioLocalUri: 'ckd4tx63g005k1ipg8flp0nzm.mp3',
            },
            {
              size: {
                x: 1000,
                y: 550,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4tvlnc005e1ipgbcnbfi39',
                awsInternalPath: 'doits/ckd4tvlnc005e1ipgbcnbfi39.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tvlnc005e1ipgbcnbfi39.png',
                editedName: 'cans4.png',
                formattedSize: '259.94 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4txact005l1ipgee4m48kl',
                awsInternalPath: 'doits/ckd4txact005l1ipgee4m48kl.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4txact005l1ipgee4m48kl.mp3',
                editedName: 'can4.mp3',
                formattedSize: '40.39 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tvlnc005e1ipgbcnbfi39.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4txact005l1ipgee4m48kl.mp3',
              spriteLocalUri: 'ckd4tvlnc005e1ipgbcnbfi39.png',
              audioLocalUri: 'ckd4txact005l1ipgee4m48kl.mp3',
            },
            {
              size: {
                x: 1000,
                y: 550,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4tvrs3005f1ipg27ym6ng3',
                awsInternalPath: 'doits/ckd4tvrs3005f1ipg27ym6ng3.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tvrs3005f1ipg27ym6ng3.png',
                editedName: 'cans5.png',
                formattedSize: '245.13 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4txf9u005m1ipg845s8tsa',
                awsInternalPath: 'doits/ckd4txf9u005m1ipg845s8tsa.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4txf9u005m1ipg845s8tsa.mp3',
                editedName: 'cans5.mp3',
                formattedSize: '35.20 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tvrs3005f1ipg27ym6ng3.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4txf9u005m1ipg845s8tsa.mp3',
              spriteLocalUri: 'ckd4tvrs3005f1ipg27ym6ng3.png',
              audioLocalUri: 'ckd4txf9u005m1ipg845s8tsa.mp3',
            },
            {
              size: {
                x: 1000,
                y: 550,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4tvz59005g1ipg62bdh96j',
                awsInternalPath: 'doits/ckd4tvz59005g1ipg62bdh96j.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tvz59005g1ipg62bdh96j.png',
                editedName: 'cans6.png',
                formattedSize: '245.16 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4txjl0005n1ipgauh395i2',
                awsInternalPath: 'doits/ckd4txjl0005n1ipgauh395i2.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4txjl0005n1ipgauh395i2.mp3',
                editedName: 'cans6.mp3',
                formattedSize: '44.26 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tvz59005g1ipg62bdh96j.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4txjl0005n1ipgauh395i2.mp3',
              spriteLocalUri: 'ckd4tvz59005g1ipg62bdh96j.png',
              audioLocalUri: 'ckd4txjl0005n1ipgauh395i2.mp3',
            },
            {
              size: {
                x: 1000,
                y: 550,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4tw54c005h1ipgakepaht0',
                awsInternalPath: 'doits/ckd4tw54c005h1ipgakepaht0.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4tw54c005h1ipgakepaht0.png',
                editedName: 'cans7.png',
                formattedSize: '371.74 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4txo03005o1ipg03ql7vui',
                awsInternalPath: 'doits/ckd4txo03005o1ipg03ql7vui.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4txo03005o1ipg03ql7vui.mp3',
                editedName: 'cans6.mp3',
                formattedSize: '44.26 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4tw54c005h1ipgakepaht0.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4txo03005o1ipg03ql7vui.mp3',
              spriteLocalUri: 'ckd4tw54c005h1ipgakepaht0.png',
              audioLocalUri: 'ckd4txo03005o1ipg03ql7vui.mp3',
            },
            {
              size: {
                x: 1000,
                y: 550,
              },
              position: {
                x: 35,
                y: -70,
              },
              spriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '19 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4txo03005o1ipg03ql7vui',
                awsInternalPath: 'doits/ckd4txo03005o1ipg03ql7vui.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4txo03005o1ipg03ql7vui.mp3',
                editedName: 'cans6.mp3',
                formattedSize: '44.26 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4txo03005o1ipg03ql7vui.mp3',
              spriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
              audioLocalUri: 'ckd4txo03005o1ipg03ql7vui.mp3',
            },
          ],
          itemId: 'ckd4tgilm005x2c6e6lf3k4sl',
        },
      },
      {
        itemId: 'ckd4uapef00892c6el3us0n72',
        name: 'Robot cake candles',
        baseTemplateId: 'find-it-template',
        dynamicTemplateId: 'find-it-template',
        startSecs: 798,
        finishSecs: 836,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4zfbcz006y1ipg6law2gen',
              awsInternalPath: 'doits/ckd4zfbcz006y1ipg6law2gen.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4zfbcz006y1ipg6law2gen.png',
              editedName: 'RobotCake Plate Candles.png',
              formattedSize: '434.35 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4zfbcz006y1ipg6law2gen.png',
            spriteLocalUri: 'ckd4zfbcz006y1ipg6law2gen.png',
          },
          completeSound: {
            file: null,
            uri: '',
            localUri: null,
          },
          findables: [
            {
              position: {
                x: -45,
                y: 280,
              },
              size: {
                x: 125,
                y: 200,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd4zftwc006z1ipg7hmn9op2',
                awsInternalPath: 'doits/ckd4zftwc006z1ipg7hmn9op2.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4zftwc006z1ipg7hmn9op2.png',
                editedName: 'blue.png',
                formattedSize: '10.71 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4wty3j006o1ipg2imr0pfa',
                awsInternalPath: 'doits/ckd4wty3j006o1ipg2imr0pfa.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4wty3j006o1ipg2imr0pfa.mp3',
                editedName: 'blow-a.mp3',
                formattedSize: '16.51 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4zftwc006z1ipg7hmn9op2.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4wty3j006o1ipg2imr0pfa.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd4zftwc006z1ipg7hmn9op2.png',
              audioLocalUri: 'ckd4wty3j006o1ipg2imr0pfa.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: 133,
                y: 280,
              },
              size: {
                x: 125,
                y: 230,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd4zftwc006z1ipg7hmn9op2',
                awsInternalPath: 'doits/ckd4zftwc006z1ipg7hmn9op2.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4zftwc006z1ipg7hmn9op2.png',
                editedName: 'blue.png',
                formattedSize: '10.71 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd51i0ro00751ipg9jow219m',
                awsInternalPath: 'doits/ckd51i0ro00751ipg9jow219m.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51i0ro00751ipg9jow219m.mp3',
                editedName: 'blow-b.mp3',
                formattedSize: '7.83 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4zftwc006z1ipg7hmn9op2.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd51i0ro00751ipg9jow219m.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd4zftwc006z1ipg7hmn9op2.png',
              audioLocalUri: 'ckd51i0ro00751ipg9jow219m.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: 181,
                y: 3,
              },
              size: {
                x: 125,
                y: 225,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd517ic900701ipg8d1r9bck',
                awsInternalPath: 'doits/ckd517ic900701ipg8d1r9bck.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd517ic900701ipg8d1r9bck.png',
                editedName: 'black.png',
                formattedSize: '11.19 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd51i7mq00761ipg1d31efid',
                awsInternalPath: 'doits/ckd51i7mq00761ipg1d31efid.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51i7mq00761ipg1d31efid.mp3',
                editedName: 'blow-c.mp3',
                formattedSize: '13.10 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd517ic900701ipg8d1r9bck.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd51i7mq00761ipg1d31efid.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd517ic900701ipg8d1r9bck.png',
              audioLocalUri: 'ckd51i7mq00761ipg1d31efid.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: -174,
                y: 52,
              },
              size: {
                x: 125,
                y: 225,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd517ic900701ipg8d1r9bck',
                awsInternalPath: 'doits/ckd517ic900701ipg8d1r9bck.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd517ic900701ipg8d1r9bck.png',
                editedName: 'black.png',
                formattedSize: '11.19 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd51idrm00771ipg2nquazrm',
                awsInternalPath: 'doits/ckd51idrm00771ipg2nquazrm.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51idrm00771ipg2nquazrm.mp3',
                editedName: 'blow-d.mp3',
                formattedSize: '5.32 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd517ic900701ipg8d1r9bck.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd51idrm00771ipg2nquazrm.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd517ic900701ipg8d1r9bck.png',
              audioLocalUri: 'ckd51idrm00771ipg2nquazrm.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: 60,
                y: 240,
              },
              size: {
                x: 125,
                y: 225,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd51as0i00711ipg8t2o0a5u',
                awsInternalPath: 'doits/ckd51as0i00711ipg8t2o0a5u.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51as0i00711ipg8t2o0a5u.png',
                editedName: 'green.png',
                formattedSize: '10.59 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd51ijns00781ipg6kw45qbf',
                awsInternalPath: 'doits/ckd51ijns00781ipg6kw45qbf.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51ijns00781ipg6kw45qbf.mp3',
                editedName: 'blow-e.mp3',
                formattedSize: '12.42 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd51as0i00711ipg8t2o0a5u.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd51ijns00781ipg6kw45qbf.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd51as0i00711ipg8t2o0a5u.png',
              audioLocalUri: 'ckd51ijns00781ipg6kw45qbf.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: -106,
                y: 263,
              },
              size: {
                x: 125,
                y: 225,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd51covq00721ipgdhq4ej4q',
                awsInternalPath: 'doits/ckd51covq00721ipgdhq4ej4q.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51covq00721ipgdhq4ej4q.png',
                editedName: 'orange.png',
                formattedSize: '10.42 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd51ipec00791ipg4ffcex18',
                awsInternalPath: 'doits/ckd51ipec00791ipg4ffcex18.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51ipec00791ipg4ffcex18.mp3',
                editedName: 'blow-f.mp3',
                formattedSize: '6.00 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd51covq00721ipgdhq4ej4q.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd51ipec00791ipg4ffcex18.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd51covq00721ipgdhq4ej4q.png',
              audioLocalUri: 'ckd51ipec00791ipg4ffcex18.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: 15,
                y: 255,
              },
              size: {
                x: 125,
                y: 225,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd51e2c900731ipg48iad7hk',
                awsInternalPath: 'doits/ckd51e2c900731ipg48iad7hk.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51e2c900731ipg48iad7hk.png',
                editedName: 'red.png',
                formattedSize: '10.74 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4wty3j006o1ipg2imr0pfa',
                awsInternalPath: 'doits/ckd4wty3j006o1ipg2imr0pfa.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4wty3j006o1ipg2imr0pfa.mp3',
                editedName: 'blow-a.mp3',
                formattedSize: '16.51 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd51e2c900731ipg48iad7hk.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4wty3j006o1ipg2imr0pfa.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd51e2c900731ipg48iad7hk.png',
              audioLocalUri: 'ckd4wty3j006o1ipg2imr0pfa.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: -74,
                y: -130,
              },
              size: {
                x: 125,
                y: 225,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd51f8lb00741ipgck76h85j',
                awsInternalPath: 'doits/ckd51f8lb00741ipgck76h85j.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51f8lb00741ipgck76h85j.png',
                editedName: 'yellow.png',
                formattedSize: '10.63 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd51i0ro00751ipg9jow219m',
                awsInternalPath: 'doits/ckd51i0ro00751ipg9jow219m.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51i0ro00751ipg9jow219m.mp3',
                editedName: 'blow-b.mp3',
                formattedSize: '7.83 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd51f8lb00741ipgck76h85j.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd51i0ro00751ipg9jow219m.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd51f8lb00741ipgck76h85j.png',
              audioLocalUri: 'ckd51i0ro00751ipg9jow219m.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
            {
              position: {
                x: -175,
                y: -105,
              },
              size: {
                x: 125,
                y: 225,
              },
              tweenTo: {
                x: -191,
                y: -110,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckd51f8lb00741ipgck76h85j',
                awsInternalPath: 'doits/ckd51f8lb00741ipgck76h85j.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51f8lb00741ipgck76h85j.png',
                editedName: 'yellow.png',
                formattedSize: '10.63 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd51idrm00771ipg2nquazrm',
                awsInternalPath: 'doits/ckd51idrm00771ipg2nquazrm.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd51idrm00771ipg2nquazrm.mp3',
                editedName: 'blow-d.mp3',
                formattedSize: '5.32 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd51f8lb00741ipgck76h85j.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd51idrm00771ipg2nquazrm.mp3',
              swapToSpriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: '2 hours ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              spriteLocalUri: 'ckd51f8lb00741ipgck76h85j.png',
              audioLocalUri: 'ckd51idrm00771ipg2nquazrm.mp3',
              swapToSpriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
            },
          ],
          itemId: 'ckd4uapef00892c6el3us0n72',
        },
      },
      {
        itemId: 'ckd4usc4o008m2c6esn8f39ig',
        name: 'Robot cake eat',
        baseTemplateId: 'sequence-it-template',
        dynamicTemplateId: 'sequence-it-template',
        startSecs: 852,
        finishSecs: 891,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckd4uv2fw005v1ipgct6vdzzr',
              awsInternalPath: 'doits/ckd4uv2fw005v1ipgct6vdzzr.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uv2fw005v1ipgct6vdzzr.png',
              editedName: 'Plate Cloth.png',
              formattedSize: '398.06 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uv2fw005v1ipgct6vdzzr.png',
            spriteLocalUri: 'ckd4uv2fw005v1ipgct6vdzzr.png',
          },
          sequence: [
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4uvz48005w1ipghl7165vi',
                awsInternalPath: 'doits/ckd4uvz48005w1ipghl7165vi.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uvz48005w1ipghl7165vi.png',
                editedName: '640x720robotcake.png',
                formattedSize: '78.75 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v2n7u006c1ipgeem1emk7',
                awsInternalPath: 'doits/ckd4v2n7u006c1ipgeem1emk7.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v2n7u006c1ipgeem1emk7.mp3',
                editedName: 'bite1.mp3',
                formattedSize: '15.66 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uvz48005w1ipghl7165vi.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v2n7u006c1ipgeem1emk7.mp3',
              spriteLocalUri: 'ckd4uvz48005w1ipghl7165vi.png',
              audioLocalUri: 'ckd4v2n7u006c1ipgeem1emk7.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4uyo6p005x1ipgdk002ujl',
                awsInternalPath: 'doits/ckd4uyo6p005x1ipgdk002ujl.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uyo6p005x1ipgdk002ujl.png',
                editedName: '640x720_0000s_0014_2.png',
                formattedSize: '73.66 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v2su6006d1ipgg4mx9ucj',
                awsInternalPath: 'doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
                editedName: 'bite2.mp3',
                formattedSize: '8.46 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uyo6p005x1ipgdk002ujl.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
              spriteLocalUri: 'ckd4uyo6p005x1ipgdk002ujl.png',
              audioLocalUri: 'ckd4v2su6006d1ipgg4mx9ucj.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4uyyyy005y1ipghdte6ar5',
                awsInternalPath: 'doits/ckd4uyyyy005y1ipghdte6ar5.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uyyyy005y1ipghdte6ar5.png',
                editedName: '640x720_0000s_0013_3.png',
                formattedSize: '72.20 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v2zxc006e1ipg4lk20kyo',
                awsInternalPath: 'doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
                editedName: 'bite3.mp3',
                formattedSize: '10.70 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uyyyy005y1ipghdte6ar5.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
              spriteLocalUri: 'ckd4uyyyy005y1ipghdte6ar5.png',
              audioLocalUri: 'ckd4v2zxc006e1ipg4lk20kyo.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4uz6hf005z1ipgfp2lhzun',
                awsInternalPath: 'doits/ckd4uz6hf005z1ipgfp2lhzun.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uz6hf005z1ipgfp2lhzun.png',
                editedName: '640x720_0000s_0012_4.png',
                formattedSize: '71.30 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v3573006f1ipgdfcc8r8l',
                awsInternalPath: 'doits/ckd4v3573006f1ipgdfcc8r8l.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v3573006f1ipgdfcc8r8l.mp3',
                editedName: 'bite4.mp3',
                formattedSize: '6.05 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uz6hf005z1ipgfp2lhzun.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v3573006f1ipgdfcc8r8l.mp3',
              spriteLocalUri: 'ckd4uz6hf005z1ipgfp2lhzun.png',
              audioLocalUri: 'ckd4v3573006f1ipgdfcc8r8l.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4uzc1q00601ipg00md814k',
                awsInternalPath: 'doits/ckd4uzc1q00601ipg00md814k.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uzc1q00601ipg00md814k.png',
                editedName: '640x720_0000s_0011_5.png',
                formattedSize: '67.60 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v3azy006g1ipg8sg7fcih',
                awsInternalPath: 'doits/ckd4v3azy006g1ipg8sg7fcih.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v3azy006g1ipg8sg7fcih.mp3',
                editedName: 'bite5.mp3',
                formattedSize: '5.01 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uzc1q00601ipg00md814k.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v3azy006g1ipg8sg7fcih.mp3',
              spriteLocalUri: 'ckd4uzc1q00601ipg00md814k.png',
              audioLocalUri: 'ckd4v3azy006g1ipg8sg7fcih.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4uzk8r00611ipg5yt592kw',
                awsInternalPath: 'doits/ckd4uzk8r00611ipg5yt592kw.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uzk8r00611ipg5yt592kw.png',
                editedName: '640x720_0000s_0010_6.png',
                formattedSize: '64.95 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v3xo3006h1ipg47jf92iz',
                awsInternalPath: 'doits/ckd4v3xo3006h1ipg47jf92iz.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v3xo3006h1ipg47jf92iz.mp3',
                editedName: 'bite1.mp3',
                formattedSize: '15.66 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uzk8r00611ipg5yt592kw.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v3xo3006h1ipg47jf92iz.mp3',
              spriteLocalUri: 'ckd4uzk8r00611ipg5yt592kw.png',
              audioLocalUri: 'ckd4v3xo3006h1ipg47jf92iz.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4uzr2n00621ipg0qyl3iyx',
                awsInternalPath: 'doits/ckd4uzr2n00621ipg0qyl3iyx.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4uzr2n00621ipg0qyl3iyx.png',
                editedName: '640x720_0000s_0009_7.png',
                formattedSize: '60.37 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v2su6006d1ipgg4mx9ucj',
                awsInternalPath: 'doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
                editedName: 'bite2.mp3',
                formattedSize: '8.46 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4uzr2n00621ipg0qyl3iyx.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
              spriteLocalUri: 'ckd4uzr2n00621ipg0qyl3iyx.png',
              audioLocalUri: 'ckd4v2su6006d1ipgg4mx9ucj.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v056000631ipg550xf196',
                awsInternalPath: 'doits/ckd4v056000631ipg550xf196.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v056000631ipg550xf196.png',
                editedName: '640x720_0000s_0008_8.png',
                formattedSize: '57.70 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v2zxc006e1ipg4lk20kyo',
                awsInternalPath: 'doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
                editedName: 'bite3.mp3',
                formattedSize: '10.70 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v056000631ipg550xf196.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
              spriteLocalUri: 'ckd4v056000631ipg550xf196.png',
              audioLocalUri: 'ckd4v2zxc006e1ipg4lk20kyo.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v0ct500641ipgd86a2s4x',
                awsInternalPath: 'doits/ckd4v0ct500641ipgd86a2s4x.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v0ct500641ipgd86a2s4x.png',
                editedName: '640x720_0000s_0007_9.png',
                formattedSize: '52.85 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v3573006f1ipgdfcc8r8l',
                awsInternalPath: 'doits/ckd4v3573006f1ipgdfcc8r8l.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v3573006f1ipgdfcc8r8l.mp3',
                editedName: 'bite4.mp3',
                formattedSize: '6.05 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v0ct500641ipgd86a2s4x.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v3573006f1ipgdfcc8r8l.mp3',
              spriteLocalUri: 'ckd4v0ct500641ipgd86a2s4x.png',
              audioLocalUri: 'ckd4v3573006f1ipgdfcc8r8l.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v0j3x00651ipg9gzp41zt',
                awsInternalPath: 'doits/ckd4v0j3x00651ipg9gzp41zt.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v0j3x00651ipg9gzp41zt.png',
                editedName: '640x720_0000s_0006_10.png',
                formattedSize: '44.98 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v3azy006g1ipg8sg7fcih',
                awsInternalPath: 'doits/ckd4v3azy006g1ipg8sg7fcih.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v3azy006g1ipg8sg7fcih.mp3',
                editedName: 'bite5.mp3',
                formattedSize: '5.01 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v0j3x00651ipg9gzp41zt.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v3azy006g1ipg8sg7fcih.mp3',
              spriteLocalUri: 'ckd4v0j3x00651ipg9gzp41zt.png',
              audioLocalUri: 'ckd4v3azy006g1ipg8sg7fcih.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v105300671ipgbhx62ivl',
                awsInternalPath: 'doits/ckd4v105300671ipgbhx62ivl.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v105300671ipgbhx62ivl.png',
                editedName: '640x720_0000s_0005_11.png',
                formattedSize: '37.94 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v3xo3006h1ipg47jf92iz',
                awsInternalPath: 'doits/ckd4v3xo3006h1ipg47jf92iz.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v3xo3006h1ipg47jf92iz.mp3',
                editedName: 'bite1.mp3',
                formattedSize: '15.66 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v105300671ipgbhx62ivl.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v3xo3006h1ipg47jf92iz.mp3',
              spriteLocalUri: 'ckd4v105300671ipgbhx62ivl.png',
              audioLocalUri: 'ckd4v3xo3006h1ipg47jf92iz.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v0t0y00661ipgfpsz2ncp',
                awsInternalPath: 'doits/ckd4v0t0y00661ipgfpsz2ncp.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v0t0y00661ipgfpsz2ncp.png',
                editedName: '640x720_0000s_0004_12.png',
                formattedSize: '27.51 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v2su6006d1ipgg4mx9ucj',
                awsInternalPath: 'doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
                editedName: 'bite2.mp3',
                formattedSize: '8.46 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v0t0y00661ipgfpsz2ncp.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v2su6006d1ipgg4mx9ucj.mp3',
              spriteLocalUri: 'ckd4v0t0y00661ipgfpsz2ncp.png',
              audioLocalUri: 'ckd4v2su6006d1ipgg4mx9ucj.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v1dbn00681ipg2k9k6ds8',
                awsInternalPath: 'doits/ckd4v1dbn00681ipg2k9k6ds8.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1dbn00681ipg2k9k6ds8.png',
                editedName: '640x720_0000s_0003_13.png',
                formattedSize: '22.67 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckd4v2zxc006e1ipg4lk20kyo',
                awsInternalPath: 'doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
                editedName: 'bite3.mp3',
                formattedSize: '10.70 kB',
                formattedCreated: 'a minute ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1dbn00681ipg2k9k6ds8.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckd4v2zxc006e1ipg4lk20kyo.mp3',
              spriteLocalUri: 'ckd4v1dbn00681ipg2k9k6ds8.png',
              audioLocalUri: 'ckd4v2zxc006e1ipg4lk20kyo.mp3',
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v1ir900691ipg3fika04d',
                awsInternalPath: 'doits/ckd4v1ir900691ipg3fika04d.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1ir900691ipg3fika04d.png',
                editedName: '640x720_0000s_0002_14.png',
                formattedSize: '14.24 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: null,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1ir900691ipg3fika04d.png',
              audioUri: '',
              spriteLocalUri: 'ckd4v1ir900691ipg3fika04d.png',
              audioLocalUri: null,
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v1pko006a1ipg26cne12w',
                awsInternalPath: 'doits/ckd4v1pko006a1ipg26cne12w.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1pko006a1ipg26cne12w.png',
                editedName: '640x720_0000s_0001_15.png',
                formattedSize: '11.15 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: null,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1pko006a1ipg26cne12w.png',
              audioUri: '',
              spriteLocalUri: 'ckd4v1pko006a1ipg26cne12w.png',
              audioLocalUri: null,
            },
            {
              size: {
                x: 550,
                y: 650,
              },
              position: {
                x: 0,
                y: -20,
              },
              spriteFile: {
                fileId: 'ckd4v1wv2006b1ipg8qhtg8n1',
                awsInternalPath: 'doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
                editedName: '640x720_0000s_0000_16.png',
                formattedSize: '7.06 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: null,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckd4v1wv2006b1ipg8qhtg8n1.png',
              audioUri: '',
              spriteLocalUri: 'ckd4v1wv2006b1ipg8qhtg8n1.png',
              audioLocalUri: null,
            },
          ],
          itemId: 'ckd4usc4o008m2c6esn8f39ig',
        },
      },
      {
        itemId: 'ckdixdjpf00042c6erkr0uo1i',
        name: 'Tims Phone Find it Devon',
        baseTemplateId: 'find-it-template',
        dynamicTemplateId: 'find-it-template',
        startSecs: 676,
        finishSecs: 689,
        params: {
          background: {
            spriteFile: {
              fileId: 'ckdixdx3c00491ipe7kztfmuz',
              awsInternalPath: 'doits/ckdixdx3c00491ipe7kztfmuz.png',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckdixdx3c00491ipe7kztfmuz.png',
              editedName: 'Devon Phone.png',
              formattedSize: '799.60 kB',
              formattedCreated: 'a few seconds ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'image/png',
            },
            spriteUri: 'https://assets.hellosaurus.com/doits/ckdixdx3c00491ipe7kztfmuz.png',
          },
          completeSound: {
            file: {
              fileId: 'ckcmsd0h9001y01ox6a4116aw',
              awsInternalPath: 'doits/ckcmsd0h9001y01ox6a4116aw.mp3',
              awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckcmsd0h9001y01ox6a4116aw.mp3',
              editedName: 'Silence.mp3',
              formattedSize: '964 B',
              formattedCreated: '22 days ago',
              uploaderUserId: 'ckc56if92000r01my0im1dvl5',
              uploaderName: 'Jonathan Shulman',
              mimeType: 'audio/mpeg',
            },
            uri: 'https://assets.hellosaurus.com/doits/ckcmsd0h9001y01ox6a4116aw.mp3',
          },
          findables: [
            {
              position: {
                x: -5,
                y: -197,
              },
              size: {
                x: 100,
                y: 100,
              },
              tweenTo: {
                x: -5,
                y: -197,
              },
              tweenSizeToScaleFactor: 1,
              spriteFile: {
                fileId: 'ckdiw84jk003u1iped3v6atk7',
                awsInternalPath: 'doits/ckdiw84jk003u1iped3v6atk7.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckdiw84jk003u1iped3v6atk7.png',
                editedName: 'phone_butt_up.png',
                formattedSize: '5.99 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              audioFile: {
                fileId: 'ckdiwbqqo003w1ipe09mq3hmw',
                awsInternalPath: 'doits/ckdiwbqqo003w1ipe09mq3hmw.mp3',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckdiwbqqo003w1ipe09mq3hmw.mp3',
                editedName: 'phone_button.mp3',
                formattedSize: '36.19 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'audio/mpeg',
              },
              disableOnClick: false,
              spriteUri: 'https://assets.hellosaurus.com/doits/ckdiw84jk003u1iped3v6atk7.png',
              audioUri: 'https://assets.hellosaurus.com/doits/ckdiwbqqo003w1ipe09mq3hmw.mp3',
              swapToSpriteFile: {
                fileId: 'ckdiw7rhc003t1ipe8c00acl8',
                awsInternalPath: 'doits/ckdiw7rhc003t1ipe8c00acl8.png',
                awsPublicUrl: 'https://assets.hellosaurus.com/doits/ckdiw7rhc003t1ipe8c00acl8.png',
                editedName: 'phone_butt_down.png',
                formattedSize: '4.61 kB',
                formattedCreated: 'a few seconds ago',
                uploaderUserId: 'ckc56if92000r01my0im1dvl5',
                uploaderName: 'Jonathan Shulman',
                mimeType: 'image/png',
              },
              swapToSpriteUri: 'https://assets.hellosaurus.com/doits/ckdiw7rhc003t1ipe8c00acl8.png',
            },
          ],
          itemId: 'ckdixdjpf00042c6erkr0uo1i',
        },
      },
    ],
    videoUrl:
      'https://player.vimeo.com/external/442075998.hd.mp4?s=40eb997933ba2b544cb92af35a34f218793faa25&profile_id=175&oauth2_token_id=1348825098',
    show: {
      showId: 'ckd6n94ae002e1ip7hzvyehm8',
      title: 'The Birthday Show By Hellosaurus',
    },
    channel: {
      logoUrl: 'https://assets.hellosaurus.com/doits/ckcqcxdmy000001nk7c2g1otq.png',
      channelId: 'ckd0i374y00001io42vz668vo',
      title: 'Hellosaurus',
    },
  };

  state = {
    isOpen: false,
    isPreviewVideoLoading: true,
    isUnsupportedModalOpen: false,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleWindowKeydown);
  }

  handleWindowKeydown = ({ key, keyCode, target }: KeyboardEvent) => {
    const isEscapeKey = key === 'Escape' || key === 'Esc' || keyCode === 27;
    const isTargetBody = (target as any).nodeName === 'BODY';

    if (!isEscapeKey || !isTargetBody) {
      return;
    }

    if (!this.state.isOpen) {
      return;
    }

    this.handleClick();
  };

  handleClick() {
    const isSupported = utils.isWebGLSupported();
    this.setState(({ isOpen }) => ({
      isOpen: isSupported && !isOpen,
      isUnsupportedModalOpen: !isSupported,
    }));
  }

  handlePreviewVideoLoad() {
    this.setState({ isPreviewVideoLoading: false });
  }

  handleUnsupportedModalClose() {
    this.setState({ isUnsupportedModalOpen: false });
  }

  render() {
    const { isOpen, isPreviewVideoLoading, isUnsupportedModalOpen } = this.state;
    const playerPreviewStyles = cx(styles.playerPreview, {
      [styles.playerPreviewVideoLoaded]: !isPreviewVideoLoading,
    });

    return (
      <div className={playerPreviewStyles}>
        <Image
          standard={playerDemoRetina}
          retina={playerDemoRetina}
          alt="A demonstration of an interactive show. Click to play."
          className={styles.playerPreviewBackground}
          onClick={() => this.handleClick()}
        />
        <video
          className={styles.playerPreviewBackground}
          onPlaying={() => this.handlePreviewVideoLoad()}
          src="/basketball.mp4"
          muted
          loop
          autoPlay
          preload="auto"
          playsInline
        />
        <Image
          standard={playStandard}
          retina={playRetina}
          alt="Play Icon. Click to play."
          className={styles.playerPreviewPlay}
          onClick={() => this.handleClick()}
        />
        <Unity
          episodePayload={PlayerPreview.DEMO_EPISODE}
          isOpen={isOpen}
          onClose={() => this.handleClick()}
        />
        <UnityMobileNotSupportedModal
          isOpen={isUnsupportedModalOpen}
          onClose={() => this.handleUnsupportedModalClose()}
        />
      </div>
    );
  }
}

export default PlayerPreview;
