import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import cx from 'classnames';

import { AnimatedImageProps, AnimatedImageState } from './types';

import styles from './AnimatedImage.css';

class AnimatedImage extends React.PureComponent<AnimatedImageProps, AnimatedImageState> {
  static defaultProps = {
    delayMs: 0,
  };

  state = {
    hasViewed: false,
    viewingImage: this.props.staticImage,
    animationTimer: undefined,
  };

  componentWillUnmount() {
    clearTimeout(this.state.animationTimer);
  }

  handleVisibility(isVisible: boolean) {
    const { hasViewed } = this.state;

    if (!hasViewed && isVisible) {
      this.startAnimation();
      this.setState({ hasViewed: true });
    }
  }

  handleClick() {
    this.startAnimation();
  }

  async startAnimation() {
    const { staticImage, animatedImage, durationMs, delayMs } = this.props;
    const { animationTimer } = this.state;

    if (animationTimer) {
      return;
    }

    const timer = setTimeout(() => {
      this.setState({ animationTimer: undefined, viewingImage: staticImage });
    }, durationMs + delayMs);

    this.setState({ animationTimer: timer });

    await new Promise((resolve) => {
      setTimeout(resolve, delayMs);
    });

    this.setState({ viewingImage: animatedImage });
  }

  render() {
    const { className, isClickable } = this.props;
    const { viewingImage, animationTimer } = this.state;
    const isAnimating = Boolean(animationTimer);
    const animatedImageStyles = cx(
      className,
      styles.animatedImage,
      isClickable && styles.animatedImageClickable,
      isAnimating && styles.animatedImageAnimating,
    );

    return (
      <VisibilitySensor
        partialVisibility={true}
        onChange={(isVisible) => this.handleVisibility(isVisible)}
      >
        <img
          className={animatedImageStyles}
          src={viewingImage}
          onClick={() => isClickable && this.handleClick()}
        />
      </VisibilitySensor>
    );
  }
}

export default AnimatedImage;
