/* eslint-disable @typescript-eslint/indent */

import React from 'react';

import Modal from '../Modal';
import { WaitListModalProps } from '../types';
import * as styles from './WaitListModal.css';

import {
  FacebookShareButton,
  TwitterShareButton,
  InstagramFollowButton,
} from '../../button/Button';

class WaitListModal extends React.PureComponent<WaitListModalProps> {
  static SHARE_TEXT =
    'Check out the new @playhellosaurus app for the most advanced interactive learning experience for kids! Reserve your spot today on hellosaurus.com.';

  render() {
    const { email } = this.props;

    return (
      <Modal {...this.props} className={styles.waitListModal}>
        <h3>Congrats! You&apos;re on the list.</h3>
        <p>
          We&apos;ll contact <a href={`mailto:${email}`}>{email}</a> when you&apos;re next in line.
        </p>
        <h4>Want to get access to Hellosaurus sooner?</h4>
        <p>Share this list with your friends!</p>
        <div className={styles.waitListModalShareButtons}>
          <TwitterShareButton shareText={WaitListModal.SHARE_TEXT} />
          <FacebookShareButton />
          <InstagramFollowButton />
        </div>
      </Modal>
    );
  }
}

export default WaitListModal;
