import React from 'react';
import Modal from '../Modal';
import { ModalProps, EarlyAccessModalState } from '../types';
import Input from '../../input/Input';
import { InputTypes } from '../../input/types';
import { ButtonPrimary } from '../../button/Button';
import * as utils from '../../../utils';

class EarlyAccessModal extends React.PureComponent<ModalProps, EarlyAccessModalState> {
  private inputFieldRef: React.RefObject<HTMLInputElement> = React.createRef();

  state = {
    email: '',
    isLoading: false,
  };

  componentDidUpdate(prevProps: ModalProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.inputFieldRef.current?.focus();
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.inputFieldRef.current?.blur();
    }
  }

  handleChange(email: string) {
    this.setState({ email });
  }

  async handleSubmit() {
    const { email } = this.state;
    this.setState({ isLoading: true });
    await utils.addUserToWaitList(email);
    this.props.onClose(email);
  }

  render() {
    const { email, isLoading } = this.state;
    const isEmailValid = utils.validateEmail(email);
    const buttonLabel = isLoading ? 'Loading…' : 'Submit';

    return (
      <Modal {...this.props} hasWavingDino>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <h3>Get Early Access</h3>
          <p>
            We are granting access to the Hellosaurus on a rolling basis. Enter your email below and
            we will reach out soon!
          </p>
          <Input
            placeholder="Enter your email"
            type={InputTypes.EMAIL}
            onChange={(email) => this.handleChange(email)}
            value={email}
            ref={this.inputFieldRef}
            isDisabled={isLoading}
            invalid={Boolean(email && !isEmailValid)}
          />
          <ButtonPrimary
            label={buttonLabel}
            onClick={() => this.handleSubmit()}
            isDisabled={!isEmailValid || isLoading}
          />
          {/* Allows submission of form through pressing enter */}
          <button aria-label="Submit" type="submit" hidden disabled={isLoading} />
        </form>
      </Modal>
    );
  }
}

export default EarlyAccessModal;
