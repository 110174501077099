import React from 'react';
import StaticNavBar from '../components/navbar/StaticNavBar';

import styles from '../../css/static-pages';

const TermsOfUsePage = () => (
  <div className={styles.termsOfUse}>
    <StaticNavBar />
    <main>
      <header>
        <h1>Terms of Use</h1>
        <p>Last Updated: November 8, 2020</p>
      </header>
      <p>
        Thank you for using Hellosaurus, shows you can play! Hellosaurus is owned and operated by
        All Together Labs, Inc. (“All Together Labs”, “ATL”, “Hellosaurus”, “we”, “us”, or “our”).
        Please read on to learn the rules and restrictions that govern your use of our website(s),
        products, services and applications (the “Services”).
      </p>
      <p>
        These Terms of Use (the “Terms”) are a binding contract (the “Agreement”) between you and
        All Together Labs, Inc (“All Together Labs”, “ATL”, “Hellosaurus”, “we”, “us”, or “our”).
        Your use of the Services in any way means that you agree to all of these Terms, and these
        Terms will remain in effect while you use the Services. These Terms include the provisions
        in this document as well as those in the <a href="/privacy-policy">Privacy Policy</a>.{' '}
        <strong>
          Your use of or participation in certain Services may also be subject to additional
          policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by
          reference, and you understand and agree that by using or participating in any such
          Services, you agree to also comply with these Additional Terms. Please read these Terms
          carefully.
        </strong>{' '}
        They cover important information about Services provided to you and any charges, taxes, and
        fees we bill you.{' '}
        <strong>
          These Terms include information about future changes to these Terms, automatic renewals,
          limitations of liability, a class action waiver and resolution of disputes by arbitration
          instead of in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT
          TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR
          ACCESS THE SERVICES IN ANY MANNER.
        </strong>
      </p>
      <p>
        <strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong> EXCEPT FOR CERTAIN TYPES OF
        DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES
        BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR
        RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
      </p>

      <section>
        <h2>Changes to Terms</h2>
        <p>
          We may modify the Agreement at any time, in our sole discretion. If we do so, we’ll let
          you know by posting the modified terms on our site located at www.hellosaurus.com, or
          through other communications as required by law. It’s important that you review the
          Agreement whenever we modify them, because if you continue to use the Services after we
          have posted modified terms, you are indicating that you agree to be bound by the modified
          Agreement. If you don’t agree to be bound by the modified Agreement, then you may not use
          the Services any longer.
        </p>
      </section>

      <section>
        <h2>What about my privacy?</h2>
        <p>
          Hellosaurus takes the privacy of its users very seriously. For the current Hellosaurus
          Privacy Policy, please click <a href="/privacy-policy">here</a>.
        </p>

        <strong>
          <u>
            <em>Children’s Online Privacy Protection Act</em>
          </u>
        </strong>
        <p>
          The Children’s Online Privacy Protection Act (“COPPA”) requires that online service
          providers obtain parental consent before they knowingly collect personally identifiable
          information online from children who are under thirteen (13). We do not knowingly collect
          or solicit personally identifiable information from children under thirteen (13) without
          obtaining verifiable consent from that child’s parent or guardian (“Parental Consent”),
          except for the limited amount of personally identifiable information we need to collect in
          order to obtain Parental Consent (“Required Information”). Until we have received Parental
          Consent, we will only use Required Information for the purpose of obtaining Parental
          Consent. If you are a child under thirteen (13), please do not attempt to register for or
          otherwise use the Services or send any personal information about yourself to us before we
          obtain Parental Consent, except for the Required Information in the context of the
          Parental Consent process. If we learn we have collected personal information from a child
          under thirteen (13) (beyond the Required Information) without our obtaining Parental
          Consent, we will delete that information as quickly as possible. If you believe that a
          child under thirteen (13) may have provided us with personal information (beyond the
          Required Information) without our obtaining Parental Consent, please contact us at{' '}
          <a href="mailto:privacy@hellosaurus.com">privacy@hellosaurus.com</a>.
        </p>
      </section>

      <section>
        <h2>Hellosaurus Basics</h2>
        <p>
          You may be required to sign up for an account, which may require you to set a password and
          provide us with certain information or data, such as your name and your contact
          information. You promise to provide us with accurate, complete, and updated registration
          information about yourself. You may not transfer your account to anyone else without our
          prior written permission.
        </p>
        <p>
          You represent and warrant that you are an individual of legal age to form a binding
          contract (or if not, you’ve received your parent’s or guardian’s permission to use the
          Services and have gotten your parent or guardian to agree to these Terms on your behalf).
        </p>
        <p>
          You will only use the Services for your own internal, personal, non-commercial use, and
          not on behalf of or for the benefit of any third party, and only in a manner that complies
          with all laws that apply to you. If your use of the Services is prohibited by applicable
          laws, then you aren’t authorized to use the Services. We can’t and won’t be responsible
          for your using the Services in a way that breaks the law.
        </p>
        <p>
          You will not share your account information with anyone, and you must protect the security
          of your account and any other access tools or credentials. You’re responsible for any
          activity associated with your account.
        </p>
        <p>
          You acknowledge and agree that the availability of the Services is dependent on the third
          party stores from which you download the application, e.g., the App Store from Apple or
          the Android app market from Google (each an “App Store”). Each App Store may have its own
          terms and conditions to which you must agree before downloading Hellosaurus from such
          store, including the specific terms relating to Apple App Store set forth below. You agree
          to comply with, and your license to use Hellosaurus is conditioned upon your compliance
          with, such App Store terms and conditions. To the extent such other terms and conditions
          from such App Store are less restrictive than, or otherwise conflict with, the terms and
          conditions of these Terms of Use, the more restrictive or conflicting terms and conditions
          in these Terms of Use apply.
        </p>
      </section>

      <section>
        <h2>Messaging</h2>
        <p>
          As part of the Services, you may receive communications through the Services, including
          messages that Hellosaurus sends you (for example, via email, push notifcation or SMS).
          When signing up for the Services, you will receive a welcome message and instructions on
          how to stop receiving messages.{' '}
          <strong>
            By signing up for the Services and providing us with your email and wireless number, you
            confirm that you want Helloaurus to send you information that we think may be of
            interest to you, which may include Hellosaurus using automated dialing technology to
            text you at the wireless number you provided, and you agree to receive communications
            from Hellosaurus, and you represent and warrant that each person you register for the
            Services or for whom you provide an email or wireless phone number has consented to
            receive communications from Hellosaurus.
          </strong>{' '}
          You agree to indemnify and hold Hellosaurus harmless from and against any and all claims,
          liabilities, damages (actual and consequential), losses and expenses (including attorneys’
          fees) arising from or in any way related to your breach of the foregoing.
        </p>
      </section>

      <section>
        <h2>Your Responsibilities</h2>
        <p>
          You are responsible for using the Services in accordance with this Agreement, and for all
          activity that occurs under your Account, and the Account of your child. For your Account
          and the Account(s) of any children you authorize access, you shall (i) maintain the
          confidentiality of the user names, contact information, and passwords; (ii) promptly
          notify Hellosaurus if you know or suspect a security incident, including any unauthorized
          use of accounts and passwords; (iii) represent and warrant that the information provided
          to establish your account(s) is true and accurate, and that you will promptly update that
          information in the event of any change.
        </p>
        <p>
          You will not: (i) permit any third party to access the Services using your Account
          information; (ii) decompile, disassemble, overburden, impair, alter or reverse engineer
          the Services; (iii) use the Services or any of our confidential information to develop a
          competing product or service; (iv) impersonate another user of the Services or provide
          false information to gain access to the Services; (v) view or attempt to view records of
          another user; (vi) use automated systems, such as robots, spiders, etc., to access the
          Services or (vii) upload content, including videos, that do not comply with the terms of
          this Agreement.
        </p>

        <p>
          You agree to abide by all applicable laws and regulations. You may not use the Services
          to:
        </p>
        <ul>
          <li>
            Upload, post, e-mail or otherwise transmit any material that is unlawful, harmful,
            threatening, intimidating, abusive, harassing, tortuous, defamatory, derogatory, vulgar,
            obscene, libelous, invasive of another’s privacy, disrespectful, hateful, or racially,
            ethnically or otherwise objectionable;
          </li>
          <li>
            Forge headers or otherwise manipulate identifiers in order to disguise the origin of any
            Materials transmitted through the Services;
          </li>
          <li>
            Upload, post, e-mail or otherwise transmit any material that You do not have the right
            to transmit under any law or under contractual or fiduciary relationships;
          </li>
          <li>
            Upload, post, e-mail or otherwise transmit any material that infringes any patent,
            trademark, trade secret, copyright or other proprietary rights of any party;
          </li>
          <li>
            Upload, post, e-mail or otherwise transmit any material that contains software viruses
            or worms or any other computer code, files or programs designed to disable, interrupt,
            destroy, redirect, monitor another user’s usage, limit or otherwise inhibit the
            functionality of any computer software or hardware or telecommunications equipment;
          </li>
          <li>
            Use racially or ethnically offensive language; discuss or incite illegal activity; use
            explicit/obscene language or solicit/post sexually explicit images;
          </li>
          <li>
            Attempt to probe, scan, or test the vulnerability of any system or network operated by
            us, or breach or impair or circumvent any security or authentication measures protecting
            the Services;
          </li>
          <li>
            Attack the Services via a denial-of-service attack or a distributed denial-of-service
            attack or otherwise attempt to interfere with the proper working of the Services;
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related features on the
            Services or features that prevent or restrict use or copying of any content, or attempt
            to access or search the Services or download content from the Services through the use
            of any engine, software, tool, agent, device or mechanism (including spiders, robots,
            crawlers, data mining tools or the like) other than the software provided by us or other
            generally available third-party web browsers;
          </li>
          <li>
            Interfere with or disrupt the Services or servers or networks connected to the Services,
            or disobey any requirements, procedures, policies or regulations of networks connected
            to the Services; or
          </li>
          <li>
            Intentionally or unintentionally violate any applicable local, state, national or
            international law or regulation.
          </li>
          <li>
            Decompiles, reverse engineers, or otherwise attempts to obtain the source code or
            underlying ideas or information of or relating to the Services.
          </li>
        </ul>
        <p>
          Although we’re not obligated to monitor access to or use of the Services, we have the
          right to do so for the purpose of operating the Services, to ensure compliance with these
          terms, and to comply with applicable law or other legal requirements. We have the right to
          investigate violations of this Agreement or conduct that affects the Services.
        </p>
        <p>
          You may upload content, including videos and other materials to the Services, but you may
          not upload to, or distribute or otherwise publish through the Services any content,
          information, or other material that (a) violates or infringes the rights of any persons,
          including without limitation, rights in copyrights, patents, trademarks, service marks,
          trade secrets, and other proprietary rights; (b) is libelous, threatening, defamatory,
          obscene, indecent, pornographic, or could give rise to any civil or criminal liability
          under U.S. or international law; or (c) includes any bugs, viruses, worms, trap doors,
          Trojan horses or other harmful code or properties.
        </p>
      </section>

      <section>
        <h2>Intellectual Property</h2>
        <p>
          This Services are owned by Hellosaurus, our licensors, or other providers of such
          materials. For purposes of clarity, Hellosaurus owns the rights to the compilation,
          arrangement, and assembly, along with any modifications, variations, updates, versions,
          and changes to all information entered and stored within the database associated with the
          Site. This material, collectively, is protected by United States copyright, trademark,
          patent, trade secret, and other intellectual property or proprietary rights laws. All of
          the aforementioned content is copyrighted material and is protected by the Copyright Act
          of 1976. You are not permitted to republish, reproduce, transmit, transfer, prepare
          derivative versions or works, or otherwise use any content on appearing within the
          Services without prior, express, and written permission from the appropriate copyright
          holder.
        </p>
        <p>
          Furthermore, you acknowledge that all trademarks, service marks and logos, as well as all
          text, graphics, images, music, software, audio, video, works of authorship of any kind,
          User Submissions (as defined below) and information or other materials that we post or
          make available through the Services (collectively, “Materials”) are owned by or licensed
          to Hellosaurus and are protected by copyright and other intellectual property rights, and
          that you have no rights to transfer or reproduce the Site or prepare any derivative works
          with respect to, or to disclose confidential information pertaining to the Hellosaurus.
          You agree not to remove, alter or obscure any Materials, including but not limited to any
          copyright, trademark, service mark, or other proprietary rights and related notices
          incorporated in or accompanying the Services.
        </p>
        <p>
          For purposes of clarity, you acknowledge that you do not, and will not, acquire any
          intellectual property rights in the content appearing with the Services or related
          Materials, including, but not limited to, the underlying services and the content
          published herein, by your use of the Services. Subject to your compliance with the terms
          and conditions of this Agreement, we grant you a limited, non-exclusive, non-transferable,
          and revocable license, without the right to sublicense, to access and use the Services and
          to download and print any content provided by us, solely for your personal and
          non-commercial purposes. No licenses or rights are granted to you by implication or
          otherwise under any intellectual property rights owned or controlled by us or our
          licensors, except for the limited license expressly granted in the preceding sentence. No
          licenses or rights are granted to you by the terms of this Agreement with respect to any
          articles or trade journal materials which may be accessed by clicking on a third-party
          link on our Services.
        </p>
        <p>
          Anything you post, upload, share, store, or otherwise provide through the Services is your
          “User Submission”. Some User Submissions may be viewable by other users. You are solely
          responsible for all User Submissions you contribute to the Services. You represent that
          all User Submissions submitted by you are accurate, complete, up-to-date, and in
          compliance with all applicable laws, rules and regulations.
        </p>
        <p>
          You agree that you will not post, upload, share, store, or otherwise provide through the
          Services any User Submissions that: (i) infringe any third party's copyrights or other
          rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or
          pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred
          against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme
          violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote
          fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online
          gaming and gambling, cash gifting, work from home businesses, or any other dubious
          money-making ventures; or (viii) that violate any law.
        </p>
        <p>
          In order to display your User Submissions on the Services, and to allow other users to
          enjoy them (where applicable), you grant us certain rights in those User Submissions (see
          below for more information). Please note that all of the following licenses are subject to
          our <a href="/privacy-policy">Privacy Policy</a> to the extent they relate to User
          Submissions that are also your personally-identifiable information.
        </p>
        <p>
          For all User Submissions, you hereby grant Hellosaurus a license to translate, modify (for
          technical purposes, for example, making sure your content is viewable on a mobile device
          as well as a computer) and reproduce and otherwise act with respect to such User
          Submissions, in each case to enable us to operate the Services, as described in more
          detail below. This is a license only – your ownership in User Submissions is not affected.
        </p>
        <p>
          If you store a User Submission in your own personal Hellosaurus account, in a manner that
          is not viewable by any other user except you (a “Personal User Submission”), you grant
          Hellosaurus the license above, as well as a license to display, perform, and distribute
          your Personal User Submission for the sole purpose of making that Personal User Submission
          accessible to you and providing the Services necessary to do so.
        </p>
        <p>
          If you share a User Submission in a manner that only certain specified users can view (for
          example, a private message to one or more other users) (a “Limited Audience User
          Submission”), then you grant Hellosaurus the licenses above, as well as a license to
          display, perform, and distribute your Limited Audience User Submission for the sole
          purpose of making that Limited Audience User Submission accessible to such other specified
          users, and providing the Services necessary to do so. Also, you grant such other specified
          users a license to access that Limited Audience User Submission, and to use and exercise
          all rights in it, as permitted by the functionality of the Services.
        </p>
        <p>
          If you share a User Submission publicly on the Services and/or in a manner that more than
          just you or certain specified users can view, or if you provide us (in a direct email or
          otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature
          requests relating to the Services (each of the foregoing, a “Public User Submission”),
          then you grant Hellosaurus the licenses above, as well as a license to display, perform,
          and distribute your Public User Submission for the purpose of making that Public User
          Submission accessible to all Hellosaurus users and providing the Services necessary to do
          so, as well as all other rights necessary to use and exercise all rights in that Public
          User Submission in connection with the Services and/or otherwise in connection with
          Hellosaurus’s business. Also, you grant all other users of the Services a license to
          access that Public User Submission, and to use and exercise all rights in it, as permitted
          by the functionality of the Services.
        </p>
        <p>
          You agree that the licenses you grant are royalty-free, perpetual, sublicensable,
          irrevocable, and worldwide, provided that when you delete your Hellosaurus account, we
          will stop displaying your User Submissions (other than Public User Submissions, which may
          remain fully available) to other users (if applicable), but you understand and agree that
          it may not be possible to completely delete that content from Hellosaurus’s records, and
          that your User Submissions may remain viewable elsewhere to the extent that they were
          copied or stored by other users.
        </p>
        <p>
          Certain features of the Services allow you to share information with others, including
          through your social networks or other services where you have an account (“Third Party
          Accounts”). When Content is authorized for sharing, we will clearly identify the Content
          you are authorized to redistribute and the ways you may redistribute it, usually by
          providing a “share” button on or near the Content. If you share information from the
          Services with others through your Third Party Accounts, such as your social networks, you
          authorize Hellosaurus to share that information with the applicable Third Party Account
          provider. Please review the policies of any Third Party Account providers you share
          information with or through for additional information about how they may use your
          information. If you redistribute Content, you must be able to edit or delete any Content
          you redistribute, and you must edit or delete it promptly upon our request.
        </p>
        <p>
          Finally, you understand and agree that Hellosaurus, in performing the required technical
          steps to provide the Services to our users (including you), may need to make changes to
          your User Submissions to conform and adapt those User Submissions to the technical
          requirements of connection networks, devices, services, or media, and the foregoing
          licenses include the rights to do so.
        </p>
      </section>

      <section>
        <h2>Cost</h2>
        <p>
          The Services may be free or we may charge a fee for using the Services. If you are using a
          free version of the Services, we will notify you before any Services you are then using
          begin carrying a fee, and if you wish to continue using such Services, you must pay all
          applicable fees for such Services. Note that if you elect to receive text messages through
          the Services, data and message rates may apply. Any and all such charges, fees or costs
          are your sole responsibility. You should consult with your wireless carrier to determine
          what rates, charges, fees or costs may apply to your use of the Services.
        </p>
        <ol>
          <li>
            <u>Paid Services.</u> Certain of our Services may be subject to payments now or in the
            future (the “Paid Services”). Please note that any payment terms presented to you in the
            process of using or signing up for a Paid Service are deemed part of these Terms. For
            example, some Paid Services will charge a fee for downloading or installing the Services
            through the applicable App Store linked to your specific device. You agree to comply
            with, and your license to use our mobile application (“Hellosaurus”) is conditioned upon
            your compliance with, such App Store terms and conditions. Any refunds relating to
            Hellosaurus or inquiries regarding refunds relating to Hellosaurus shall be handled
            solely by the applicable App Store in accordance with such App Store’s terms and
            conditions. We are not responsible for any error by, or other acts or omissions of, any
            App Store. By choosing to use Paid Services, you agree to pay all charges at the prices
            then in effect for any use of such Paid Services in accordance with the applicable
            payment terms.
          </li>
          <li>
            <u>In-App Purchases.</u> Through our app Hellosaurus, you may purchase certain goods or
            features designed to enhance the performance of the Services (“In-App Purchase”). When
            you make an In-App Purchase, you are doing so through either the Apple iTunes service or
            the Google Play service and you are agreeing to their respective Terms and Conditions,
            available at http://www.apple.com/legal/internet-services/itunes/us/terms.html and
            http://play.google.com/intl/en_us/about/play-terms.html). Hellosaurus is not a party to
            any In-App Purchase.
          </li>
          <li>
            <u>Recurring Billing.</u> Some of the Paid Services may consist of an initial period,
            for which there is a one-time charge, followed by recurring period charges as agreed to
            by you. By choosing a recurring payment plan, you acknowledge that such Services have an
            initial and recurring payment feature and you accept responsibility for all recurring
            charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT
            FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS
            CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR
            PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD
            ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO THE
            SUBSCRIPTION MANAGEMENT SECTION OF THE APPLICABLE APP STORE.
          </li>
          <li>
            <u>Auto-Renewal for Paid Services.</u> Unless you opt out of auto-renewal, which can be
            done through the subscription management section of the applicable App Store, any Paid
            Services you have signed up for will be automatically extended for successive renewal
            periods of the same duration as the subscription term originally selected, at the
            then-current non-promotional rate. To change or resign your Paid Services at any time,
            cancel your subscription in the subscription management section of the applicable App
            Store. If you terminate a Paid Service, you may use your subscription until the end of
            your then-current term, and your subscription will not be renewed after your
            then-current term expires. However, you will not be eligible for a prorated refund of
            any portion of the subscription fee paid for the then-current subscription period. If
            you do not want to continue to be charged on a recurring monthly basis, you must cancel
            the applicable Paid Service through the subscription management section of the
            applicable App Store or terminate your Hellosaurus account before the end of the
            recurring term. Paid Services cannot be terminated before the end of the period for
            which you have already paid, and except as expressly provided in these terms, ATL will
            not refund any fees that you have already paid.
          </li>
          <li>
            <u>Current Information Required.</u> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE
            INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP
            YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING
            ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY
            NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR
            THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE
            UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH
            INFORMATION CAN BE MADE AT THE SUBSCRIPTION MANAGEMENT SECTION OF THE APPLICABLE APP
            STORE. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY
            CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU
            HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.
          </li>
          <li>
            <u>Free Trials and Other Promotions.</u> Any free trial or other promotion that provides
            access to a Paid Service must be used within the specified time of the trial. You must
            stop using a Paid Service before the end of the trial period in order to avoid being
            charged for that Paid Service. If you cancel prior to the end of the trial period and
            are inadvertently charged for a Paid Service, please contact us at
            support@hellosaurus.com.
          </li>
        </ol>
      </section>

      <section>
        <h2>Additional Information</h2>
        <p>
          <u>
            <em>Warranty Disclaimer.</em>
          </u>{' '}
          Hellosaurus and its licensors, suppliers, partners, parent, subsidiaries or affiliated
          entities, and each of their respective officers, directors, members, employees,
          consultants, contract employees, representatives and agents, and each of their respective
          successors and assigns (Helloaurus and all such parties together, the “Hellosaurus
          Parties”) make no representations or warranties concerning the Services, including without
          limitation regarding any Content contained in or accessed through the Services, and the
          Hellosaurus Parties will not be responsible or liable for the accuracy, copyright
          compliance, legality, or decency of material contained in or accessed through the Services
          or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising
          out of use of, or in any way related to your participation in, the Services. The
          Hellosaurus Parties make no representations or warranties regarding suggestions or
          recommendations of services or products offered or purchased through or in connection with
          the Services. THE SERVICES AND CONTENT ARE PROVIDED BY HELLOSAURUS (AND ITS LICENSORS AND
          SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR
          ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO
          THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        </p>

        <p>
          <u>
            <em>Limitation of Liability.</em>
          </u>{' '}
          TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL
          THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE)
          SHALL ANY OF THE HELLOSAURUS PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY
          INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING
          DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK
          STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE
          GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER
          OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO
          HELLOSAURUS IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS
          APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO
          THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>
        <p>
          <u>
            <em>Indemnity.</em>
          </u>{' '}
          You agree to indemnify and hold the Hellosaurus Parties harmless from and against any and
          all claims, liabilities, damages (actual and consequential), losses and expenses
          (including attorneys’ fees) arising from or in any way related to any claims relating to
          (a) your use of the Services (including any actions taken by a third party using your
          account), and (b) your violation of these Terms.
        </p>
        <p>
          <u>
            <em>Assignment.</em>
          </u>{' '}
          You may not assign, delegate or transfer these Terms or your rights or obligations
          hereunder, or your Services account, in any way (by operation of law or otherwise) without
          Hellosaurus’s prior written consent. We may transfer, assign, or delegate these Terms and
          our rights and obligations without consent.
        </p>
        <p>
          <u>
            <em>Choice of Law.</em>
          </u>{' '}
          These Terms are governed by and will be construed under the Federal Arbitration Act,
          applicable federal law, and the laws of the State of New York, without regard to the
          conflicts of laws provisions thereof.
        </p>
        <p>
          <u>
            <em>Arbitration Agreement.</em>
          </u>{' '}
          Please read the following ARBITRATION AGREEMENT carefully because it requires you to
          arbitrate certain disputes and claims with Hellosaurus and limits the manner in which you
          can seek relief from Hellosaurus. Both you and Hellosaurus acknowledge and agree that for
          the purposes of any dispute arising out of or relating to the subject matter of these
          Terms, Hellosaurus’s officers, directors, employees and independent contractors
          (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance
          of these Terms, Personnel will have the right (and will be deemed to have accepted the
          right) to enforce these Terms against you as the third-party beneficiary hereof.
        </p>
        <p>
          (a) <em>Arbitration Rules; Applicability of Arbitration Agreement.</em> The parties shall
          use their best efforts to settle any dispute, claim, question, or disagreement arising out
          of or relating to the subject matter of these Terms directly through good-faith
          negotiations, which shall be a precondition to either party initiating arbitration. If
          such negotiations do not resolve the dispute, it shall be finally settled by binding
          arbitration in New York, New York. The arbitration will proceed in the English language,
          in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”)
          then in effect, by one commercial arbitrator with substantial experience in resolving
          intellectual property and commercial contract disputes. The arbitrator shall be selected
          from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon
          the award rendered by such arbitrator may be entered in any court of competent
          jurisdiction.
        </p>
        <p>
          (b) <em>Costs of Arbitration.</em> The Rules will govern payment of all arbitration fees.
          Hellosaurus will pay all arbitration fees for claims less than seventy-five thousand
          ($75,000) dollars. Hellosaurus will not seek its attorneys’ fees and costs in arbitration
          unless the arbitrator determines that your claim is frivolous.
        </p>
        <p>
          (c) <em>Small Claims Court; Infringement.</em> Either you or Hellosaurus may assert
          claims, if they qualify, in small claims court in New York, New York or any United States
          county where you live or work. Furthermore, notwithstanding the foregoing obligation to
          arbitrate disputes, each party shall have the right to pursue injunctive or other
          equitable relief at any time, from any court of competent jurisdiction, to prevent the
          actual or threatened infringement, misappropriation or violation of a party's copyrights,
          trademarks, trade secrets, patents or other intellectual property rights.
        </p>
        <p>
          (d) <em>Waiver of Jury Trial.</em> YOU AND HELLOSAURUS WAIVE ANY CONSTITUTIONAL AND
          STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and
          Hellosaurus are instead choosing to have claims and disputes resolved by arbitration.
          Arbitration procedures are typically more limited, more efficient, and less costly than
          rules applicable in court and are subject to very limited review by a court. In any
          litigation between you and Hellosaurus over whether to vacate or enforce an arbitration
          award, YOU AND HELLOSAURUS WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the
          dispute be resolved by a judge.
        </p>
        <p>
          (e) <em>Waiver of Class or Consolidated Actions.</em> ALL CLAIMS AND DISPUTES WITHIN THE
          SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS
          AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
          LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however,
          this waiver of class or consolidated actions is deemed invalid or unenforceable, neither
          you nor Hellosaurus is entitled to arbitration; instead all claims and disputes will be
          resolved in a court as set forth in (g) below.
        </p>
        <p>
          (f) <em>Opt-out.</em> You have the right to opt out of the provisions of this Section by
          sending written notice of your decision to opt out to the following address: 600 Madision
          Avenue New York, NY 10022 postmarked within thirty (30) days of first accepting these
          Terms. You must include (i) your name and residence address, (ii) the email address and/or
          telephone number associated with your account, and (iii) a clear statement that you want
          to opt out of these Terms’ arbitration agreement.
        </p>
        <p>
          (g) <em>Exclusive Venue.</em> If you send the opt-out notice in (f), and/or in any
          circumstances where the foregoing arbitration agreement permits either you or Hellosaurus
          to litigate any dispute arising out of or relating to the subject matter of these Terms in
          court, then the foregoing arbitration agreement will not apply to either party, and both
          you and Hellosaurus agree that any judicial proceeding (other than small claims actions)
          will be brought in the state or federal courts located in, respectively, New York, NY or
          the federal district in which that county falls.
        </p>
        <p>
          (h) <em>Severability.</em> If the prohibition against class actions and other claims
          brought on behalf of third parties contained above is found to be unenforceable, then all
          of the preceding language in this Arbitration Agreement section will be null and void.
          This arbitration agreement will survive the termination of your relationship with
          Hellosaurus.
        </p>
        <p>
          <u>
            <em>Miscellaneous.</em>
          </u>{' '}
          You will be responsible for paying, withholding, filing, and reporting all taxes, duties,
          and other governmental assessments associated with your activity in connection with the
          Services, provided that the Hellosaurus may, in its sole discretion, do any of the
          foregoing on your behalf or for itself as it sees fit. The failure of either you or us to
          exercise, in any way, any right herein shall not be deemed a waiver of any further rights
          hereunder. If any provision of these Terms are found to be unenforceable or invalid, that
          provision will be limited or eliminated, to the minimum extent necessary, so that these
          Terms shall otherwise remain in full force and effect and enforceable. You and Hellosaurus
          agree that these Terms are the complete and exclusive statement of the mutual
          understanding between you and Hellosaurus, and that these Terms supersede and cancel all
          previous written and oral agreements, communications and other understandings relating to
          the subject matter of these Terms. You hereby acknowledge and agree that you are not an
          employee, agent, partner, or joint venture of Hellosaurus, and you do not have any
          authority of any kind to bind Hellosaurus in any respect whatsoever.
        </p>
        <p>
          Except as expressly set forth in the sections above regarding the Apple Hellosaurus and
          the arbitration agreement, you and Hellosaurus agree there are no third-party
          beneficiaries intended under these Terms.
        </p>
      </section>

      <section>
        <h2>Contacting All Together Labs</h2>
        <p>Please contact All Together Labs, Inc. with any questions or comments.</p>
        <p>
          By email: <a href="mailto:privacy@hellosaurus.com">privacy@hellosaurus.com</a>
        </p>
        <p>By mail: All Together Labs, Inc., 600 Madison Avenue, New York, NY 10022</p>
      </section>
    </main>
  </div>
);

export default TermsOfUsePage;
