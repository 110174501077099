import React from 'react';
import Swipe from 'react-easy-swipe';

import { TestimonialsProps, TestimonialsState } from './types';
import Testimonial from './Testimonial';

import styles from './Testimonials.css';
import Image from '../image/Image';
import PaginationDots from './PaginationDots';

import backStandard from '../../../images/back.png';
import backRetina from '../../../images/back@2x.png';
import forwardStandard from '../../../images/forward.png';
import forwardRetina from '../../../images/forward@2x.png';

import * as utils from '../../utils';

class Testimonials extends React.PureComponent<TestimonialsProps, TestimonialsState> {
  private testimonialsListRef: React.RefObject<HTMLDivElement> = React.createRef();

  private testimonialRefs: React.RefObject<HTMLDivElement>[] = [];

  constructor(props: TestimonialsProps) {
    super(props);

    for (let i = 0; i < props.testimonials.length; i += 1) {
      this.testimonialRefs.push(React.createRef());
    }

    // Looks better on desktop to start in the middle!
    const startingTestimonialIndex = utils.isDesktop()
      ? Math.floor(props.testimonials.length / 2)
      : 0;

    this.state = {
      testimonialIndex: startingTestimonialIndex,
    };
    this.setupList = this.setupList.bind(this);
  }

  componentDidMount() {
    this.setupList();

    window.addEventListener('resize', this.setupList);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setupList);
  }

  handleClick(testimonialIndex: number): void {
    const { testimonials } = this.props;
    const wrappedTestimonialIndex = (testimonialIndex + testimonials.length) % testimonials.length;
    this.setState({ testimonialIndex: wrappedTestimonialIndex });
    this.scrollToTestimonial(wrappedTestimonialIndex);
  }

  scrollToTestimonial(testimonialIndex: number, behavior: 'smooth' | 'auto' = 'smooth'): void {
    const element = this.testimonialRefs[testimonialIndex]?.current;

    if (!element) {
      return;
    }

    element.scrollIntoView({
      behavior,
      block: 'nearest',
      inline: 'center',
    });
  }

  setupList() {
    const { testimonials } = this.props;
    const { testimonialIndex } = this.state;

    if (!this.testimonialsListRef?.current) {
      return;
    }

    const testimonialWidth = window.innerWidth;
    const testimonialsListElement = this.testimonialsListRef.current;
    const listWidth = testimonialWidth * testimonials.length + testimonialWidth * 2;

    testimonialsListElement.style.width = `${listWidth}px`;

    // Scroll testimonial into view. Using scrollIntoView will scroll down,
    // so reset the scroll position immediately.
    requestAnimationFrame(() => {
      const { scrollY } = window;
      this.scrollToTestimonial(testimonialIndex, 'auto');
      window.scrollTo({ top: scrollY });
    });
  }

  render(): JSX.Element {
    const { testimonials } = this.props;
    const { testimonialIndex } = this.state;
    const testimonialElements = testimonials.map((testimonial, i) => (
      <Testimonial
        testimonialRef={this.testimonialRefs[i] as React.RefObject<HTMLDivElement>}
        key={i}
        {...testimonial}
        isActive={i === testimonialIndex}
      />
    ));
    const handleSwipeLeft = () => this.handleClick(testimonialIndex + 1);
    const handleSwipeRight = () => this.handleClick(testimonialIndex - 1);

    return (
      <div className={styles.testimonialsContainer}>
        <Swipe onSwipeLeft={handleSwipeLeft} onSwipeRight={handleSwipeRight} tolerance={100}>
          <div className={styles.testimonials}>
            <div className={styles.testimonialsBack}>
              <Image
                standard={backStandard}
                retina={backRetina}
                alt="Back to previous testimonial"
                onClick={handleSwipeRight}
              />
            </div>
            <div className={styles.testimonialsList} ref={this.testimonialsListRef}>
              {testimonialElements}
            </div>
            <div className={styles.testimonialsForward}>
              <Image
                standard={forwardStandard}
                retina={forwardRetina}
                alt="Forward to next testimonial"
                onClick={handleSwipeLeft}
              />
            </div>
          </div>
        </Swipe>
        <PaginationDots
          activeIndex={testimonialIndex}
          count={testimonials.length}
          onClick={(activeIndex) => this.handleClick(activeIndex)}
        />
      </div>
    );
  }
}

export default Testimonials;
