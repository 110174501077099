import * as React from 'react';
import cx from 'classnames';

import * as utils from '../../utils';
import styles from './Image.css';
import { ImageProps } from './types';

class Image extends React.PureComponent<ImageProps> {
  static defaultProps = {
    startStyles: null,
    endStyles: null,
    className: null,
  };

  private imageRef: React.RefObject<HTMLImageElement>;

  constructor(props: ImageProps) {
    super(props);

    this.imageRef = React.createRef();
    this.updateImagePositioning = this.updateImagePositioning.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const { startStyles, endStyles } = this.props;

    if (startStyles && endStyles) {
      window.addEventListener('scroll', this.updateImagePositioning);
      requestAnimationFrame(this.updateImagePositioning);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateImagePositioning);
  }

  updateImagePositioning() {
    const { startStyles, endStyles } = this.props;

    if (!startStyles || !endStyles) {
      return;
    }

    const scrollPercentDecimal = utils.getScrollPercentDecimal();
    Object.keys(startStyles).forEach((styleName: any) => {
      const currentStyle = utils.getStyleProgressFromTo(
        startStyles[styleName],
        endStyles[styleName],
        scrollPercentDecimal,
      );

      if (!this.imageRef.current) {
        return;
      }

      this.imageRef.current.style[styleName] = currentStyle;
    });
  }

  render() {
    const { standard, retina, alt, className, onClick } = this.props;
    const srcSet = `${standard || retina}, ${retina} 2x`;
    const imageStyles = cx(styles.image, className);

    return (
      <img
        className={imageStyles}
        ref={this.imageRef}
        src={retina}
        srcSet={srcSet}
        alt={alt}
        onClick={onClick}
      />
    );
  }
}

export default Image;
