/* eslint-disable @typescript-eslint/indent */

import React from 'react';

import Modal from '../Modal';
import * as styles from './UnityMobileNotSupportedModal.css';
import dinosaurErrorRetina from '../../../../images/dinosaur-error.png';

import { ModalProps } from '../types';
import Image from '../../image/Image';

class UnityMobileNotSupportedMobile extends React.PureComponent<ModalProps> {
  render() {
    return (
      <Modal {...this.props} className={styles.unityMobileNotSupportedModal}>
        <Image
          className={styles.dinosaurError}
          retina={dinosaurErrorRetina}
          alt="Sad Hellosaurus"
        />
        <h3>Looks like you're using an unsupported browser!</h3>
        <p>
          To try out Hellosaurus, visit this website on a desktop using Chrome or Firefox, or sign
          up with your email above.
        </p>
      </Modal>
    );
  }
}

export default UnityMobileNotSupportedMobile;
