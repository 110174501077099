// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Carousell_carousell___1gVpzJ7sxh {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 3em;\n  width: 100%;\n  flex-wrap: wrap;\n}\n\n  .Carousell_carousell___1gVpzJ7sxh img {\n    filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.2));\n    height: 6em;\n    margin-bottom: 3em;\n    margin-left: 0.5em;\n    margin-right: 0.5em;\n    -webkit-user-select: none;\n            user-select: none;\n  }\n\n@media (max-width: 576px) {\n  .Carousell_carousell___1gVpzJ7sxh {\n    margin-top: 2em;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"carousell": "Carousell_carousell___1gVpzJ7sxh"
};
module.exports = exports;
