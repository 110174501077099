// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n  .AnimatedImage_animated-image___3gOt80gs_N.AnimatedImage_animated-image--clickable___1NLuQIFRYq {\n    cursor: pointer\n  }\n.AnimatedImage_animated-image___3gOt80gs_N.AnimatedImage_animated-image--clickable___1NLuQIFRYq.AnimatedImage_animated-image--animating___17VOTbTFO5 {\n      cursor: initial;\n    }\n", ""]);
// Exports
exports.locals = {
	"animated-image": "AnimatedImage_animated-image___3gOt80gs_N",
	"animatedImage": "AnimatedImage_animated-image___3gOt80gs_N",
	"animated-image--clickable": "AnimatedImage_animated-image--clickable___1NLuQIFRYq",
	"animatedImageClickable": "AnimatedImage_animated-image--clickable___1NLuQIFRYq",
	"animated-image--animating": "AnimatedImage_animated-image--animating___17VOTbTFO5",
	"animatedImageAnimating": "AnimatedImage_animated-image--animating___17VOTbTFO5"
};
module.exports = exports;
