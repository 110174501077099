import { Episode } from './types';

const GAME_OBJECT = '[Scene Manager]' as const;

const updateUnityConfig = (config: Episode) => {
  window.EPISODE_CONFIG_FOR_UNITY = config;
};

const loadAndInstantiateUnity = () => {
  const instantiateUnity = () => {
    window.unityInstance = window.UnityLoader.instantiate('unityContainer', '/unity/unity.json');
  };

  const existingScript = document.getElementById('unityBuild');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = '/unity/UnityLoader.js';
    script.id = 'unityBuild';
    document.body.appendChild(script);

    script.onload = () => {
      instantiateUnity();
    };
  }

  if (existingScript) instantiateUnity();
};

const destroyUnity = () => {
  if (typeof window.unityInstance !== 'undefined') {
    window.unityInstance.Quit();
  }

  const unityScript = document.getElementById('unityBuild');
  if (unityScript) {
    unityScript.parentElement?.removeChild(unityScript);
  }
};

const sendMessage = (functionName: string, param: any = null) => {
  if (typeof window.unityInstance === 'undefined') {
    return;
  }

  if (param) {
    window.unityInstance.SendMessage(GAME_OBJECT, functionName, param);
  } else {
    window.unityInstance.SendMessage(GAME_OBJECT, functionName);
  }
};

const updateDoits = () => {
  try {
    sendMessage('UpdateDoits');
  } catch (e) {
    // Suppress
  }
};

const seek = (seconds: number) => {
  sendMessage('Seek', seconds);
};

const onUnityItemIdChange = (fn: () => void) => {
  window.updateActiveDoit = fn;
};

const onUnityVideoTimeChange = (fn: (videoTime: number) => void) => {
  window.updateVideoTime = fn;
};

const toggleFullScreen = () => {
  window.unityInstance.SetFullscreen();
};

export default {
  updateUnityConfig,
  loadAndInstantiateUnity,
  destroyUnity,
  updateDoits,
  seek,
  onUnityItemIdChange,
  onUnityVideoTimeChange,
  toggleFullScreen,
};
